import React, { useEffect } from "react"
import { AnimatePresence, motion } from 'framer-motion'
import { MdDoneAll } from "react-icons/md";
import { MdWarning } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";

var timeout = null;

const Alert = ({ type = "error", title, subtitle, active, setActive }) => {

    const toggleActive = () => {
        clearTimeout(timeout);
        if (typeof setActive == "function") setActive(!active);
    };
    useEffect(() => {
        if (active) timeout = setTimeout(() => toggleActive(), 5000);
    }, [active]); // eslint-disable-line

    return (
        <AnimatePresence>
            {
                active && (
                    <motion.div className={`absolute top-2 right-0 z-[1000] px-4 py-2 bg-slate-50 justify-between rounded-xl mx-5 w-96 h-16 shadow-2xl flex flex-row items-center ${type === "success" ? 'ring-1 ring-pColor' : type === "info" ? 'ring-1 ring-[blue]' : type === 'warning' ? 'ring-1 ring-[yellow]' : 'ring-1 ring-[#BE3144]'}`}>
                        <motion.div className="flex flex-row justify-start">
                            <motion.div className="px-2 py-2">
                                {type === 'success' && <MdDoneAll className="font-bold text-xl text-sColor" />}
                                {type === 'info' && <BsFillInfoCircleFill className="font-bold text-xl text-[blue]" />}
                                {type === 'warning' && <MdWarning className="font-bold text-xl text-[yellow]" />}
                                {type === 'error' && <IoMdCloseCircle className="font-bold text-xl text-[#BE3144]" />}
                            </motion.div>
                            <motion.div className="px-2 py-2 flex flex-col">
                                {subtitle}
                            </motion.div>
                        </motion.div>
                        <motion.div className="px-2 py-2">
                            <FaTimes className="cursor-pointer" onClick={toggleActive} />
                        </motion.div>
                    </motion.div>
                )
            }
        </AnimatePresence>
    )
}

export default Alert