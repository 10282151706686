import React, { useEffect, useMemo, useState } from "react"
import { motion } from 'framer-motion'
import InlineTextField from "../../Components/InLineTextField"
import DataTable, { createTheme } from 'react-data-table-component'
import API from '../../Common/API'
import { datatableTheme } from "../../Common/dataTableTheme"
import DatatableLoader from '../../Components/DatatableLoader'
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const UserList = () => {
    const orgId = useSelector(state => state.auth.orgId)
    const [loading, setLoading] = useState(true)
    const [userList, setUserList] = useState([])
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const rolesList = useSelector(state => state.auth.menuItems)


    useEffect(() => {
        API.get(`/user/getUsers/${orgId}`).then((res) => {
            setUserList(res.data.data)
            setLoading(false)
        })
    }, [])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => <Link to={`/users/view/${row._id}`}>{i + 1}</Link>, initialActive: true, sortable: true },
            { name: 'ID', selector: (row, i) => row.userId, initialActive: true, sortable: true },
            { name: 'Name', selector: row => `${row.firstName} ${row.lastName}`, grow: 1, sortable: true },
            { name: 'User Name', selector: row => row.userName, sortable: true },
            { name: 'Phone', selector: row => row.mobile, sortable: true },
            { name: 'Email', selector: row => row.email, sortable: true },
            { name: 'Role', selector: row => row.roleId, sortable: true },
        ]
    })
    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return userList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [userList, filter])/// eslint-disable-line

    return (
        <motion.div>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="User List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
        </motion.div>
    )
}

export default UserList