import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import API from '../../Common/API';
import { setAlert } from '../../Store/Theme/actions';
import { calculateAcademicYear } from '../../Common/academicYear';
import FieldSelect from '../../Components/FieldSelect';
import FieldInput from '../../Components/FieldInput';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../Components/Button';

const initialMarkSheet = {
    classId: '',
    sectionId: '',
    academicYear: "",
    orgId: '',
    createdBy: '',
    staffId: '',
    reportFor: '',
    subjectId: '',
    totalMark: 100
}

const AddMarks = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const userDetails = useSelector(state => state.auth.userDetails)
    const dispatch = useDispatch()
    const [classFullList, setClassFullList] = useState([])
    const [classList, setClassList] = useState([])
    const academicYear = calculateAcademicYear()
    const [loading, setLoading] = useState(true)
    const [subjectId, setSubjectId] = useState()
    const [classId, setClassId] = useState()
    const [sectionList, setSectionList] = useState([])
    const [enableSection, setEnableSection] = useState(false)
    const [studentList, setStudentList] = useState([])

    useEffect(() => {
        API.get(`/common/getMyAllotedClass/${academicYear}/${userDetails.subject._id}`).then((classRes) => {
            if (classRes.status === 200) {
                setClassFullList(classRes.data.data.map((c) => ({ text: c.class.className, value: c.class._id })))
                setClassList(classRes.data.data.map((c) => ({ text: `${c.class.className} - ${c.subject}`, value: `${c.class._id}-${c.subjectId}` })))
                setLoading(false)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        })
    }, [])

    const markFormSchema = Yup.object().shape({
        classId: Yup.string(),
        sectionId: Yup.string(),
        academicYear: Yup.string(),
        orgId: Yup.string(),
        createdBy: Yup.string(),
        staffId: Yup.string(),
        reportFor: Yup.string(),
        subjectId: Yup.string(),
        totalMark: Yup.number()
    })

    const markForm = useFormik({
        initialValues: initialMarkSheet,
        enableReinitialize: true,
        validationSchema: markFormSchema,
        validateOnMount: true,
        onSubmit: (values) => {
            console.log(values)
        }
    })

    const getSections = (value) => {
        setClassId(value.split('-')[0])
        setSubjectId(value.split('-')[1])
        API.get(`/class/getSections/${orgId}/${value.split('-')[0]}/${academicYear}`).then((sectionRes) => {
            if (sectionRes.status === 200) {
                setSectionList(sectionRes.data.data.map((c) => ({ text: c.sectionName, value: c._id })))
                setEnableSection(true)
            }
        })
    }

    const getStudentList = (values) => {
        API.get(`/class/getStudentBySections/${classId}/${values}/${orgId}/${academicYear}`).then((response) => {
            if (response.status === 200) {
                setStudentList(response.data.data[0].students)
            }
        })
    }

    return (
        <motion.div>
            {
                loading ? "Loading please wait ..." : <motion.div className="w-full h-full text-black flex flex-col">
                    <motion.div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                        <FieldSelect label={'Class'} fieldProps={markForm.getFieldProps('classId')} fieldMeta={markForm.getFieldMeta('classId')} fieldHelper={markForm.getFieldHelpers('classId')} options={classList} edit={true} dataLoaded={true} onChange={(ev) => getSections(ev)} />
                        <FieldSelect disabled={!enableSection} label={'Section'} fieldProps={markForm.getFieldProps('sectionId')} fieldMeta={markForm.getFieldMeta('sectionId')} fieldHelper={markForm.getFieldHelpers('sectionId')} options={sectionList} edit={true} dataLoaded={true} onChange={(ev) => getStudentList(ev)} />
                        <FieldInput label={'Total Mark for paper'} fieldProps={markForm.getFieldProps('totalMark')} fieldMeta={markForm.getFieldMeta('totalMark')} fieldHelper={markForm.getFieldHelpers('totalMark')} edit={true} dataLoaded={true} />
                    </motion.div>
                    {
                        studentList && studentList.length > 0 ?
                            <motion.div className="flex flex-col">
                                <motion.div className="flex flex-row text-center justify-center items-center font-bold">
                                    {academicYear}
                                </motion.div>
                                <motion.div className='flex flex-col text-black'>
                                    <motion.div className="relative h-full px-10  w-full flex flex-col">
                                        <table className="table-auto border border-sColor border-collapse">
                                            <thead>
                                                <tr>
                                                    <th className='border border-sColor px-5'>S.No</th>
                                                    <th className="border border-sColor px-5">Name</th>
                                                    <th className="border border-sColor px-5">Acquired Marks</th>
                                                    <th className="border border-sColor px-5">Total Mark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    studentList.map((c, i) => {
                                                        return (
                                                            <tr className="px-10" key={i}>
                                                                <td className="border border-sColor px-10">{i + 1}</td>
                                                                <td className="border border-sColor px-10"><motion.div className='flex flex-row text-black'>
                                                                    {c.firstName} {c.lastName}
                                                                </motion.div></td>
                                                                <td className="border border-sColor px-10 py-2"><input type="number" min={0} className="text-black bg-transparent px-2 py-1 ring-1 ring-[black] focus:outline-none" /></td>
                                                                <td className="border border-sColor px-10 py-2">{markForm.values.totalMark}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            : ''
                    }
                    <motion.div className="flex flex-row justify-end px-10 py-5">
                        <Button type="primary">Save</Button>
                        <Button type="secondary">Cancel</Button>
                    </motion.div>
                </motion.div>
            }
        </motion.div>
    )
}

export default AddMarks