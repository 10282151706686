import React, { useState, useEffect } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import axios from "axios"
import { useSelector } from "react-redux"
import FieldSelect from "../../Components/FieldSelect"
import * as Yup from 'yup'
import { useFormik } from "formik"
import { setAlert } from "../../Store/Theme/actions"
import { useDispatch } from "react-redux"
import Button from "../../Components/Button"
import { calculateAcademicYear } from '../../Common/academicYear'

const initialForm = {
    assignedStaff: ''
}

const AssignSubjects = () => {

    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = useSelector(state => state.auth.orgId)
    const [loading, setLoading] = useState(true)
    const academicYear = calculateAcademicYear()
    const [classSubList, setClassSubList] = useState([])
    const [userList, setUserList] = useState([])
    const [assignedSubjects, setAssignedSubjects] = useState([])
    const [assignSubjects, setAssignSubjects] = useState([])
    const dispatch = useDispatch()
    const [count, setCount] = useState(0)


    useEffect(() => {
        try {
            axios.all([API.get(`/class/classList/${orgId}`), API.get(`/user/getTeachersList/${orgId}`)]).then(axios.spread((classRes, userRes) => {
                setClassSubList(classRes.data.data)
                setUserList(userRes.data.data.map((c) => ({ text: c.firstName + ' ' + c.lastName, value: c._id })))
                setLoading(false)
            }))
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        API.get(`/common/assignedSubjectList/${academicYear}`).then((subRes) => {
            setAssignedSubjects(subRes.data.data)
        })
    }, [count])

    const assignSubFormSchema = Yup.object().shape({
        assignedStaff: Yup.string().required("Choose Staff Name")
    })

    const assignedSubForm = useFormik({
        initialValues: initialForm,
        validationSchema: assignSubFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            assignSubjects.map((c) => c.assignedStaff = values.assignedStaff)
            try {
                API.post('/common/assignSubjectsToStaff', assignSubjects).then((apiRes) => {
                    if (apiRes.status === 200) {
                        dispatch(setAlert({ title: 'Success', subtitle: 'Subject Assigned to the staff successfully', active: true, type: 'success' }))
                        assignedSubForm.resetForm()
                        setAssignSubjects([])
                        setCount(count + 1)
                    } else {
                        dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                    }
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    const selectSubjects = (classes, subject, outerIndex, innerIndex) => {
        if (assignedSubForm.values.assignedStaff === '' || assignedSubForm.values.assignedStaff === null || assignedSubForm.values.assignedStaff === undefined) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Please select the staff', active: true, type: 'error' }))
        } else {
            const isSubjectSelected = assignSubjects.some(
                (x) => x.outerIndex === outerIndex && x.innerIndex === innerIndex
            );
            if (isSubjectSelected) {
                // Subject is selected, so remove it from the array
                const updatedValues = assignSubjects.filter(
                    (x) => !(x.outerIndex === outerIndex && x.innerIndex === innerIndex)
                );
                setAssignSubjects(updatedValues);
            } else {
                const values = {
                    outerIndex: outerIndex,
                    innerIndex: innerIndex,
                    class: classes._id,
                    className: classes.className,
                    subjectId: subject._id,
                    subject: subject.subjectName,
                    assignedStaff: assignedSubForm.values.assignedStaff,
                    academicYear: academicYear,
                }
                setAssignSubjects([...assignSubjects, values])
            }
        }
    }

    const cancelAssign = () => {
        assignedSubForm.resetForm()
        setAssignSubjects([])
    }

    return (
        <motion.div className={`relative h-full w-full flex flex-col`}>
            <motion.div className="relative">
                <FieldSelect label={'Staff Name'} fieldProps={assignedSubForm.getFieldProps('assignedStaff')} fieldHelper={assignedSubForm.getFieldHelpers('assignedStaff')} fieldMeta={assignedSubForm.getFieldMeta('assignedStaff')} options={userList} edit={true} dataLoaded={true} />
            </motion.div>
            <motion.div className="px-5 flex flex-row justify-between">
                <motion.div className="relative flex flex-col text-center justify-center text-black font-bold">
                    Class & Subjects
                </motion.div>
                <motion.div className="flex flex-row">
                    <Button type="primary" onClick={() => assignedSubForm.submitForm()}>Save</Button>
                    <Button type="secondary" onClick={() => cancelAssign()}>Cancel</Button>
                </motion.div>
            </motion.div>
            {
                classSubList.map((c, i) => {
                    return (
                        <motion.div className={`relative flex flex-col bg-slate-300 px-5 py-2 my-2 bg-opacity-60 rounded-xl`}>
                            <motion.div className="relative text-black font-bold text-lg">
                                {c.className}
                            </motion.div>
                            <motion.div className="relative flex flex-rown flex-wrap px-4 py-2">
                                {
                                    c.subjects.map((d, j) => {
                                        return <motion.div className={`${(assignSubjects.some(
                                            (x) => x.subject === d.subjectName && x.subjectId === d._id && x.class === c._id
                                        ) || assignedSubjects.some(
                                            (x) => x.subject === d.subjectName && x.subjectId === d._id && x.class === c._id
                                        )) ? assignedSubjects.some(
                                            (x) => x.subject === d.subjectName && x.subjectId === d._id && x.class === c._id
                                        ) ? 'bg-sColor opacity-40 text-white' : 'bg-sColor text-white' : 'bg-pColor bg-opacity-50'} mx-2 my-2  px-4 py-2 rounded-full cursor-pointer`} onClick={() => assignedSubjects.some(
                                            (x) => x.subject === d.subjectName && x.subjectId === d._id && x.class === c._id
                                        ) ? '' : selectSubjects(c, d, i, j)}>
                                            {d.subjectName}
                                        </motion.div>
                                    })
                                }
                            </motion.div>
                        </motion.div>
                    )
                })
            }
        </motion.div>
    )
}

export default AssignSubjects