import React from "react"
import { motion } from 'framer-motion'
import FieldInput from "../../Components/FieldInput"
import * as Yup from 'yup';
import { useFormik } from "formik";
import Button from "../../Components/Button";
import { setAlert } from "../../Store/Theme/actions";
import { useDispatch } from "react-redux";
import API from "../../Common/API";

const initialFormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
}

const ChangePassword = () => {

    const dispatch = useDispatch()

    const passwordSchema = Yup.object().shape({
        oldPassword: Yup.string(),
        newPassword: Yup.string(),
        confirmPassword: Yup.string()
    })

    const passwordForm = useFormik({
        initialValues: initialFormValues,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: passwordSchema,
        onSubmit: (values) => {
            if (values.newPassword === values.confirmPassword) {
                try {
                    API.post('/auth/changePassword', values).then((passwordRes) => {
                        if (passwordRes.status === 200) {
                            dispatch(setAlert({ title: 'Success', subtitle: 'Password updated successfully', active: true, type: 'success' }))
                        } else {
                            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                        }
                    })
                } catch (err) {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                }
            } else {
                dispatch(setAlert({ title: 'Warning', subtitle: 'Password does not match', active: true, type: 'warning' }))
            }
        }
    })

    return (
        <motion.div className={`relative w-full h-full`}>
            <motion.div className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1`}>
                <FieldInput type="text" label={'Old Password'} fieldProps={passwordForm.getFieldProps('oldPassword')} fieldHelper={passwordForm.getFieldHelpers('oldPassword')} fieldMeta={passwordForm.getFieldMeta('oldPassword')} edit={true} dataLoaded={true} />
                <FieldInput type="password" label={'New Password'} fieldProps={passwordForm.getFieldProps('newPassword')} fieldHelper={passwordForm.getFieldHelpers('newPassword')} fieldMeta={passwordForm.getFieldMeta('newPassword')} edit={true} dataLoaded={true} />
                <FieldInput type="password" label={'Confirm Password'} fieldProps={passwordForm.getFieldProps('confirmPassword')} fieldHelper={passwordForm.getFieldHelpers('confirmPassword')} fieldMeta={passwordForm.getFieldMeta('confirmPassword')} edit={true} dataLoaded={true} />
            </motion.div>
            <motion.div className="flex flex-row justify-center">
                <Button type="primary" onClick={() => passwordForm.submitForm()}>Save</Button>
                <Button type="secondary" onClick={() => passwordForm.resetForm()}>Cancel</Button>
            </motion.div>
        </motion.div>
    )
}

export default ChangePassword