import React, { useRef } from 'react'
import { MdClose } from 'react-icons/md'
import FieldLoader from './FieldLoader'

const InlineTextField = ({ label, injectClass = '', loading, value, setValue, onEnter, ml, length, focus, blur, autoFocus = false, onClose }) => {

    const inputElem = useRef(null)
    const chgValue = (e) => {
        if (typeof setValue === 'function') setValue(e.target.value)
    }
    const focusInput = () => inputElem.current.focus()

    const keyPress = (e) => {
        if (e.key === 'Enter') {
            if (onEnter !== undefined) onEnter()
        }
    }
    const onFocus = (e) => {
        if (typeof focus === 'function') focus(e.target.value)

    }
    const onBlur = (e) => {
        if (typeof blur === 'function') blur(e.target.value)

    }

    const close = () => {
        if (typeof onClose === 'function') onClose()
    }

    const closeFunction = () => {
        setValue('')
        close()
    }

    return (
        <div className={`relative ring-1 focus-within:ring-2 ring-gray-600 focus-within:ring-tcolor px-2 py-0.5 rounded-lg ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} flex items-center ${injectClass} ${loading ? 'animate-pulse' : ''}`} onClick={focusInput}>
            <span className="text-gray-500 dark:text-gray-400 text-sm mr-3 whitespace-nowrap">{label}</span>
            <div className="w-full">
                <input disabled={loading} autoFocus={autoFocus} ref={inputElem} className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded-lg px-3 py-0.5 focus:text-black bg-transparent w-full ${loading ? 'hover:cursor-wait' : ''}`} type="text" value={value} onChange={chgValue} onBlur={onBlur} onFocus={onFocus} onKeyPress={keyPress} maxLength={ml ? `${length}` : ''} />
            </div>
            <span className="w-3 mr-1">
                {value !== null && value !== '' && <MdClose onClick={() => closeFunction()} />}
            </span>
            {loading && <FieldLoader injectClass="absolute bottom-0 w-[98%] h-[2px] left-1" />}
        </div>
    )
}

export default InlineTextField
