import React, { useState, useEffect } from "react"
import { motion } from 'framer-motion'
import { useSelector } from "react-redux"
import API from '../../Common/API'
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldSelect from "../../Components/FieldSelect"
import { useDispatch } from "react-redux"
import { setAlert } from '../../Store/Theme/actions'

const initialClassForm = {
    className: ''
}

const PublishResult = () => {

    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = useSelector(state => state.auth.orgId)
    const [loading, setLoading] = useState(true)
    const [classList, setClassList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedRecord, setSelectedRecord] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const dispatch = useDispatch()
    const [count, setCount] = useState(0)

    useEffect(() => {
        API.get(`/class/classList/${orgId}`).then((res) => {
            if (res.status === 200) {
                setClassList(res.data.data.map((c) => ({ text: c.className, value: c.className })))
                setLoading(false)
            }
        })
    }, [count])


    const classFormSchema = Yup.object().shape({
        className: Yup.string()
    })

    const classForm = useFormik({
        initialValues: initialClassForm,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: classFormSchema,
        onSubmit: (values) => {
            try {
                API.get(`/class/getClassMemberList/${orgId}/${values.className}`).then((res) => {
                    setStudentList(res.data.data)
                    setSelectedRecord([])
                    setCount(count + 1)
                })
            } catch (err) {
                console.log(err)
            }
        }
    })

    const handleStudentRecord = (ev, c, index) => {
        if (ev.target.checked) {
            setSelectedRecord([...selectedRecord, c])
        } else {
            const updateRecord = [...selectedRecord]
            updateRecord.splice(index, 1)
            setSelectedRecord(updateRecord)
        }
    }

    const selectAllRecords = (values) => {
        setSelectAll(values)
        if (values) {
            setSelectedRecord(studentList)
        } else {
            setSelectedRecord([])
        }
    }

    const promoteStudents = () => {
        if (selectedRecord.length > 0) {
            let values = {
                status: 'Promoted',
                admissionNo: [...selectedRecord.map((c) => { return c.admissionNo })],
                history: [...selectedRecord.map((c) => { return { admissionNo: c.admissionNo, remarks: `Got Promoted to the next grade from ${c.admittedOn}`, orgId: c.orgId, admissionId: c._id, studentId: c.studentId._id, createdBy: userDetails.subject._id } })]
            }
            API.post(`/class/promoteStudent/${orgId}`, values).then((res) => {
                setSelectedRecord([])
                if (res.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: res.data.data, type: 'success', active: true }))
                    setCount(count + 1)
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                }
            }).catch((err) => {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            })
        } else {
            dispatch(setAlert({ type: 'Warning', subtitle: 'Please select atleast one student', active: true, type: 'warning' }))
        }
    }

    const failStudent = () => {
        if (selectedRecord.length > 0) {
            let values = {
                status: 'Failed',
                admissionNo: [...selectedRecord.map((c) => { return c.admissionNo })],
                history: [...selectedRecord.map((c) => { return { admissionNo: c.admissionNo, remarks: `Got Failed in the current grade  ${c.admittedOn}`, orgId: c.orgId, admissionId: c._id, studentId: c.studentId._id, createdBy: userDetails.subject._id } })]
            }
            API.post(`/class/promoteStudent/${orgId}`, values).then((res) => {
                setSelectedRecord([])
                if (res.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: res.data.data, type: 'success', active: true }))
                    setCount(count + 1)
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                }
            }).catch((err) => {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            })
        } else {
            dispatch(setAlert({ type: 'Warning', subtitle: 'Please select atleast one student', active: true, type: 'warning' }))
        }
    }

    return (
        <motion.div className="relative h-full w-full flex flex-col">
            <motion.div className="text-black">
                <FieldSelect label={'Select Class'} fieldProps={classForm.getFieldProps('className')} fieldHelper={classForm.getFieldHelpers('className')} fieldMeta={classForm.getFieldMeta('className')} options={classList} edit={true} dataLoaded={true} onChange={() => classForm.submitForm()} />
            </motion.div>
            <motion.div className="flex flex-row text-white w-full justify-end">
                <motion.div className="bg-sColor  rounded-full cursor-pointer px-5 py-1 mx-2" onClick={() => promoteStudents()}>Promote</motion.div>
                <motion.div className="bg-red-800 rounded-full cursor-pointer px-5 py-1 mx-2" onClick={() => failStudent()}>Fail</motion.div>
            </motion.div>
            <motion.div className="flex flex-col">
                <motion.div className="grid grid-cols-5 py-2 px-2">
                    <motion.div>S.No</motion.div>
                    <motion.div><input type="checkbox" name="selectAll" checked={selectAll} onClick={() => selectAllRecords(!selectAll)} /></motion.div>
                    <motion.div>Admission Id</motion.div>
                    <motion.div>Name</motion.div>
                    <motion.div>Result</motion.div>
                </motion.div>
                <motion.div className="h-0.5 rounded-full w-full bg-sColor"></motion.div>
                {studentList.map((c, i) => {
                    return <motion.div className="grid px-2 py-2 grid-cols-5">
                        <motion.div>{i + 1}</motion.div>
                        <motion.div>
                            <input type="checkbox" checked={selectedRecord.includes(c)} onChange={(ev) => handleStudentRecord(ev, c, i)} />
                        </motion.div>
                        <motion.div>
                            {c.admissionNo}
                        </motion.div>
                        <motion.div>
                            {c.studentId.firstName} {c.studentId.lastName}
                        </motion.div>
                        <motion.div>
                            {c.promotedStat}
                        </motion.div>
                    </motion.div>
                })}
            </motion.div>
        </motion.div>
    )
}

export default PublishResult