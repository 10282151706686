import React from "react"
import { motion } from 'framer-motion'
import { useSelector } from "react-redux"
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../Store/Auth/actions";
import { useHistory } from "react-router-dom";

const TopBar = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const pageTitle = useSelector(state => state.theme.pageTitle)

    const logout = () => {
        localStorage.removeItem('token')
        history.push('/auth/login')
        dispatch(setLoggedIn(false))
    }

    return (
        <motion.div className="relative px-8 py-5 h-full flex flex-row justify-between w-full">
            <motion.div className="text-sColor text-2xl font-bold">{pageTitle}</motion.div>
            <FiLogOut className="cursor-pointer text-sColor text-md font-bold" onClick={() => logout()} />
        </motion.div>
    )
}

export default TopBar