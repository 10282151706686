import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import API from "../Common/API"
import { setMenuItems } from "../Store/Auth/actions"
import { useSelector } from "react-redux"
import { motion } from 'framer-motion'
import { FaExpand } from "react-icons/fa";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Dialog from "../Components/Dialog"
import Button from "../Components/Button"

const Dashboard = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [dashDetails, setDashDetails] = useState()
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(new Date());
    const [eventDetails, setEventDetails] = useState()
    const [openViewEventsDailog, setOpenViewEventsDialog] = useState(false)


    useEffect(() => {
        API.get('/common/dashboard').then((dashRes) => {
            if (dashRes.status === 200) {
                localStorage.setItem('menuItems', JSON.stringify(dashRes.data.data.menuItems))
                setDashDetails(dashRes.data.data)
                setLoading(false)
                dispatch(setMenuItems(dashRes.data.data.menuItems))
            }
        })
    }, [])

    const getGreeting = () => {
        const now = new Date();
        const hour = now.getHours();

        if (hour >= 5 && hour < 12) {
            return "Good Morning!";
        } else if (hour >= 12 && hour < 18) {
            return "Good Afternoon!";
        } else {
            return "Good Evening!";
        }
    };


    const hasHolidayEvent = (date) => {
        return dashDetails.menuItems.holidays.some((event) => event.eventType === "Holiday and Seasonal Events" && new Date(event.eventDate).toString().slice(0, 15) === new Date(date).toString().slice(0, 15));
    };

    // Function to format date as YYYY-MM-DD
    const viewEvents = (date) => {
        setEventDetails(dashDetails.menuItems.holidays.filter((event) => new Date(event.eventDate).toString().slice(0, 15) === new Date(date).toString().slice(0, 15))[0])
        setOpenViewEventsDialog(true)
    };

    console.log(userDetails)
    return (
        <>
            {
                loading ? "Loading please wait..." : <motion.div className="w-full h-full flex flex-col">
                    <motion.div className="flex flex-row">
                        <motion.div className="text-black font-bold text-3xl">
                            {getGreeting()}
                        </motion.div>&nbsp;
                        <motion.div className="text-black font-bold text-3xl">
                            {userDetails.subject.profileType === 'org' ? userDetails.subject.orgName : userDetails.subject.firstName + userDetails.subject.lastName}  .
                        </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row my-2 justify-between">
                        <motion.div className="w-2/3 mx-2 flex flex-col">
                            <motion.div className="grid grid-cols-3 gap-10 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                                <motion.div className="flex flex-row px-5 h-20 justify-between bg-slate-400 bg-opacity-50 rounded-lg">
                                    <motion.div className="flex flex-col justify-center">
                                        <motion.div className="text-grey text-xl font-medium">
                                            Students
                                        </motion.div>
                                        <motion.div className="text-sColor text-2xl font-bold">
                                            {dashDetails.menuItems.students}
                                        </motion.div>
                                    </motion.div>
                                    <motion.div className="flex text-sColor flex-col justify-center">
                                        <FaExpand className="cursor-pointer" />
                                    </motion.div>
                                </motion.div>
                                <motion.div className="flex flex-row px-5 h-20  justify-between bg-slate-400 bg-opacity-50 rounded-lg">
                                    <motion.div className="flex flex-col justify-center">
                                        <motion.div className="text-grey text-xl font-medium">
                                            Teachers
                                        </motion.div>
                                        <motion.div className="text-sColor text-2xl font-bold">
                                            {dashDetails.menuItems.teachers}
                                        </motion.div>
                                    </motion.div>
                                    <motion.div className="flex text-sColor flex-col justify-center">
                                        <FaExpand className="cursor-pointer" />
                                    </motion.div>
                                </motion.div>
                                <motion.div className="flex flex-row px-5 h-20  justify-between bg-slate-400 bg-opacity-50 rounded-lg">
                                    <motion.div className="flex flex-col justify-center">
                                        <motion.div className="text-grey text-xl font-medium">
                                            Staff
                                        </motion.div>
                                        <motion.div className="text-sColor text-2xl font-bold">
                                            {dashDetails.menuItems.staff}
                                        </motion.div>
                                    </motion.div>
                                    <motion.div className="flex text-sColor flex-col justify-center">
                                        <FaExpand className="cursor-pointer" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                        <motion.div className="w-1/3 mx-2 flex flex-col justify-end items-end">
                            <div className='calendar-container'>
                                <Calendar
                                    onChange={setDate}
                                    value={date}
                                    selectRange={false}
                                    onClickDay={(ev) => hasHolidayEvent(date) && viewEvents(ev)}
                                    tileContent={({ date, view }) => {
                                        if (view === 'month' && hasHolidayEvent(date)) {
                                            return <div className="red-dot"></div>;
                                        }
                                    }}
                                />
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
            <Dialog title="View Events" showDialog={openViewEventsDailog} injectClass="w-[500px]" onClose={() => setOpenViewEventsDialog(false)}>
                <motion.div className="flex flex-col w-full my-2">
                    <motion.div className="grid grid-cols-2 w-full sm:grid-cols-1 py-4 md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2">
                        {/* Title */}
                        <motion.div className="flex flex-col px-2 py-1">
                            <motion.div className="text-xl font-bold">
                                Title
                            </motion.div>
                            <motion.div className="text-md">
                                {eventDetails?.eventName}
                            </motion.div>
                        </motion.div>
                        {/* Description */}
                        <motion.div className="flex flex-col px-2 py-1">
                            <motion.div className="text-xl font-bold">
                                Description
                            </motion.div>
                            <motion.div className="text-md">
                                {eventDetails?.description !== null && eventDetails?.description !== '' ? eventDetails?.description : '-'}
                            </motion.div>
                        </motion.div>
                        {/* From Date */}
                        <motion.div className="flex flex-col px-2 py-1">
                            <motion.div className="text-xl font-bold">
                                From
                            </motion.div>
                            <motion.div className="text-md">
                                {eventDetails?.startDate}-{eventDetails?.startMonth < 10 ? '0' + eventDetails?.startMonth : eventDetails?.startMonth}-{eventDetails?.startYear}
                            </motion.div>
                        </motion.div>
                        {/* To */}
                        <motion.div className="flex flex-col px-2 py-1">
                            <motion.div className="text-xl font-bold">
                                To
                            </motion.div>
                            <motion.div className="text-md">
                                {eventDetails?.endDate}-{eventDetails?.endMonth < 10 ? '0' + eventDetails?.endMonth : eventDetails?.endMonth}-{eventDetails?.endYear}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    <Button type='primary' onClick={() => setOpenViewEventsDialog(false)}>Ok</Button>
                </motion.div>
            </Dialog>
        </>
    )
}

export default Dashboard