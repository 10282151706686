import React, { useLayoutEffect } from "react"
import { motion } from "framer-motion"
import SideBar from "../Components/SideBar"
import { CombinedRoutes } from "../Common/Routes"
import { Route, Switch } from 'react-router'
import { useDispatch } from "react-redux"
import { setPageTitle } from "../Store/Theme/actions"
import { withRouter } from "react-router-dom"
import TopBar from "../Components/TopBar"

const getUrlElem = (url) => {
    let urlSplit = url.split('/')
    let route = CombinedRoutes.filter(c => c.link !== null && c.link.split('/').length === urlSplit.length).find(c => c.link.split('/').every((i, j) => i[0] === ':' || i === urlSplit[j]))
    let urlParams = {}
    route?.link?.split('/').forEach((c, i) => {
        if (c[0] === ':') {
            urlParams[c.slice(1,)] = urlSplit[i]
        }
    })
    let { component: Elem = null, title = null, ItemIcon: icon = null, saveData = false, multiWindow = true } = route || {}
    return { Elem, title, icon, saveData, multiWindow, urlParams }
}


const HomeRoot = ({ history }) => {

    const dispatch = useDispatch()

    // set page title
    useLayoutEffect(() => {
        let path = getUrlElem(history.location.pathname)
        if (path) dispatch(setPageTitle(path.title))
    }, [history.location]) // eslint-disable-line

    return (
        <motion.div className="h-full bg-pColor flex">
            <SideBar />
            <div className="w-full flex mx-10 flex-col h-full">
                <TopBar />
                <div className="text-left overflow-auto pt-4 px-8 text-tpColor">
                    <Switch>
                        {
                            CombinedRoutes.map(c => (
                                <Route key={c.link} path={c.link} exact component={c.component} />
                            ))
                        }
                    </Switch>
                </div>
            </div>
        </motion.div>
    )
}

export default withRouter(HomeRoot)