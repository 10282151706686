import { useMemo } from "react"

const TabGroup = ({ children, value= 0 }) => {

    const translateX = useMemo(() => {
        return `-${parseInt(value) * 100}%`
    }, [value])

    return (
        <div className="overflow-x-hidden">
            <div className={`flex w-full transition-all duration-300 transform`} style={{transform: `translateX(${translateX})`}}>
                {children}
            </div>
        </div>
    )
}

export default TabGroup