import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { calculateAcademicYear } from '../../Common/academicYear'
import moment from 'moment-timezone';
import API from '../../Common/API'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import FieldSelect from '../../Components/FieldSelect'
import FieldInput from '../../Components/FieldInput'
import { setAlert } from '../../Store/Theme/actions';
import axios from 'axios';
import TabButtonGroup from '../../Components/Tabs/TabButtonGroup';
import TabButton from '../../Components/Tabs/TabButton';
import TabGroup from '../../Components/Tabs/TabGroup';
import Tab from '../../Components/Tabs/Tab';

const initialFormValues = {
    classId: '',
    startDate: '',
    endDate: '',
}

const AttendenceSummary = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const academicYear = calculateAcademicYear()
    const currentTimezone = moment.tz.guess(); // Automatically detect the timezone
    const currentTime = moment().tz(currentTimezone).format('YYYY-MM-DD')
    const [loading, setLoading] = useState(true)
    const [studentsList, setStudentsList] = useState([])
    const [summaryList, setSummaryList] = useState([])
    const [classList, setClassList] = useState([])
    const dispatch = useDispatch()
    const [reportLoading, setReportLoading] = useState(true)
    const [noOfDays, setNoOfDays] = useState(0)
    const [tab, setTab] = useState(0)
    const [holidaysList, setHolidaysList] = useState([])
    const [displayRecords, setDisplayRecords] = useState({})
    const [sundayList, setSundayList] = useState([])

    useEffect(() => {
        API.get(`/class/classList/${orgId}`).then((classRes) => {
            try {
                setClassList(classRes.data.data.map((c) => ({ text: c.className, value: c._id })));
                setLoading(false)
            } catch (err) {
                console.error('Error fetching class list:', err)
                dispatch(setAlert({ title: 'Error', subtitle: 'Failed to load classes', active: true, type: 'error' }))
            }
        })
    }, [orgId, dispatch])

    const summarySchema = Yup.object().shape({
        classId: Yup.string().required("Choose class"),
        startDate: Yup.string().required("Choose Start Date"),
        endDate: Yup.string().required("Choose End Date"),
    })

    const summaryForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: summarySchema,
        onSubmit: (values) => {
            setReportLoading(true)
            setSundayList([])
            axios.all([
                API.get(`/class/getStudentsByClass/${orgId}/${academicYear}/${values.classId}`),
                API.get(`/class/getAttendenceSummary/${orgId}/${values.classId}/${values.startDate}/${values.endDate}`),
                API.get(`/class/getCurrentAvailedHolidays/${orgId}/${values.startDate}/${values.endDate}`)
            ]).then(axios.spread(async (classRes, attRes, holidayRes) => {
                setStudentsList(classRes.data.data.sort((a, b) => a.sectionName.localeCompare(b.sectionName)))
                setDisplayRecords(classRes.data.data.sort((a, b) => a.sectionName.localeCompare(b.sectionName))[0])
                setSummaryList(await summaryMap(attRes.data.data))
                setHolidaysList(holidayRes.data.data.map((c) => c.eventDate))
                setNoOfDays(await getWeekdayCount(new Date(values.startDate), new Date(values.endDate)))
                setReportLoading(false)
            })).catch(err => {
                console.error('Error fetching attendance data:', err)
                dispatch(setAlert({ title: 'Error', subtitle: 'Failed to load attendance data', active: true, type: 'error' }))
                setReportLoading(false)
            })
        }
    })

    const summaryMap = async (details) => {
        const generatedSummary = new Map();

        details.forEach((res) => {
            const admission = res.admission.rollNumber;

            if (generatedSummary.has(admission)) {
                const admissionData = generatedSummary.get(admission);
                admissionData.report[res.attendenceStat.toLowerCase()].push(res);
            } else {
                generatedSummary.set(admission, {
                    rollNumber: admission,
                    report: {
                        absent: [],
                        present: [],
                    },
                });

                generatedSummary.get(admission).report[res.attendenceStat.toLowerCase()].push(res);
            }
        });

        return [...generatedSummary.values()]
    }

    const getWeekdayCount = async (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        let dayCount = 0;
        let sunValues = []

        while (start <= end) {
            if (start.getDay() !== 0) {
                dayCount++;
            } else {
                sunValues.push(moment(start).format('YYYY-MM-DD'))
            }
            start.setDate(start.getDate() + 1);
        }
        setSundayList(sunValues)
        return dayCount;
    }

    const segregateLeaveCount = (holidays, sundays) => {
        const flattenedArray = [...holidays, ...sundays].flat()
        const uniqueDates = [...new Set(flattenedArray)]
        return parseInt(noOfDays) - uniqueDates.length
    }

    return (
        <>
            {
                loading ? "Loading, please wait..." : (
                    <motion.div className='relative h-full w-full flex flex-col'>
                        <motion.div className='grid grid-cols-3 gap-5'>
                            <FieldSelect label={'Class'} fieldHelper={summaryForm.getFieldHelpers('classId')} fieldMeta={summaryForm.getFieldMeta('classId')} fieldProps={summaryForm.getFieldProps('classId')} options={classList} edit={true} dataLoaded={true} />
                            <FieldInput label={'Start Date'} type="date" max={currentTime} fieldHelper={summaryForm.getFieldHelpers('startDate')} fieldMeta={summaryForm.getFieldMeta('startDate')} fieldProps={summaryForm.getFieldProps('startDate')} edit={true} dataLoaded={true} onChange={() => summaryForm.setFieldValue('endDate', '')} />
                            <FieldInput label={'End Date'} type="date" max={currentTime} fieldHelper={summaryForm.getFieldHelpers('endDate')} fieldMeta={summaryForm.getFieldMeta('endDate')} fieldProps={summaryForm.getFieldProps('endDate')} edit={true} dataLoaded={true} onChange={() => summaryForm.submitForm()} />
                        </motion.div>
                        <motion.div className="text-center my-5">
                            <TabButtonGroup>
                                {
                                    studentsList.map((c, i) => (
                                        <TabButton index={i} value={tab} setValue={setTab} id={c.sectionName} onClick={() => setDisplayRecords(c)}>
                                            Section {c.sectionName}
                                        </TabButton>
                                    ))
                                }
                            </TabButtonGroup>
                        </motion.div>
                        <TabGroup value={tab}>
                            {
                                studentsList.map((c, i) => (
                                    <Tab index={i} value={tab}>
                                        <motion.div className="relative h-full px-10 w-full flex flex-col">
                                            <table className="table-auto border border-sColor border-collapse">
                                                <thead>
                                                    <tr>
                                                        <th className='border border-sColor px-5'>Roll number</th>
                                                        <th className='border border-sColor px-5'>Student Name</th>
                                                        <th className='border border-sColor px-5'>Absent</th>
                                                        <th className='border border-sColor px-5'>Present</th>
                                                        <th className='border border-sColor px-5'>Holidays</th>
                                                        <th className='border border-sColor px-5'>Days Expected</th>
                                                        <th className='border border-sColor px-5'>Percentage</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !reportLoading && displayRecords.students && displayRecords.admissionId && displayRecords.students.map((stu, i) => {
                                                            const rollNumber = displayRecords.admissionId[i].rollNumber;
                                                            const summary = summaryList.find(x => x.rollNumber === rollNumber);
                                                            const absentCount = summary ? summary.report.absent.length / 2 : 0;
                                                            const presentCount = summary ? summary.report.present.length / 2 : 0;
                                                            const daysExpected = segregateLeaveCount(holidaysList, sundayList);
                                                            const percentage = daysExpected ? Math.floor((presentCount / daysExpected) * 100) : 0;

                                                            return (
                                                                <tr className="px-10" key={i}>
                                                                    <td className="border border-sColor px-10">{rollNumber}</td>
                                                                    <td className="border border-sColor px-10">{stu.firstName}</td>
                                                                    <td className="border border-sColor px-10">{absentCount}</td>
                                                                    <td className="border border-sColor px-10">{presentCount}</td>
                                                                    <td className="border border-sColor px-10">{holidaysList.length}</td>
                                                                    <td className="border border-sColor px-10">{daysExpected}</td>
                                                                    <td className="border border-sColor px-10">{percentage} %</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </motion.div>
                                    </Tab>
                                ))
                            }
                        </TabGroup>
                    </motion.div>
                )
            }
        </>
    )
}

export default AttendenceSummary
