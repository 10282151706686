import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import FieldInput from "../../Components/FieldInput"
import { useDispatch, useSelector } from "react-redux"
import FieldSelect from "../../Components/FieldSelect"
import * as Yup from 'yup'
import { useFormik } from "formik"
import API from '../../Common/API'
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import axios from 'axios'

const initialAdmissionForm = {
    studentName: '',
    gender: '',
    guardianName: "",
    mobile: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        district: '',
        pinCode: ''
    },
    guardianOccupation: '',
    pastSchool: '',
    admissionFullDate: '',
    tcSubmission: '',
    nationality: '',
    religion: '',
    community: '',
    motherTongue: '',
    dob: '',
    date: "",
    year: '',
    month: '',
    admittedOn: '',
    motherMobile: '',
    fatherMobile: '',
    sibilingInSameSchool: '',
    physicalDisability: '',
    aadharCardNo: '',
    bloodGroup: '',
    emisNo: '',
    age: 0,
    admissionDate: '',
    admissionYear: '',
    admissionMonth: '',
    admissionType: 'New'
}

const NewAdmission = () => {

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone', "Not a Valid Phone", (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 10)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet', "Only Alphabets Allowed", (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', "Not valid", (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })


    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test('onlyAlphabet and numbers are allowed', 'Only Alphabets and numbers allowed', (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validName', function () {
        return this.test('Space is not allowed at the beginning', 'Space is not allowed at the beginning', (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })


    const dispatch = useDispatch()
    const menuItems = useSelector(state => state.auth.menuItems)
    const genderList = menuItems.gender.map((c) => ({ text: c.gender, value: c.gender }))
    const nationality = menuItems.nationality.map((c) => ({ text: c.nationality, value: c.nationality }))
    const state = menuItems.states.map((c) => ({ text: c.state, value: c.state }))
    const [district, setDistrict] = useState([])
    const religion = menuItems.religion.map((c) => ({ text: c.religion, value: c.religion }))
    const community = menuItems.community.map((c) => ({ text: c.community, value: c.community }))
    const answer = [{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }]
    const bloodGroup = menuItems.bloodGroup.map((c) => ({ text: c.bloodGroup, value: c.bloodGroupId }))
    const userDetails = useSelector(state => state.auth.userDetails)
    const [classList, setClassList] = useState([])
    const [classMenuList, setClassMenuList] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectDisable, setSelectDisable] = useState(false)
    const orgId = useSelector(state => state.auth.orgId)
    const [classGroups, setClassGroups] = useState([])
    const [enableGroupSelection, setEnableGroupSelection] = useState(false)

    useEffect(() => {
        axios.all([API.get(`/class/getSubjectGroups/${orgId}`), API.get(`/class/classList/${orgId}`)]).then(axios.spread((groupRes, apiRes) => {
            setClassGroups(groupRes.data.data.map((c) => ({ text: c.groupName, value: c._id })))
            setClassList(apiRes.data.data)
            setClassMenuList(apiRes.data.data.map((c) => ({ text: c.className, value: c.className })))
            setLoading(false)
        }))
        // API.get(`/class/getSubjectGroups/${orgId}`).then((groupRes)=>{

        // })
        // API.get(`/class/classList/${orgId}`).then((apiRes) => {
        //     if (apiRes.status === 200) {
        //         setClassList(apiRes.data.data)
        //         setClassMenuList(apiRes.data.data.map((c) => ({ text: c.className, value: c.className })))
        //         setLoading(false)
        //     }
        // })
    }, [])


    const admissionFormSchema = Yup.object().shape({
        studentName: Yup.string().validateName().required("Student name is required"),
        gender: Yup.string().required("Gender is required"),
        guardianName: Yup.string().validateName().required("Guardian name is required"),
        address: Yup.object().shape({
            addressLine1: Yup.string().validName().required("Address Line 1 is required"),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().validName().required("City/Village is required"),
            state: Yup.string().required("State is required"),
            district: Yup.string().required("District is required"),
            pinCode: Yup.string().min(6, "Pincode should be of 6 characters").max(6, "Pincode should be of 6 characters").required("Pin Code is required")
        }),
        guardianOccupation: Yup.string().validName().required("Guardian Name is required"),
        pastSchool: Yup.string().validName().nullable(),
        admissionFullDate: Yup.string().required("Admission Date is required"),
        tcSubmission: Yup.string().required("Tc submission is required"),
        nationality: Yup.string().required("Nationality is required"),
        religion: Yup.string().required("Religion is required"),
        community: Yup.string().required("Community is required"),
        motherTongue: Yup.string().required("Mother Tongue is required"),
        dob: Yup.string().required("Dob is required"),
        date: Yup.string().nullable(),
        year: Yup.string().nullable(),
        month: Yup.string().nullable(),
        admittedOn: Yup.string().required("Admission on class is required"),
        mobile: Yup.string().nullable().validatePhoneLength(),
        motherMobile: Yup.string().nullable().validatePhoneLength(),
        fatherMobile: Yup.string().nullable().validatePhoneLength(),
        sibilingInSameSchool: Yup.string().nullable(),
        physicalDisability: Yup.string().nullable(),
        aadharCardNo: Yup.string().nullable().min(12, "Aadhar Card No is 12 Digit Number").max(12, "Aadhar Card No is 12 Digit Number"),
        bloodGroup: Yup.string().nullable(),
        emisNo: Yup.string().nullable().min(8, "Emis is 8 digit number").max(8, "Emis is 8 digit number"),
        age: Yup.number(),
        admissionDate: Yup.string().nullable(),
        admissionYear: Yup.string().nullable(),
        admissionMonth: Yup.string().nullable(),
        admissionType: Yup.string().nullable(),
        tcImage: Yup.string().nullable(),
        aadharImage: Yup.string().nullable(),
        profileImage: Yup.string().nullable(),
        groupId: enableGroupSelection ? Yup.string().required("Please select the group") : Yup.string().nullable()
    })

    const admissionForm = useFormik({
        initialValues: initialAdmissionForm,
        validationSchema: admissionFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            setSelectDisable(true)
            values.admissionDate = values.admissionFullDate.split('-')[2]
            values.admissionMonth = values.admissionFullDate.split('-')[1]
            values.admissionYear = values.admissionFullDate.split('-')[0]
            values.admissionType = "New"
            values.orgId = userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId
            values.createdBy = userDetails.subject._id
            try {
                API.post('/admission/new', values).then((apiRes) => {
                    if (apiRes.status === 200) {
                        dispatch(setAlert({ title: 'Success', subtitle: 'Admission saved successfully', active: true, type: 'success' }))
                        admissionForm.resetForm()
                        setEnableGroupSelection(false)
                        setSelectDisable(false)
                    }
                }).catch((err) => {
                    setSelectDisable(false)
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                })
            } catch (Error) {
                setSelectDisable(false)
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    const filterDistrict = (value) => {
        setDistrict(menuItems.states.filter((c) => c.state === value)[0].districts.map((c) => ({ text: c, value: c })))
    }

    const ageCalculation = (dob) => {
        const currentDate = new Date()
        const dobDate = new Date(dob)
        let currentAge = ''

        const yearDifference = currentDate.getFullYear() - dobDate.getFullYear();

        if (
            currentDate.getMonth() < dobDate.getMonth() ||
            (currentDate.getMonth() === dobDate.getMonth() &&
                currentDate.getDate() < dobDate.getDate())
        ) {
            currentAge = yearDifference - 1;
            admissionForm.setValues({ ...admissionForm.values, age: currentAge, date: dob.split('-')[2], month: dob.split('-')[1], year: dob.split('-')[0] })
        } else {
            currentAge = yearDifference;
            admissionForm.setValues({ ...admissionForm.values, age: currentAge, date: dob.split('-')[2], month: dob.split('-')[1], year: dob.split('-')[0] })
        }
    }

    // const uploadFile = (v, fieldName) => {
    //     const fileType = v.type.split('/')[0];
    //     if (fileType === 'video') {
    //         dispatch(setAlert({ title: t('Warning'), subtitle: t('video file cannot be allowed'), type: 'warning', active: true }))
    //         // profileForm.resetForm();
    //         return;
    //     }
    //     if (v === undefined) {
    //         setUploadButton(false)
    //     }
    //     if (v.name) {
    //         setFileName(v.name)
    //         setUploadButton(true)
    //     }
    //     let result = validateFileType(v.size)
    //     if (result === undefined) {
    //         base64Conversion(v).then((value) => {
    //             setFile(value)
    //         })
    //     }
    // }

    // const base64Conversion = v => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(v);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = error => reject(error);
    // })

    return (
        <motion.div className={`relative min-h-screen w-full flex flex-col`}>
            <motion.div className={`bg-slate-300 bg-opacity-50 rounded-full px-2 py-2 text-sColor font-bold`}>
                Student Details 1
            </motion.div>
            <motion.div className={`relative grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3`}>
                <FieldInput label={'Name of the student'} fieldProps={admissionForm.getFieldProps('studentName')} fieldMeta={admissionForm.getFieldMeta('studentName')} fieldHelper={admissionForm.getFieldHelpers('studentName')} edit={true} dataLoaded={true} />
                <FieldSelect label={'Gender'} fieldProps={admissionForm.getFieldProps('gender')} fieldMeta={admissionForm.getFieldMeta('gender')} fieldHelper={admissionForm.getFieldHelpers('gender')} edit={true} dataLoaded={true} options={genderList} />
                <FieldInput label={'Guardian Name'} fieldProps={admissionForm.getFieldProps('guardianName')} fieldMeta={admissionForm.getFieldMeta('guardianName')} fieldHelper={admissionForm.getFieldHelpers('guardianName')} edit={true} dataLoaded={true} />
                <FieldInput label={'Residential Address'} fieldProps={admissionForm.getFieldProps('address.addressLine1')} fieldMeta={admissionForm.getFieldMeta('address.addressLine1')} fieldHelper={admissionForm.getFieldHelpers('address.addressLine1')} edit={true} dataLoaded={true} />
                <FieldInput label={'Address Line 2'} fieldProps={admissionForm.getFieldProps('address.addressLine2')} fieldMeta={admissionForm.getFieldMeta('address.addressLine2')} fieldHelper={admissionForm.getFieldHelpers('address.addressLine2')} edit={true} dataLoaded={true} />
                <FieldInput label={'City/Village'} fieldProps={admissionForm.getFieldProps('address.city')} fieldMeta={admissionForm.getFieldMeta('address.city')} fieldHelper={admissionForm.getFieldHelpers('address.city')} edit={true} dataLoaded={true} />
                <FieldSelect label={'State'} fieldProps={admissionForm.getFieldProps('address.state')} fieldMeta={admissionForm.getFieldMeta('address.state')} fieldHelper={admissionForm.getFieldHelpers('address.state')} edit={true} dataLoaded={true} options={state} onChange={(ev) => filterDistrict(ev)} />
                <FieldSelect label={'District'} fieldProps={admissionForm.getFieldProps('address.district')} fieldMeta={admissionForm.getFieldMeta('address.district')} fieldHelper={admissionForm.getFieldHelpers('address.district')} edit={true} dataLoaded={true} options={district} />
                <FieldInput type="phone" label={'Pin Code'} fieldProps={admissionForm.getFieldProps('address.pinCode')} fieldMeta={admissionForm.getFieldMeta('address.pinCode')} fieldHelper={admissionForm.getFieldHelpers('address.pinCode')} edit={true} dataLoaded={true} ml={true} length={6} />
                <FieldInput type="phone" label={'Contact No'} fieldProps={admissionForm.getFieldProps('mobile')} fieldMeta={admissionForm.getFieldMeta('mobile')} fieldHelper={admissionForm.getFieldHelpers('mobile')} edit={true} dataLoaded={true} />
                <FieldInput label={'Occupation of Guardian'} fieldProps={admissionForm.getFieldProps('guardianOccupation')} fieldMeta={admissionForm.getFieldMeta('guardianOccupation')} fieldHelper={admissionForm.getFieldHelpers('guardianOccupation')} edit={true} dataLoaded={true} />
                <FieldInput label={'Past School'} fieldProps={admissionForm.getFieldProps('pastSchool')} fieldMeta={admissionForm.getFieldMeta('pastSchool')} fieldHelper={admissionForm.getFieldHelpers('pastSchool')} edit={true} dataLoaded={true} />
                <FieldInput type="date" label={'Date Of Admission'} fieldProps={admissionForm.getFieldProps('admissionFullDate')} fieldMeta={admissionForm.getFieldMeta('admissionFullDate')} fieldHelper={admissionForm.getFieldHelpers('admissionFullDate')} edit={true} dataLoaded={true} />
                <FieldSelect label={'Is TC Submitted ?'} fieldProps={admissionForm.getFieldProps('tcSubmission')} fieldMeta={admissionForm.getFieldMeta('tcSubmission')} fieldHelper={admissionForm.getFieldHelpers('tcSubmission')} edit={true} dataLoaded={true} options={answer} />
                <FieldSelect label={'Nationality'} fieldProps={admissionForm.getFieldProps('nationality')} fieldMeta={admissionForm.getFieldMeta('nationality')} fieldHelper={admissionForm.getFieldHelpers('nationality')} edit={true} dataLoaded={true} options={nationality} />
                <FieldSelect label={'Religion'} fieldProps={admissionForm.getFieldProps('religion')} fieldMeta={admissionForm.getFieldMeta('religion')} fieldHelper={admissionForm.getFieldHelpers('religion')} edit={true} dataLoaded={true} options={religion} />
                <FieldSelect label={'Community'} fieldProps={admissionForm.getFieldProps('community')} fieldMeta={admissionForm.getFieldMeta('community')} fieldHelper={admissionForm.getFieldHelpers('community')} edit={true} dataLoaded={true} options={community} />
                <FieldInput label={'Mother Tongue'} fieldProps={admissionForm.getFieldProps('motherTongue')} fieldMeta={admissionForm.getFieldMeta('motherTongue')} fieldHelper={admissionForm.getFieldHelpers('motherTongue')} edit={true} dataLoaded={true} />
                <FieldInput type="date" label={'Date Of Birth'} maxDate={new Date().toISOString().split('T')[0]} fieldProps={admissionForm.getFieldProps('dob')} fieldMeta={admissionForm.getFieldMeta('dob')} fieldHelper={admissionForm.getFieldHelpers('dob')} edit={true} dataLoaded={true} onChange={(ev) => ageCalculation(ev)} />
                <FieldInput label={'Age'} disabled={true} fieldProps={admissionForm.getFieldProps('age')} fieldMeta={admissionForm.getFieldMeta('age')} fieldHelper={admissionForm.getFieldHelpers('age')} edit={true} dataLoaded={true} />
                <FieldSelect label={'Class on admission'} fieldProps={admissionForm.getFieldProps('admittedOn')} fieldMeta={admissionForm.getFieldMeta('admittedOn')} fieldHelper={admissionForm.getFieldHelpers('admittedOn')} edit={true} dataLoaded={true} options={classMenuList} onChange={(ev) => ev === 'Class 11' || ev === 'Class 12' ? setEnableGroupSelection(true) : setEnableGroupSelection(false)} />
                {enableGroupSelection && <FieldSelect label={'Group'} fieldProps={admissionForm.getFieldProps('groupId')} fieldMeta={admissionForm.getFieldMeta('groupId')} fieldHelper={admissionForm.getFieldHelpers('groupId')} edit={true} dataLoaded={true} options={classGroups} />}
            </motion.div>
            <motion.div className={`bg-slate-300 bg-opacity-50 rounded-full px-2 py-2 text-sColor font-bold`}>
                Student Details 2
            </motion.div>
            <motion.div className={`relative grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3`}>
                <FieldInput type="phone" label={'Mother Mobile No'} fieldProps={admissionForm.getFieldProps('motherMobile')} fieldMeta={admissionForm.getFieldMeta('motherMobile')} fieldHelper={admissionForm.getFieldHelpers('motherMobile')} edit={true} dataLoaded={true} />
                <FieldInput type="phone" label={'Father Mobile No'} fieldProps={admissionForm.getFieldProps('fatherMobile')} fieldMeta={admissionForm.getFieldMeta('fatherMobile')} fieldHelper={admissionForm.getFieldHelpers('fatherMobile')} edit={true} dataLoaded={true} />
                <FieldSelect label={'Sibilings in this school ?'} fieldProps={admissionForm.getFieldProps('sibilingInSameSchool')} fieldMeta={admissionForm.getFieldMeta('sibilingInSameSchool')} fieldHelper={admissionForm.getFieldHelpers('sibilingInSameSchool')} edit={true} dataLoaded={true} options={answer} />
                <FieldSelect label={'Any Disability ?'} fieldProps={admissionForm.getFieldProps('physicalDisability')} fieldMeta={admissionForm.getFieldMeta('physicalDisability')} fieldHelper={admissionForm.getFieldHelpers('physicalDisability')} edit={true} dataLoaded={true} options={answer} />
                <FieldInput type="phone" label={'Aadhar Card No'} fieldProps={admissionForm.getFieldProps('aadharCardNo')} fieldMeta={admissionForm.getFieldMeta('aadharCardNo')} fieldHelper={admissionForm.getFieldHelpers('aadharCardNo')} edit={true} dataLoaded={true} length={12} />
                <FieldSelect label={'Blood Group'} fieldProps={admissionForm.getFieldProps('bloodGroup')} fieldMeta={admissionForm.getFieldMeta('bloodGroup')} fieldHelper={admissionForm.getFieldHelpers('bloodGroup')} edit={true} dataLoaded={true} options={bloodGroup} />
                <FieldInput label={'EMIS No'} fieldProps={admissionForm.getFieldProps('emisNo')} fieldMeta={admissionForm.getFieldMeta('emisNo')} fieldHelper={admissionForm.getFieldHelpers('emisNo')} edit={true} dataLoaded={true} ml={true} length={8} />
            </motion.div>
            <motion.div className={`bg-slate-300 bg-opacity-50 rounded-full px-2 py-2 text-sColor font-bold`}>
                Upload Assets
            </motion.div>
            {/* <motion.div className={`relative grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3`}>
                <FieldInput ariaLabel={'Uplad'} loading={formLoading} accept=".png, .jpg, .jpeg" type="file" label={"Upload TC"} autoComplete='off' fieldMeta={admissionForm.getFieldMeta('tcImage')} fieldProps={admissionForm.getFieldProps('tcImage')} fieldHelper={admissionForm.getFieldHelpers('tcImage')} edit={true} dataLoaded={true} onChange={v => uploadFile(v, 'tcImage')} />
                <FieldInput ariaLabel={'Uplad'} loading={formLoading} accept=".png, .jpg, .jpeg" type="file" label={"Upload Aadhar card"} autoComplete='off' fieldMeta={admissionForm.getFieldMeta('aadharImage')} fieldProps={admissionForm.getFieldProps('aadharImage')} fieldHelper={admissionForm.getFieldHelpers('aadharImage')} edit={true} dataLoaded={true} onChange={v => uploadFile(v, 'aadharImage')} />
                <FieldInput ariaLabel={'Choose File'} loading={formLoading} accept=".png, .jpg, .jpeg" type="file" label={"Upload Photo"} autoComplete='off' fieldMeta={admissionForm.getFieldMeta('profileImage')} fieldProps={admissionForm.getFieldProps('profileImage')} fieldHelper={admissionForm.getFieldHelpers('profileImage')} edit={true} dataLoaded={true} onChange={v => uploadFile(v, 'profileImage')} />
            </motion.div> */}
            <motion.div className="relative flex flex-row justify-center">
                <Button type="primary" disabled={selectDisable} onClick={() => !selectDisable && admissionForm.submitForm()}>Save</Button>
                <Button type="secondary" onClick={() => admissionForm.resetForm()}>Cancel</Button>
            </motion.div>
        </motion.div>
    )
}

export default NewAdmission