import React, { useEffect, useMemo, useState } from "react"
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux"
import API from '../../Common/API'
import InlineTextField from "../../Components/InLineTextField"
import DataTable, { createTheme } from "react-data-table-component"
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import { FaCheck, FaTrash } from 'react-icons/fa'
import { setAlert } from '../../Store/Theme/actions'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const DeclinedAdmissionList = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [filter, setFilter] = useState('')
    const [admissionList, setAdmissionList] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    useEffect(() => {
        API.get(`/admission/getDeclinedAdmissionList/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`).then((apiRes) => {
            setAdmissionList(apiRes.data.data)
            setLoading(false)
        })
    }, [count])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Student Name', selector: row => row.studentId.firstName, sortable: true },
            { name: "Class", selector: row => row.admittedOn, sortable: true },
            { name: 'Admission Id', selector: row => row.admissionNo, sortable: true },
            { name: 'Age', selector: row => row.studentId.age, sortable: true },
            { name: 'Status', selector: row => row.activeStat === 'P' ? <motion.div className="pendingChip">Pending</motion.div> : row.activeStat === 'A' ? <motion.div className="activeChip">Active</motion.div> : <motion.div className="deactiveChip">Deactive</motion.div>, sortable: true },
            {
                name: 'Actions', selector: row => <motion.div className="flex flex-row justify-around">
                    {(row.activeStat === 'P' || row.activeStat === 'D') && <FaCheck className="mx-2 text-[green] cursor-pointer" title="Approve" onClick={() => approveAdmission(row)} />}
                    {(row.activeStat === 'P' || row.activeStat === 'A') && <FaTrash className="mx-2 text-[red] cursor-pointer" title="Decline" onClick={() => denyAdmission(row)} />}
                </motion.div>, omit: (userDetails.subject.role !== 'Super Admin')
            }
        ]
    })

    const SubHeader = useMemo(() => {
        return <div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    }, [filter])

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return admissionList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [admissionList, filter])/// eslint-disable-line

    const approveAdmission = (values) => {
        API.post(`/admission/approveAdmission`, values).then((approveRes) => {
            if (approveRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Admission approved successfully', active: true, type: 'success' }))
                setCount(count + 1)
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
        })
    }

    const denyAdmission = (values) => {
        API.post(`/admission/denyAdmission`, values).then((approveRes) => {
            if (approveRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Admission declined successfully', active: true, type: 'success' }))
                setCount(count + 1)
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
        })
    }

    return (
        <motion.div>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Admission List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
        </motion.div>
    )
}

export default DeclinedAdmissionList