import React, { useEffect, useState } from "react"
import API from '../Common/API'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setLoggedIn, setMenuItems, setToken, setUserDetails, setOrgId } from '../Store/Auth/actions'
import Alert from '../Components/Alerts'
import { setAlert } from '../Store/Theme/actions'
import HomeRoot from "./HomeRoot";
import Login from "../Pages/Login";
import Loaders from "../Common/Loaders";


const Root = () => {

    const [loading, setLoading] = useState(false)
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const dispatch = useDispatch();
    const [isLoggedChecked, setIsLoggedChecked] = useState(false);
    const setHideAlert = () => dispatch(setAlert({ active: false }));
    const alert = useSelector((state) => state.theme.alert)
    const [routes, setRoutes] = useState()

    useEffect(() => {
        // adding interceptors to api
        API.interceptors.request.use((request) => {
            setLoading(true);
            // dispatch(setLoading(true))
            return request;
        });
        API.interceptors.response.use(
            (response) => {
                setLoading(false);
                // dispatch(setLoading(false))
                return response;
            },
            (error) => {
                setLoading(false);
                // dispatch(setLoading(false))
                return Promise.reject(error);
            }
        );
    }, []); // eslint-disable-line

    const checkLoggedIn = () => {
        let token = localStorage.getItem('token')
        if (!token && isLoggedIn) {
            dispatch(setLoggedIn(false));
        } else if (token && token.split(".").length > 1) {
            let userDetails = JSON.parse(atob(token.split(".")[1]));
            dispatch(setOrgId(userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId))
            setIsLoggedChecked(true);
            dispatch(setLoggedIn(true))
            dispatch(setToken(token))
            dispatch(setMenuItems(JSON.parse(localStorage.getItem('menuItems'))))
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            dispatch(setUserDetails(userDetails))
        } else {
            setIsLoggedChecked(true)
        }
    }

    useEffect(() => {
        setIsLoggedChecked(false)
        checkLoggedIn()
    }, [isLoggedIn])

    useEffect(() => {
        if (isLoggedIn) {
            setRoutes(
                <Switch>
                    <Route path="/" component={HomeRoot} />
                    <Redirect to="/" />
                </Switch>
            )
        } else {
            setRoutes(
                <Switch>
                    <Route path="/auth/login" component={Login} />
                    <Route path="/" component={Login} />
                </Switch>
            )
        }
    }, [isLoggedIn])

    return (
        <>
            {isLoggedChecked ? <div className="h-100">
                <Alert title={alert.title} subtitle={alert.subtitle} active={alert.active} setActive={setHideAlert} type={alert.type} />
                {routes}
            </div> : <Loaders />}
        </>
    )
}

export default Root