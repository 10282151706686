import React, { useState, useEffect } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import axios from "axios"
import moment from 'moment-timezone';
import { useSelector, useDispatch } from "react-redux";
import * as Yup from 'yup'
import { useFormik } from "formik";
import FieldSelect from "../../Components/FieldSelect";
import { calculateAcademicYear } from "../../Common/academicYear";
import Button from "../../Components/Button";
import { setAlert } from "../../Store/Theme/actions"

const initialFormValues = {
    classId: '',
    section: '',
    filterMonth: 0,
    filterYear: 0
}

const months = [{ text: 'January', value: 1 }, { text: 'Feburaury', value: 2 }, { text: 'March', value: 3 }, { text: 'April', value: 4 }, { text: 'May', value: 5 },
{ text: 'June', value: 6 }, { text: 'July', value: 7 }, { text: 'August', value: 8 }, { text: 'September', value: 9 }, { text: 'October', value: 10 }, { text: 'November', value: 11 }, { text: 'December', value: 12 }]


const MonthWiseReport = () => {

    const [loading, setLoading] = useState(true)
    const orgId = useSelector(state => state.auth.orgId)
    const [sectionList, setSectionList] = useState([])
    const academicYear = calculateAcademicYear()
    const [classMenuList, setClassMenuList] = useState([])
    const [reportList, setReportList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [yearsList, setYearsList] = useState([])
    const [leap, setLeap] = useState(false)
    const [dateArray, setDateArray] = useState([])
    const [detailLoad, setDetailLoad] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        API.get(`/class/classList/${orgId}`).then((res) => {
            setClassMenuList(res.data.data.map((c) => ({ text: c.className, value: c._id })))
            let totalYears = []
            for (let i = 2023; i <= new Date().getFullYear(); i++) {
                let value = { text: i, value: i }
                totalYears.push(value)
            }
            setYearsList(totalYears)
            setLoading(false)
        })
    }, [])

    const reportSchema = Yup.object().shape({
        classId: Yup.string(),
        section: Yup.string(),
        filterMonth: Yup.number(),
        filterYear: Yup.number(),
    })

    const reportForm = useFormik({
        initialValues: initialFormValues,
        validateOnMount: true,
        validationSchema: reportSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if(!values.classId){
             dispatch(setAlert({ type: 'Error', subtitle: 'Please Select the Class', active: true }));
             return
            }
            if(!values.section){
                dispatch(setAlert({ type: 'Error', subtitle: 'Please Select the Section', active: true }));
                return
               }
            if(!values.filterMonth){
             dispatch(setAlert({ type: 'Error', subtitle: 'Please Select the Month', active: true }));
             return
            }
            if(!values.filterYear){
                dispatch(setAlert({ type: 'Error', subtitle: 'Please Select the Year', active: true }));
                return
               }
            API.get(`/class/getAttendenceReport/${orgId}/${academicYear}/${values.classId}/${values.section}/${values.filterMonth}/${values.filterYear}`).then((reportRes) => {
                if (reportRes.status === 200) {
                    let dateMap = new Map()
                    let idMap = new Map()

                    reportRes.data.data.map((c) => {
                        if (dateMap.has(c.date)) {
                            const dateData = dateMap.get(c.date)
                            dateData.push({ date: c.date, session: c.session, stat: c.attendenceStat, studentId: c.admission.studentId })
                        } else {
                            dateMap.set(c.date, [{
                                date: c.date,
                                session: c.session,
                                stat: c.attendenceStat,
                                studentId: c.admission.studentId
                            }])
                        }
                        if (!idMap.has(c.admission.studentId)) {
                            idMap.set(c.admission.studentId, {
                                studentId: c.admission.studentId,
                                attendence: []
                            })
                        }
                    })

                    reportRes.data.data.map((c) => {
                        const dateData = dateMap.get(c.date).filter((x) => x.studentId === c.admission.studentId)
                        const idData = idMap.get(c.admission.studentId)
                        if (!idData.attendence.includes(dateData)) {
                            idData.attendence.push(dateData)
                        }
                    })
                    let segregateAttendence = [...idMap.values()]
                    let attendenceValue = []
                    segregateAttendence.map((c) => {
                        for (let i = 0; i < c.attendence.length; i++) {
                            if (c.attendence[i].filter((x) => x.stat === 'Present').length === 2) {
                                let values = {
                                    date: c.attendence[i][0].date,
                                    stat: 'FP',
                                    studentId: c.studentId
                                }
                                attendenceValue.push(values)
                            } else if (c.attendence[i].filter((x) => x.stat === 'Absent').length === 2) {
                                let values = {
                                    date: c.attendence[i][0].date,
                                    stat: 'FA',
                                    studentId: c.studentId
                                }
                                attendenceValue.push(values)
                            } else {
                                if (c.attendence[i][0].session === 'FN' && c.attendence[i][0].stat === 'Present' && c.attendence[i][1].session === 'AN' && c.attendence[i][1].stat === 'Absent') {
                                    let values = {
                                        date: c.attendence[i][0].date,
                                        stat: 'MP',
                                        studentId: c.studentId
                                    }
                                    attendenceValue.push(values)
                                } else if (c.attendence[i][0].session === 'FN' && c.attendence[i][0].stat === 'Absent' && c.attendence[i][1].session === 'AN' && c.attendence[i][1].stat === 'Present') {
                                    let values = {
                                        date: c.attendence[i][0].date,
                                        stat: 'AP',
                                        studentId: c.studentId
                                    }
                                    attendenceValue.push(values)
                                } else if (c.attendence[i][0].session === 'AN' && c.attendence[i][0].stat === 'Present' && c.attendence[i][1].session === 'FN' && c.attendence[i][1].stat === 'Absent') {
                                    let values = {
                                        date: c.attendence[i][0].date,
                                        stat: 'AP',
                                        studentId: c.studentId
                                    }
                                    attendenceValue.push(values)
                                } else {
                                    let values = {
                                        date: c.attendence[i][0].date,
                                        stat: 'MP',
                                        studentId: c.studentId
                                    }
                                    attendenceValue.push(values)
                                }
                            }
                        }
                    })
                    const uniqueDataMap = new Map();
                    // Iterate over the input data and add items to the Map
                    attendenceValue.forEach(item => {
                        const key = `${item.date}_${item.stat}_${item.studentId}`;
                        uniqueDataMap.set(key, item);
                    });

                    // Convert the Map values back to an array to get the unique dataset
                    const uniqueDataSet = Array.from(uniqueDataMap.values());
                    setReportList(uniqueDataSet)
                }
            })
        }
    })

    const assingSections = (value) => {
        API.get(`/class/getSections/${orgId}/${value}/${academicYear}`).then((secRes) => {
            setSectionList(secRes.data.data.sort((a, b) => a.sectionName.localeCompare(b.sectionName)).map((c) => ({ text: c.sectionName, value: c._id })))
        })
    }

    const getStudentsList = (value) => {
        API.get(`/class/getStudentBySection/${orgId}/${reportForm.values.classId}/${value}`).then((stuRes) => {
            if (stuRes.status === 200) {
                setStudentList(stuRes.data.data[0].students)
                setDetailLoad(true)
            }
        })
    }

    const generateDates = (value) => {
        setReportList([])
        if (value === 1 || value === 3 || value === 5 || value === 7 || value === 8 || value === 10 || value === 12) {
            setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]);
        } else if (value === 2) {
            if (leap) {
                setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]);
            } else {
                setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]);
            }
        } else {
            setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]);
        }
    }

    const checkLeap = (value) => {
        setReportList([])
        if ((value % 4 === 0 && value % 100 !== 0) || (value % 400 === 0)) {
            setLeap(true)
            setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]);
        } else {
            setLeap(false)
            setDateArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]);
        }
    }

    const cancelSummary = () => {
        setReportList([])
        reportForm.resetForm()
    }


    return (
        <>
            {
                loading ? "Loading please wait" : <motion.div className="relative w-full h-full flex flex-col">
                    <motion.div className="grid grid-cols-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5">
                        <FieldSelect label={'Class'} fieldProps={reportForm.getFieldProps('classId')} fieldHelper={reportForm.getFieldHelpers('classId')} fieldMeta={reportForm.getFieldMeta('classId')} edit={true} dataLoaded={true} options={classMenuList} onChange={(ev) => assingSections(ev)} />
                        <FieldSelect label={'Section'} fieldProps={reportForm.getFieldProps('section')} fieldHelper={reportForm.getFieldHelpers('section')} fieldMeta={reportForm.getFieldMeta('section')} edit={true} dataLoaded={true} options={sectionList} onChange={(ev) => getStudentsList(ev)} />
                        <FieldSelect label={'Year'} fieldProps={reportForm.getFieldProps('filterYear')} fieldHelper={reportForm.getFieldHelpers('filterYear')} fieldMeta={reportForm.getFieldMeta('filterYear')} edit={true} dataLoaded={true} options={yearsList} onChange={(ev) => checkLeap(ev)} />
                        <FieldSelect label={'Month'} fieldProps={reportForm.getFieldProps('filterMonth')} fieldHelper={reportForm.getFieldHelpers('filterMonth')} fieldMeta={reportForm.getFieldMeta('filterMonth')} edit={true} dataLoaded={true} options={months} onChange={(ev) => generateDates(ev)} />
                        <motion.div className="flex flex-col justify-between">
                            <Button type="secondary" onClick={() => cancelSummary()}>Cancel</Button>
                            <Button type="primary" onClick={() => reportForm.submitForm()}>Generate Report</Button>
                        </motion.div>
                    </motion.div>

                    {
    detailLoad && (
        <motion.div className="my-5 py-2 relative  flex flex-col w-full justify-center h-full">
            <motion.div className="text-black text-xl font-bold w-full flex flex-row justify-center items-center text-center">
                {reportForm.values.classId !== null && reportForm.values.classId !== '' && classMenuList.find(x => x.value === reportForm.values.classId)?.text} - {reportForm.values.section !== null && reportForm.values.section !== '' && sectionList.find(x => x.value === reportForm.values.section)?.text}
            </motion.div>
            <motion.div className="text-black text-lg font-bold w-full flex flex-row justify-center items-center text-center">
                {reportForm.values.filterMonth !== null && reportForm.values.filterMonth !== '' && reportForm.values.filterMonth !== 0 && months.find(x => x.value === reportForm.values.filterMonth)?.text} - {reportForm.values.filterYear !== null && reportForm.values.filterYear !== '' && reportForm.values.filterYear !== 0 && reportForm.values.filterYear}
            </motion.div>
            <motion.div className="flex flex-row px-2">
                <table className="w-full text-center table-auto px-2 border border-black border-collapse">
                    <thead>
                        <tr className="px-2">
                            <th className="px-2 w-96 border border-[black]">Name</th>
                            {dateArray.map((c, i) => (
                                <th key={i} className="px-2 w-12 border border-[black]">{c}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {studentList.map((student, index) => (
                            <tr key={index}>
                                <td className="w-96 px-2 py-1 border border-[black]">{student.firstName} {student.lastName}</td>
                                {reportList.length > 0 && dateArray.map((date, i) => {
                                    const attendance = reportList.find(item => item.date === date && item.studentId === student._id);
                                    return (
                                        <td key={i} className="border border-[black]">
                                            {attendance ? (
                                                <motion.div style={{ padding: '5px' }}>
                                                    <motion.div className={`${attendance.stat === 'FP' || attendance.stat === 'AP' ? 'bg-[green]' : 'bg-[red]'} h-[20px] w-[20px]`}>
                                                    {attendance.stat === 'FP' || attendance.stat === 'MP' ? <motion.div id="triangle-topleftGreen"></motion.div> : <motion.div id="triangle-topleftRed"></motion.div>}
                                                    </motion.div>
                                                </motion.div>
                                            ) : ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </motion.div>
        </motion.div>
    )
}

                </motion.div>
            }
        </>
    )
}

export default MonthWiseReport