import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import API from "../../Common/API"
import { useDispatch, useSelector } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import { calculateAcademicYear } from "../../Common/academicYear"
import { MdClass } from "react-icons/md";
import { useHistory } from 'react-router-dom'

const AllotedSubjects = () => {

    const [loading, setLoading] = useState(true)
    const [classList, setClassList] = useState(false)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const academicYear = calculateAcademicYear()
    const history = useHistory()


    useEffect(() => {
        try {
            API.get(`/common/getMyAllotedClass/${academicYear}/${userDetails.subject._id}`).then((classRes) => {
                if (classRes.status === 200) {
                    setClassList(classRes.data.data)
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    const routeToNotesOfLesson = (record) => {
        history.push('/notesOfLesson', { state: record })
    }
    return (
        <motion.div className="relative h-full w-full flex flex-col">
            <motion.div className="relative text-xl my-2 font-bold">
                Select subject to enter the notes of lesson
            </motion.div>
            <motion.div className="grid px-5 grid-cols-4 my-2 gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4">
                {classList.length > 0 ? classList.map((c) => {
                    return <motion.div className="flex cursor-pointer flex-col bg-sColor bg-opacity-60 rounded-xl w-44 items-center h-44 relative justify-center text-center px-4 py-2" onClick={() => routeToNotesOfLesson(c)}>
                        <MdClass className="text-3xl text-white opacity-80" />
                        <motion.div className="text-md font-bold text-white">{c.class.className}</motion.div>
                        <motion.div className="text-md font-bold text-white">{c.subject}</motion.div>
                    </motion.div>
                }) : ''}
            </motion.div>
        </motion.div>
    )
}

export default AllotedSubjects