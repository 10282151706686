import React, { useState, useEffect } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import axios from "axios"
import { calculateAcademicYear } from "../../Common/academicYear"
import { useDispatch, useSelector } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldSelect from "../../Components/FieldSelect"
import { FaTrashAlt } from 'react-icons/fa'
import Button from "../../Components/Button"

const initialFormValues = {
    class: '',
    subject: '',
    academicYear: '',
    syllabus: ''
}

const AddSyllabus = () => {

    const [loading, setLoading] = useState(true)
    const [subjectList, setSubjectList] = useState([])
    const [classList, setClassList] = useState([])
    const [classMenuList, setClassMenuList] = useState([])
    const orgId = useSelector(state => state.auth.orgId)
    const [syllabusList, setSyllabusList] = useState([])
    const academicYear = calculateAcademicYear()
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)

    useEffect(() => {
        try {
            API.get(`/class/classList/${orgId}`).then((classRes) => {
                if (classRes.status === 200) {
                    setClassList(classRes.data.data)
                    setClassMenuList(classRes.data.data.map((c) => ({ text: c.className, value: c._id })))
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    const syllabusFormShcema = Yup.object().shape({
        class: Yup.string().required("Select class"),
        subject: Yup.string().required("Select subject"),
        syllabus: Yup.array(),
        academicYear: Yup.string()
    })

    const syllabusForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: syllabusFormShcema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            let details = {
                class: values.class,
                subject: values.subject,
                academicYear: academicYear,
                orgId: orgId,
                createdBy: userDetails.subject._id.createdBy,
                syllabus: []
            }
            syllabusList.map((c) => {
                let value = {
                    ...c,
                    class: values.class,
                    subject: values.subject,
                    academicYear: academicYear,
                    orgId: orgId,
                    createdBy: userDetails.subject._id.createdBy,
                    topicName: c.topicName,
                    subTopics: c.subTopics,
                }
                details.syllabus.push(value)
            })
            try {
                API.post('/class/createSyllabus', details).then((sylRes) => {
                    if (sylRes.status === 200) {
                        syllabusForm.resetForm()
                        setSubjectList([])
                        setSyllabusList([])
                        dispatch(setAlert({ title: 'Success', subtitle: 'Syllabus created Successfully', active: true, type: 'success' }))
                    }
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    const selectSubjectMenu = (values) => {
        setSubjectList(classList.filter((x) => x._id === values)[0].subjects.map((c) => ({ text: c.subjectName, value: c._id })))
    }

    const getSyllabus = (eventValues) => {
        let classId = syllabusForm.values.class
        try {
            API.get(`/class/getSyllabus/${orgId}/${classId}/${eventValues}/${academicYear}`).then((res) => {
                if (res.status === 200) {
                    setSyllabusList(res.data.data)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    const addTopics = () => {
        let values = {
            topicName: '',
            subTopics: []
        }
        setSyllabusList([...syllabusList, values])
    }

    const addSubTopics = (i) => {
        let values = {
            topicName: '',
            createdBy: userDetails.subject._id,
            orgId: orgId,
            academicYear: academicYear,
        }
        const updateSyllabus = [...syllabusList]
        updateSyllabus[i].subTopics.push(values)
        setSyllabusList(updateSyllabus)
    }


    const deleteTopics = (index) => {
        console.log(index)
        const updateSyllabus = [...syllabusList]
        updateSyllabus.splice(index, 1)
        setSyllabusList(updateSyllabus)
    }

    const deleteSubTopics = (outIndex, inIndex) => {
        const updateSyllabus = [...syllabusList]
        updateSyllabus[outIndex].subTopics.splice(inIndex, 1)
        setSyllabusList(updateSyllabus)
    }
    const updateTopicName = (index, value) => {
        const updatedSyllabus = [...syllabusList];
        updatedSyllabus[index].topicName = value;
        setSyllabusList(updatedSyllabus);
    }

    const updateSubTopicName = (parentIndex, subIndex, value) => {
        const updatedSyllabus = [...syllabusList];
        updatedSyllabus[parentIndex].subTopics[subIndex].topicName = value;
        setSyllabusList(updatedSyllabus);
    }

    const cancelOperation = () => {
        syllabusForm.resetForm()
        setSyllabusList([])
        setSubjectList([])
    }

    return (
        <motion.div className="relative h-full w-full flex flex-col">
            <motion.div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                <FieldSelect label={'Class'} fieldProps={syllabusForm.getFieldProps('class')} fieldMeta={syllabusForm.getFieldMeta('class')} fieldHelper={syllabusForm.getFieldHelpers('class')} options={classMenuList} dataLoaded={true} edit={true} onChange={(ev) => selectSubjectMenu(ev)} />
                <FieldSelect label={'Subjects'} fieldProps={syllabusForm.getFieldProps('subject')} fieldMeta={syllabusForm.getFieldMeta('subject')} fieldHelper={syllabusForm.getFieldHelpers('subject')} options={subjectList} dataLoaded={true} edit={true} onChange={(ev) => getSyllabus(ev)} />
            </motion.div>
            <motion.div className="flex flex-col px-5 w-full">
                <motion.div className="flex flex-row justify-between">
                    <motion.div className="text-xl flex flex-col justify-center font-bold text-black">
                        Topics
                    </motion.div>
                    {syllabusList.length > 0 && <motion.div className="flex flex-row">
                        <Button type="primary" onClick={() => syllabusForm.submitForm()}>Save</Button>
                        <Button type="secondary" onClick={() => cancelOperation()}>Cancel</Button>
                    </motion.div>}
                </motion.div>
                {
                    syllabusList && syllabusList.map((c, i) => {
                        return (
                            <motion.div className="grid grid-cols-1 gap-4 my-1 w-full">
                                <motion.div className="w-full text-center text-black font-bold">
                                    UNIT - {i + 1}
                                </motion.div>
                                <motion.div className="flex flex-row justify-between w-full text-black text-md">
                                    <motion.div className="mx-2 ring-1 flex flex-row ring-black w-full px-2">
                                        ({i + 1})  <input type="text" value={c.topicName} onChange={(ev) => updateTopicName(i, ev.target.value)} className="h-7 w-full px-2 font-bold text-lg py-2 bg-transparent outline-none" />
                                    </motion.div>
                                    <FaTrashAlt className="text-center justify-center align-center my-2 text-[red] font-bold" onClick={() => deleteTopics(i)} />
                                </motion.div>
                                {
                                    c.subTopics && c.subTopics.length ? c.subTopics.map((e, j) => {
                                        return <motion.div className="flex flex-row w-full text-black text-md">
                                            <motion.div className="mx-2 ring-1 flex-row flex ring-black w-full px-2">
                                                ({i + 1}.{j + 1})  <input type="text" value={e.topicName} onChange={(ev) => updateSubTopicName(i, j, ev.target.value)} className="h-7 w-full font-bold text-md px-2 py-2 bg-transparent outline-none" />
                                            </motion.div>
                                            <FaTrashAlt className="text-center justify-center align-center my-2 text-[red] font-bold" onClick={() => deleteSubTopics(i, j)} />
                                        </motion.div>
                                    }) : ''
                                }
                                <motion.div className="mx-8 cursor-pointer underline underline-4 text-black" onClick={() => addSubTopics(i)}>
                                    Add Sub Topics
                                </motion.div>
                            </motion.div>
                        )
                    })
                }
                <motion.div className="cursor-pointer underline mx-2 my-2 underline-4 text-black" onClick={() => addTopics()}>
                    Add Topic
                </motion.div>
            </motion.div>
            <motion.div className="text-center flex flex-row justify-center">
                <Button type="primary" onClick={() => syllabusForm.submitForm()}>Save</Button>
                <Button type="secondary" onClick={() => cancelOperation()}>Cancel</Button>
            </motion.div>
        </motion.div>
    )
}

export default AddSyllabus