import { SET_LOGGEDIN, SET_TOKEN, SET_USER_DETAILS, SET_MENU_ITEMS, SET_ORG_ID } from "./constants";

export const setLoggedIn = (value) => {
    if (!value) {
        localStorage.removeItem('token')
    }
    return {
        type: SET_LOGGEDIN,
        payload: value
    }
}

export const setToken = value => ({
    type: SET_TOKEN,
    payload: value
})

export const setUserDetails = value => ({
    type: SET_USER_DETAILS,
    payload: value
})

export const setMenuItems = value => ({
    type: SET_MENU_ITEMS,
    payload: value
})

export const setOrgId = value => ({
    type: SET_ORG_ID,
    payload: value
})