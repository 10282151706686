import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import API from '../../Common/API';
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../Store/Theme/actions";
import { AiTwotoneCloseCircle } from "react-icons/ai";
import { calculateAcademicYear } from "../../Common/academicYear";

const ClassAllocation = () => {
    const [classList, setClassList] = useState([]);
    const [loading, setLoading] = useState(true);
    const orgId = useSelector(state => state.auth.orgId);
    const [dataLoad, setDataLoad] = useState(true);
    const dispatch = useDispatch();
    const [noOfStudent, setNoOfStudent] = useState(0);
    const [classMembersList, setClassMembersList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [needArrLength, setNeedArrLength] = useState(0);
    const [selectedClass, setSelectedClass] = useState('');
    const userDetails = useSelector(state => state.auth.userDetails);

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const apiRes = await API.get(`/class/classList/${orgId}`);
                if (apiRes.status === 200) {
                    setClassList(apiRes.data.data);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
                dispatch(setAlert({ title: 'Error', subtitle: 'Failed to fetch class list', active: true, type: "error" }));
            }
        };

        fetchClassList();
    }, [orgId, dispatch]);

    const fetchStudentRecords = async (values) => {
        try {
            setSelectedClass(values);
            const res = await API.get(`/class/getClassMemberList/${orgId}/${values}`);
            if (res.status === 200) {
                setClassMembersList(res.data.data.sort((a, b) => a.studentId.firstName.localeCompare(b.studentId.firstName)));
                setDataLoad(false);
            }
        } catch (err) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: "error" }));
        }
    };

    const generateSection = () => {
        if (!selectedClass) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Please select a class', active: true, type: 'error' }));
            return;
        }
        if (noOfStudent <= 0) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Please enter a valid number of students per section', active: true, type: 'error' }));
            return;
        }

        setNeedArrLength(Math.round(classMembersList.length / noOfStudent));
        setSectionList([]);

        if (noOfStudent > classMembersList.length) {
            dispatch(setAlert({ title: 'Error', subtitle: 'No of students per section is greater than the total members', active: true, type: 'error' }));
        } else {
            let chunkSize = parseInt(noOfStudent);
            let result = [];
            let sectionResult = [];

            for (let i = 0; i < classMembersList.length; i++) {
                result.push(classMembersList[i]);
                if (i === classMembersList.length - 1 || result.length === chunkSize) {
                    sectionResult.push(result);
                    result = [];
                }
                if (i === classMembersList.length - 1) {
                    sectionResult.forEach((c) => {
                        let values = {
                            sectionName: '',
                            studentList: c,
                        };
                        setSectionList((prevSections) => [...prevSections, values]);
                    });
                }
            }
        }
    };

    const changeInSectionList = (values, record, index) => {
        if (values > 0 && index === sectionList.length - 1 && sectionList[index].studentList.length === 0) {
            let newSectionList = [...sectionList];
            let newRecord = newSectionList[index - 1].studentList.reverse().splice(0, parseInt(values)).sort((a, b) => a.studentId.firstName.localeCompare(b.studentId.firstName));
            newSectionList[index - 1].studentList = newSectionList[index - 1].studentList.filter(
                (student) => !newRecord.includes(student)
            );
            newSectionList[index].studentList.push(...newRecord);
            setSectionList(newSectionList);
        } else if (values > 0) {
            let recordDifference = parseInt(values) - record.studentList.length;

            if (recordDifference > 0 && index === sectionList.length - 1) {
                dispatch(setAlert({ type: 'Error', subtitle: 'Invalid Strength Size', active: true }));
            } else {
                let newSectionList = [...sectionList];
                let newRecord = newSectionList[index + 1]?.studentList.splice(0, recordDifference);
                if (newRecord && newRecord.length > 0) {
                    if (newSectionList[index + 1].studentList.length === 0) {
                        newSectionList.splice(index + 1, 1);
                    } else {
                        newSectionList[index + 1].studentList = newSectionList[index + 1].studentList.filter(student => !newRecord.includes(student));
                    }

                    newSectionList[index].studentList.push(...newRecord);
                    setSectionList(newSectionList);
                } else {
                    dispatch(setAlert({ type: 'Error', subtitle: 'Insufficient students in the next section', active: true }));
                }
            }
        }
    };

    const addSection = () => {
        if (sectionList[sectionList.length - 1].studentList.length !== 0) {
            let values = {
                sectionName: '',
                studentList: []
            };
            setSectionList((prevSections) => [...prevSections, values]);
        } else {
            dispatch(setAlert({ title: 'Error', subtitle: 'Last section is empty, cannot add a new section', active: true, type: 'error' }));
        }
    };

    const removeSection = (index) => {
        const updateSection = [...sectionList];
        updateSection.splice(index, 1);
        setSectionList(updateSection);
    };

    const allotClass = async () => {
        if (sectionList.length === 0) {
            dispatch(setAlert({ title: 'Error', subtitle: 'No sections available to allot', active: true, type: 'error' }));
            return;
        }

        let payloadValues = sectionList.map((c) => {
            return {
                sectionName: c.sectionName,
                classId: classList.find((c) => c.className === selectedClass)._id,
                students: c.studentList.map((c) => c.studentId._id),
                admissionId: c.studentList.map((c) => c._id),
                className: selectedClass,
                academicYear: calculateAcademicYear(),
                noOfStudent: c.studentList.length,
                createdBy: userDetails.subject._id,
                orgId: orgId
            };
        });

        try {
            const apiRes = await API.post('/class/allotClass', payloadValues);
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Class Allotted Successfully', active: true, type: 'success' }));
                window.location.reload();
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
            }
        } catch (err) {
            dispatch(setAlert({ title: 'Error', subtitle: 'API Error', active: true, type: 'error' }));
        }
    };

    return (
        <>
            {loading ? "Loading Please wait" : (
                <motion.div className="relative h-full w-full flex flex-col">
                    <motion.div className="relative flex-1 w-full justify-start flex flex-row my-5">
                        <label className="mx-2 justify-center py-1">Select Class</label>
                        <select className="w-96 mb-1 mx-2 bg-transparent outline-none ring-1 ring-black px-1 py-1 h-9 rounded-lg" onChange={(ev) => fetchStudentRecords(ev.target.value)}>
                            <option value="">Select Class</option>
                            {classList.map((c) => (
                                <option key={c.className} value={c.className}>{c.className}</option>
                            ))}
                        </select>
                        <motion.div className="flex flex-col mx-10 text-center">
                            <motion.div>Total Members</motion.div>
                            <motion.div>{classMembersList.length}</motion.div>
                        </motion.div>
                        <motion.div className="flex flex-col mx-5 text-center">
                            <motion.div>No of student per section</motion.div>
                            <motion.div className="ring-1 ring-black rounded-lg">
                                <input type="number" value={noOfStudent} onChange={(ev) => setNoOfStudent(ev.target.value)} className="outline-none bg-transparent px-2 py-2 h-5" />
                            </motion.div>
                        </motion.div>
                        <motion.div className="flex flex-col mx-5">
                            <motion.div>Academic Year</motion.div>
                            <motion.div className="px-2 rounded-lg">{`${new Date().getFullYear()} - ${new Date().getFullYear() + 1}`}</motion.div>
                        </motion.div>
                        <motion.div className="flex flex-col text-center mx-5">
                            <motion.div className="bg-sColor cursor-pointer rounded-full px-4 mt-1 text-white py-1" onClick={generateSection}>
                                Generate Section
                            </motion.div>
                            <motion.div className="bg-sColor cursor-pointer rounded-full px-4 mt-1 text-white py-1" onClick={allotClass}>
                                Allot Class
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="relative flex flex-row">
                        {sectionList.map((c, i) => (
                            <motion.div className="flex flex-row mx-2 my-2 relative" key={i}>
                                {c.studentList.length === 0 && (
                                    <AiTwotoneCloseCircle className="absolute cursor-pointer -right-2 -top-2 text-xl font-bold" onClick={() => removeSection(i)} />
                                )}
                                <motion.div className="flex flex-col bg-sColor bg-opacity-60 px-2 py-2 rounded-lg h-screen">
                                    <motion.div className="ring-1 ring-black rounded-lg">
                                        <input type="text" id="sectionList" placeholder="Section Name" onInput={(ev) => c.sectionName = ev.target.value} className="outline-none text-white bg-transparent px-2 py-2 h-7" />
                                    </motion.div>
                                    <motion.div className="ring-1 ring-black my-1 rounded-lg">
                                        <input type="number" id="sectionList" placeholder="Section Size" onChange={(ev) => changeInSectionList(ev.target.value, c, i)} className="outline-none text-white bg-transparent px-2 py-2 h-7" />
                                    </motion.div>
                                    <p className="text-xs font-bold my-1 px-2">Adjust Section Size</p>
                                    <hr />
                                    {c.studentList.map((e) => (
                                        <motion.div key={e._id} className="flex font-medium my-1 mx-1 px-1 py-1 flex-col">
                                            {e.studentId.firstName}
                                        </motion.div>
                                    ))}
                                </motion.div>
                            </motion.div>
                        ))}
                        {sectionList.length > 0 && sectionList.length < needArrLength && (
                            <motion.div className="cursor-pointer mx-2 my-2 flex flex-col justify-center w-36 text-center text-white bg-sColor bg-opacity-60 px-2 py-2 rounded-lg h-screen" onClick={addSection}>
                                + Add Section
                            </motion.div>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default ClassAllocation;
