import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import { MdEdit } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux"
import building from '../../Assets/Images/building.jpeg'
import { useFormik } from "formik";
import Dialog from "../../Components/Dialog";
import Button from "../../Components/Button"
import { createPortal } from "react-dom";
import * as Yup from 'yup'
import FieldInput from "../../Components/FieldInput";
import { setAlert } from '../../Store/Theme/actions'

const Organisation = () => {

    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = userDetails.subject.profileType === 'user' ? userDetails.subject.orgId : userDetails.subject._id
    const [orgDetails, setOrgDetails] = useState();
    const [loading, setLoading] = useState(true)
    const [openEditOrgDialog, setOpenEditOrgDialog] = useState(false)
    const [imageSrc, setImageSrc] = useState(null);
    const [showUploadButton, setShowUploadButton] = useState(false)


    useEffect(() => {
        try {
            API.get(`/org/getOrgDetails/${orgId}`).then((res) => {
                setImageSrc(res.data.data.orgLogo)
                setOrgDetails({ ...res.data.data, address: res.data.data.orgAddressId })
                setLoading(false)
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    const orgFormSchema = Yup.object().shape({
        orgName: Yup.string().required("Org Name is required"),
        orgLogoName: Yup.string().nullable(),
        orgLogo: Yup.string().nullable(),
        orgEmail: Yup.string().required('Org Email is required'),
        orgMobile: Yup.string().required('Org Mobile is required'),
        address: Yup.object().shape({
            addressLine1: Yup.string().required("Address line 1 is required"),
            addressLine2: Yup.string(),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            district: Yup.string().required("District is required"),
            pinCode: Yup.string().required("Pincode is required")
        }),
    })

    const orgForm = useFormik({
        initialValues: orgDetails,
        validationSchema: orgFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            values.orgLogo = imageSrc
            API.post('/org/updateOrg', values).then((res) => {
                console.log(res)
            })
        }
    })

    // const uploadFile = (v, fileName) => {
    //     const fileType = v.type.split('/')[0];
    //     let result = validateFileType(v.size)
    //     if (result === undefined) {
    //         base64Conversion(v).then((value) => {
    //             setFile(value)
    //         })
    //     }
    // }

    // const base64Conversion = v => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(v);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = error => reject(error);
    // })

    // const validateFileType = (size) => {
    //     var fileName = size;
    //     if (fileName > 15728640) {
    //         dispatch(setAlert({ title: 'Warning', subtitle: 'Only Accept Below 15 Mb', type: 'warning', active: true }))
    //         return false;
    //     }
    // }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setImageSrc(e.target.result);
        };
        reader.readAsDataURL(file);
    };


    return (
        <motion.div>
            {
                loading ? "Loading please wait..." : <motion.div className='h-full px-5 py-2 w-full flex flex-col'>
                    <motion.div className="text-black font-bold text-md">
                        Org Details
                    </motion.div>
                    <motion.div className="text-black w-full h-64 px-2 py-2">
                        <motion.div className="flex flex-row ring-1 ring-sColor rounded-lg justify-between">
                            <motion.div className="flex flex-row px-2 py-2 justify-start">
                                <motion.div className="flex flex-col justify-center">
                                    <img src={imageSrc && imageSrc !== null && imageSrc !== undefined && imageSrc !== '' ? imageSrc : building} height={100} width={100} className="rounded-full" />
                                </motion.div>
                                <motion.div className="flex flex-col justify-center mx-2 ">
                                    <motion.div className="text-black font-bold">
                                        {orgDetails.orgName}
                                    </motion.div>
                                    <motion.div className="text-black font-medium">
                                        User Name : {orgDetails.orgUserName}
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            {userDetails.subject?._id === orgDetails._id && userDetails.subject?.role === 'Super Admin' && <motion.div className="cursor-pointer flex flex-col justify-center px-2" onClick={() => setOpenEditOrgDialog(true)}>
                                <motion.div className="flex px-2 bg-sColor rounded-full text-pColor flex-row ring-1">
                                    <MdEdit className="m-1" />Edit
                                </motion.div>
                            </motion.div>}
                        </motion.div>
                        <motion.div className="flex my-5 px-2 py-2 flex-col ring-1 ring-sColor rounded-lg">
                            <motion.div className="flex flex-row my-2 justify-between">
                                <p className="text-black font-bold text-md">Org Information</p>
                                {userDetails.subject?._id === orgDetails._id && userDetails.subject?.role === 'Super Admin' && <motion.div className="cursor-pointer  flex px-2 bg-sColor rounded-full text-pColor flex-row ring-1">
                                    <MdEdit className="m-1" />Edit
                                </motion.div>}
                            </motion.div>
                            <motion.div className="grid grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Org Name</p>
                                    <p className="text-medium font-bold">{orgDetails.orgName}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Org Suffix</p>
                                    <p className="text-medium font-bold">{orgDetails.orgSuffix}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Email</p>
                                    <p className="text-medium font-bold">{orgDetails.orgEmail}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Phone</p>
                                    <p className="text-medium font-bold">{orgDetails.orgMobile}</p>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                        <motion.div className="flex my-5 px-2 py-2 flex-col ring-1 ring-sColor rounded-lg">
                            <motion.div className="flex flex-row my-2 justify-between">
                                <p className="text-black font-bold text-md">Contact Information</p>
                                {userDetails.subject?._id === orgDetails._id && userDetails.subject?.role === 'Super Admin' && <motion.div className="cursor-pointer  flex px-2 bg-sColor rounded-full text-pColor flex-row ring-1">
                                    <MdEdit className="m-1" />Edit
                                </motion.div>}
                            </motion.div>
                            <motion.div className="grid grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Address Line1</p>
                                    <p className="text-medium font-bold">{orgDetails?.orgAddressId?.addressLine1}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">Address Line2</p>
                                    <p className="text-medium font-bold">{orgDetails?.orgAddressId?.addressLine2 ? orgDetails?.orgAddressId?.addressLine2 : '-'}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">City/Town/Village</p>
                                    <p className="text-medium font-bold">{orgDetails?.orgAddressId?.city}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">District</p>
                                    <p className="text-medium font-bold">{orgDetails?.orgAddressId?.district}</p>
                                </motion.div>
                                <motion.div className="flex flex-col px-5">
                                    <p className="text-[gray]">State</p>
                                    <p className="text-medium font-bold">{orgDetails?.orgAddressId?.state}</p>
                                </motion.div>
                                {/* <motion.div className="flex flex-col px-5">
                                <p className="text-[gray]">Country</p>
                                <p className="text-medium font-bold">{userDetails.subject.address.country}</p>
                            </motion.div> */}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
            {
                createPortal(<Dialog title="Edit Org" showDialog={openEditOrgDialog} onClose={() => setOpenEditOrgDialog(false)}>
                    <motion.div className="flex flex-col w-full my-2">
                        <motion.div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                            {/* <FieldInput label="Org Logo" accept=".png, .jpg, .jpeg" type="file" fieldMeta={orgForm.getFieldMeta('orgLogo')} fieldHelper={orgForm.getFieldHelpers('orgLogo')} fieldProps={orgForm.getFieldProps('orgLogo')} edit={true} dataloaded={true} onChange={(ev) => uploadFile(ev, orgForm.values.orgLogo)} /> */}
                            <motion.div className="relative justify-center w-full flex flex-row" onMouseEnter={() => setShowUploadButton(true)} onMouseLeave={() => setShowUploadButton(false)}>
                                <div className="flex flex-col relative">
                                    <input type="file" className="hidden" accept=".png, .jpg, .jpeg" id="openFileUpload" onChange={handleFileChange} />
                                    <img src={imageSrc && imageSrc !== null ? imageSrc : building} height={100} width={100} className="rounded-full" />
                                    {showUploadButton && <motion.div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-1 items-center text-black font-bold text-md cursor-pointer"
                                        whileHover={{ bottom: 0 }}
                                        onClick={() => document.getElementById('openFileUpload').click()}
                                    >
                                        Upload
                                    </motion.div>}
                                </div>
                            </motion.div>
                            <FieldInput label="Org Name" fieldMeta={orgForm.getFieldMeta('orgName')} fieldHelper={orgForm.getFieldHelpers('orgName')} fieldProps={orgForm.getFieldProps('orgName')} edit={true} dataloaded={true} />
                            <FieldInput label="Org UserName" fieldMeta={orgForm.getFieldMeta('orgUserName')} fieldHelper={orgForm.getFieldHelpers('orgUserName')} fieldProps={orgForm.getFieldProps('orgUserName')} edit={false} dataloaded={true} />
                            <FieldInput label="Org Email" fieldMeta={orgForm.getFieldMeta('orgEmail')} fieldHelper={orgForm.getFieldHelpers('orgEmail')} fieldProps={orgForm.getFieldProps('orgEmail')} edit={true} dataloaded={true} />
                            <FieldInput label="Org Mobile" fieldMeta={orgForm.getFieldMeta('orgMobile')} fieldHelper={orgForm.getFieldHelpers('orgMobile')} fieldProps={orgForm.getFieldProps('orgMobile')} edit={true} dataloaded={true} />
                            <FieldInput label="Address Line 1" fieldMeta={orgForm.getFieldMeta('address.addressLine1')} fieldHelper={orgForm.getFieldHelpers('address.addressLine1')} fieldProps={orgForm.getFieldProps('address.addressLine1')} edit={true} dataloaded={true} />
                            <FieldInput label="Address Line 2" fieldMeta={orgForm.getFieldMeta('address.addressLine2')} fieldHelper={orgForm.getFieldHelpers('address.addressLine2')} fieldProps={orgForm.getFieldProps('address.addressLine2')} edit={true} dataloaded={true} />
                            <FieldInput label="City" fieldMeta={orgForm.getFieldMeta('address.city')} fieldHelper={orgForm.getFieldHelpers('address.city')} fieldProps={orgForm.getFieldProps('address.city')} edit={true} dataloaded={true} />
                            <FieldInput label="State" fieldMeta={orgForm.getFieldMeta('address.state')} fieldHelper={orgForm.getFieldHelpers('address.state')} fieldProps={orgForm.getFieldProps('address.state')} edit={true} dataloaded={true} />
                            <FieldInput label="District" fieldMeta={orgForm.getFieldMeta('address.district')} fieldHelper={orgForm.getFieldHelpers('address.district')} fieldProps={orgForm.getFieldProps('address.district')} edit={true} dataloaded={true} />
                            <FieldInput label="Pincode" fieldMeta={orgForm.getFieldMeta('address.pincode')} fieldHelper={orgForm.getFieldHelpers('address.pincode')} fieldProps={orgForm.getFieldProps('address.pincode')} edit={true} dataloaded={true} />
                        </motion.div>
                        <motion.div className="flex flex-row w-full justify-between">
                            <Button type='secondary' onClick={() => setOpenEditOrgDialog(false)}>Cancel</Button>
                            <Button type='primary' onClick={() => orgForm.submitForm()}>Save</Button>
                        </motion.div>
                    </motion.div>
                </Dialog>, document.body)
            }
        </motion.div>
    )
}

export default Organisation