import React, { useEffect, useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import API from '../../Common/API'
import { useSelector,useDispatch } from 'react-redux'
import { FaCheck, FaTrash, FaPlusCircle } from 'react-icons/fa'
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"
import InlineTextField from '../../Components/InLineTextField'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Dialog from '../../Components/Dialog'
import FieldInput from '../../Components/FieldInput'
import Button from '../../Components/Button'
import { createPortal } from 'react-dom'
import { setAlert } from "../../Store/Theme/actions";

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialPackageFormValues = {
    packageName: '',
    price: 0,
    cgst: 0,
    sgst: 0,
    fees: [],
    orgId: '',
    createdBy: ''
}

const Package = () => {
    const [loading, setLoading] = useState(true)
    const [feesList, setFeesList] = useState([])
    const [packageList, setPackageList] = useState([])
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [openAddPackageDialog, setOpenAddPackageDialog] = useState(false)
    const [disableSave, setDisableSave] = useState(false)
    const dispatch = useDispatch();



    useEffect(() => {
        const fetchData = async () => {
            try {
                const packageRes = await API.get(`/finance/feesPackage/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`)
                setPackageList(packageRes.data.data)
                
                const feesRes = await API.get(`/finance/getFeesList/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`)
                setFeesList(feesRes.data.data)
                
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }

        fetchData()
    }, [userDetails.subject.profileType, userDetails.subject._id, userDetails.subject.orgId])

    const activePackage = async (details) => {
        try {
            const apiRes = await API.post('/finance/activeFeePackage', details);
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Fees Package activated successfully', active: true, type: 'success' }));
    
                const packageRes = await API.get(`/finance/feesPackage/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`);
                setPackageList(packageRes.data.data);
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
            }
        } catch (err) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
        }
    };
    

    const deactivePackage = async (details) => {
        try {
            const apiRes = await API.post('/finance/deactiveFeePackage', details);
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Fees Package De-Activated successfully', active: true, type: 'success' }));
    
                const packageRes = await API.get(`/finance/feesPackage/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`);
                setPackageList(packageRes.data.data);
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
            }
        } catch (err) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
        }
    }

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Package Name', selector: row => row.packageName, initialActive: true, sortable: true },
            { name: 'Price', selector: row => row.price, grow: 1, sortable: true },
            { name: 'SGST', selector: row => row.sgst, grow: 1, sortable: true },
            { name: 'CGST', selector: row => row.cgst, grow: 1, sortable: true },
            { name: 'Status', selector: row => row.activeStat === 'P' ? <motion.div className="pendingChip">Pending</motion.div> : row.activeStat === 'A' ? <motion.div className="activeChip">Active</motion.div> : <motion.div className="deactiveChip">Deactivated</motion.div>, sortable: true },
            {
                name: 'Actions', selector: row => <motion.div className="flex flex-row justify-around">
                    {(row.activeStat === 'P' || row.activeStat === 'D') && <FaCheck className="mx-2 text-[green] cursor-pointer" title="Activate" onClick={() => activePackage(row)} />}
                    {(row.activeStat === 'P' || row.activeStat === 'A') && <FaTrash className="mx-2 text-[red] cursor-pointer" title="Deactivate" onClick={() => deactivePackage(row)} />}
                </motion.div>, omit: (userDetails.subject.role !== 'Super Admin')
            }
        ]
    }, [userDetails.subject.role])

    const SubHeader = useMemo(() => (
        <div className="flex flex-row">
            <FaPlusCircle className="mx-2 my-2 text-sColor cursor-pointer" onClick={() => setOpenAddPackageDialog(true)} />
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter])

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return packageList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [packageList, filter])

    const packageFormSchema = Yup.object().shape({
        packageName: Yup.string().required("Package Name is required"),
        price: Yup.number().required("Price is required"),
        cgst: Yup.number(),
        sgst: Yup.number()
    })

    const packageForm = useFormik({
        initialValues: initialPackageFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: packageFormSchema,
        onSubmit: async (values) => {
            setDisableSave(true);
            if (values.fees.length <= 1) {
                dispatch(setAlert({ type: 'Error', subtitle: 'Please Select Atleast 2 fees to save', active: true }));
                setDisableSave(false);
                return;
            }
            values.orgId = userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId;
            values.createdBy = userDetails.subject._id;
            try {
                const typeRes = await API.post('/finance/addFeesPackage', values);
                if (typeRes.status === 200) {
                    packageForm.resetForm();
                    setOpenAddPackageDialog(false);
                    setDisableSave(false);
                    dispatch(setAlert({ title: 'Success', subtitle: "Fees Package Added Successfully", active: true, type: "success" }));
                    // Refetch package list
                    const packageRes = await API.get(`/finance/feesPackage/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`);
                    setPackageList(packageRes.data.data);
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
                    setDisableSave(false);
                }
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }));
                setDisableSave(false);
            }
        }
    });
    

    const cancel = () => {
        setOpenAddPackageDialog(false)
        packageForm.resetForm()
    }

    const handleCheckboxChange = (feesId) => {
        const updatedFees = packageForm.values.fees.includes(feesId)
            ? packageForm.values.fees.filter(id => id !== feesId)
            : [...packageForm.values.fees, feesId]
        packageForm.setFieldValue('fees', updatedFees)
    }

    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 px-2 py-2 rounded-lg w-full h-full`}>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Package List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
            {
                createPortal(<Dialog title={'Create Package'} showDialog={openAddPackageDialog} onClose={() => setOpenAddPackageDialog(false)}>
                    <motion.div className={'relative px-2 py-2 w-full flex flex-col'}>
                        <FieldInput label={'Package Name'} fieldProps={packageForm.getFieldProps('packageName')} fieldHelper={packageForm.getFieldHelpers('packageName')} fieldMeta={packageForm.getFieldMeta('packageName')} edit={true} dataLoaded={true} />
                        <motion.div className={'h-64 mx-2 overflow-y-scroll flex flex-col'}>
                            <motion.div className={'text-sm text-black font-bold'}>Select Fees</motion.div>
                            <motion.div>
                                {
                                    feesList.map((c) => {
                                        return (
                                            <motion.div className={'flex flex-col'} key={c._id}>
                                                <motion.div className={'flex flex-row'}>
                                                    <input type="checkbox" className="mx-2" checked={packageForm.values.fees.includes(c._id)} onChange={() => handleCheckboxChange(c._id)} /> {c.feesName}
                                                </motion.div>
                                            </motion.div>
                                        )
                                    })
                                }
                            </motion.div>
                        </motion.div>
                        <FieldInput type="number" label={'Price'} fieldProps={packageForm.getFieldProps('price')} fieldHelper={packageForm.getFieldHelpers('price')} fieldMeta={packageForm.getFieldMeta('price')} edit={true} dataLoaded={true} />
                        <FieldInput type="number" label={'SGST'} fieldProps={packageForm.getFieldProps('sgst')} fieldHelper={packageForm.getFieldHelpers('sgst')} fieldMeta={packageForm.getFieldMeta('sgst')} edit={true} dataLoaded={true} />
                        <FieldInput type="number" label={'CGST'} fieldProps={packageForm.getFieldProps('cgst')} fieldHelper={packageForm.getFieldHelpers('cgst')} fieldMeta={packageForm.getFieldMeta('cgst')} edit={true} dataLoaded={true} />
                        <motion.div className="flex flex-row justify-end">
                            <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                            <Button type="primary" onClick={() => !disableSave && packageForm.submitForm()}>Save</Button>
                        </motion.div>
                    </motion.div>
                </Dialog>, document.body)
            }
        </motion.div>
    )
}

export default Package
