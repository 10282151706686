import React, { useMemo, useEffect, useState } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import { useSelector, useDispatch } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import { calculateAcademicYear } from "../../Common/academicYear"
import moment from 'moment-timezone';
import Button from "../../Components/Button"
import axios from "axios"
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInput from "../../Components/FieldInput"
import FieldSelect from "../../Components/FieldSelect"

const initialFormValues = {
    fullDate: '',
    session: '',
}

const MarkAttendence = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const [studentsList, setStudentsList] = useState([])
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const academicYear = calculateAcademicYear()
    const [absentList, setAbsentList] = useState([])
    const [count, setCount] = useState(0)
    const currentTimezone = moment.tz.guess(); // Automatically detect the timezone
    const currentTime = moment().tz(currentTimezone).format('YYYY-MM-DD HH:mm:ss A');
    const sessions = [{ text: 'FN', value: 'FN' }, { text: 'AN', value: 'AN' }]

    useEffect(() => {
        try {
            setLoading(true)
            axios.all([API.get(`/class/getAllStudents/${orgId}/${academicYear}`), API.get(`/class/getAttendence/${orgId}/${currentTime.split(' ')[0]}`)]).then(axios.spread((stuRes, attRes) => {
                setStudentsList(stuRes.data.data)
                const attResult = attRes.data.data.map((c) => {
                    delete c._id
                    return c
                })
                setAbsentList(attResult)
                setLoading(false)
            }))
        } catch (err) {
            console.log(err)
        }
    }, [count])

    const formData = useMemo(() => {
        initialFormValues.fullDate = currentTime.split(' ')[0]
        initialFormValues.session = currentTime.split(' ')[2] === 'AM' ? 'FN' : 'AN'
        return { ...initialFormValues }
    }, [])

    const attendenceShcema = Yup.object().shape({
        fullDate: Yup.string(),
        session: Yup.string()
    })

    const attendenceForm = useFormik({
        initialValues: formData,
        validateOnMount: true,
        validationSchema: attendenceShcema,
        enableReinitialize: true,
        onSubmit: values => {
            console.log(values)
        }
    })

    const selectAbsenties = (classValues, values, secValues) => {
        console.log(classValues,values,secValues,'dat')
        let val = {
            section: secValues,
            class: classValues.classId._id,
            admission: values._id,
            attendenceStat: 'Absent',
            academicYear: academicYear,
            date: attendenceForm.values.fullDate.split('-')[2],
            month: attendenceForm.values.fullDate.split('-')[1],
            year: attendenceForm.values.fullDate.split('-')[0],
            org: orgId,
            fullDate: attendenceForm.values.fullDate,
            session: attendenceForm.values.session
        }
        if (absentList.some((x) => x.admission === val.admission && x.class === val.class && x.fullDate === val.fullDate && x.session === val.session)) {
            const updateList = [...absentList]
            const findIndex = updateList.findIndex((x) => x.admission === val.admission && x.class === val.class && x.fullDate === val.fullDate && x.session === val.session)
            updateList.splice(findIndex, 1)
            setAbsentList(updateList)
        } else if (absentList.some((x) => x.admission === val.admission && x.class === val.class && x.fullDate === val.fullDate && x.session !== val.session)) {
            const updateList = [...absentList]
            const findIndex = updateList.findIndex((x) => x.admission === val.admission && x.class === val.class && x.fullDate === val.fullDate && x.session !== val.session)
            updateList.splice(findIndex, 1)
            setAbsentList(updateList)
        } else {
            setAbsentList([...absentList, val])
        }
    }

    const submitAttendence = async () => {
        const presentList = []
        studentsList.forEach((c) => {
            const filterStudents = c.admissionId.filter((x) => {
                return !absentList.some((ad) => ad.admission === x._id);
            });
            filterStudents.forEach((value) => {
                let val = {
                    section: c._id,
                    class: c.classId._id,
                    admission: value._id,
                    attendenceStat: 'Present',
                    academicYear: academicYear,
                    date: attendenceForm.values.fullDate.split('-')[2],
                    month: attendenceForm.values.fullDate.split('-')[1],
                    year: attendenceForm.values.fullDate.split('-')[0],
                    org: orgId,
                    fullDate: attendenceForm.values.fullDate,
                    session: attendenceForm.values.session
                }
                presentList.push(val)
            })
        })
        let values = [...absentList, ...presentList]
        // API.post(`/class/markAttendence`, absentList).then((res) => {
        //     if (res.status === 200) {
        //         dispatch(setAlert({ title: 'Success', subtitle: 'Attendence marked for the session successfully', active: true, type: 'success' }))
        //         setCount(count + 1)
        //     }
        // }).catch((err) => {
        //     console.log(err)
        // })
        Promise.all([
            API.post(`/class/markAttendence`, values),
            // Other asynchronous operations if needed
        ])
            .then((responses) => {
                // Handle responses
                const markAttendenceResponse = responses[0];
                if (markAttendenceResponse.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Attendance marked for the session successfully', active: true, type: 'success' }));
                    setCount(count + 1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <>
            {
                loading ? "Loading please wait" : <motion.div className="relative h-full w-full flex flex-col">
                    <motion.div className="text-black font-bold flex flex-row w-full h-full items-center">
                        <motion.div className="grid grid-cols-3 gap-5 w-full items-center">
                            {currentTime.split(' ')[2] === 'PM' ? 'Good Afternoon' : 'Good Morning'}
                            <FieldInput label={'Select Date'} type="date" fieldHelper={attendenceForm.getFieldHelpers('fullDate')} fieldMeta={attendenceForm.getFieldMeta('fullDate')} fieldProps={attendenceForm.getFieldProps('fullDate')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'Session'} fieldProps={attendenceForm.getFieldProps('session')} fieldHelper={attendenceForm.getFieldHelpers('session')} fieldMeta={attendenceForm.getFieldMeta('session')} options={sessions} edit={true} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    {studentsList.length > 0 ? studentsList.map((c, i) => {
                        return <motion.div key={i} className="relative bg-sColor my-2 bg-opacity-60 rounded-xl min-h-12 px-4 py-2 max-h-auto">
                            <motion.div className="text-black font-bold">
                                {c.className} {c.sectionName}
                            </motion.div>
                            <motion.div className="flex flex-row my-2 flex-wrap relative ">
                                {
                                    c.admissionId.map((admission, j) => {
                                        return <motion.div key={j} className={`cursor-pointer ${absentList.some((c) => c.admission === admission._id) ? 'bg-sColor text-white font-bold' : 'bg-pColor text-black '}hover:text-white hover:bg-sColor rounded-full mx-2 my-1 px-5 py-1`} onClick={() => selectAbsenties(c, admission, c._id)}>
                                            {admission.rollNumber}
                                        </motion.div>
                                    })
                                }
                            </motion.div>
                        </motion.div>
                    }) : ''}
                    <motion.div className="w-full text-center items-center justify-center flex flex-row">
                        <Button type="primary" onClick={() => submitAttendence()}>Mark Attendence</Button>
                        <Button type="secondary">Cancel</Button>
                    </motion.div>
                </motion.div>
            }
        </>
    )
}

export default MarkAttendence