import React, { useEffect, useState, useMemo, useCallback } from "react"
import API from '../../Common/API'
import { useSelector } from "react-redux"
import DataTable, { createTheme } from "react-data-table-component"
import { datatableTheme } from "../../Common/dataTableTheme"
import { motion } from 'framer-motion'
import InlineTextField from "../../Components/InLineTextField"
import DatatableLoader from '../../Components/DatatableLoader'
import 'moment-timezone';
import moment from 'moment';
import { setAlert } from '../../Store/Theme/actions'
import { useDispatch } from "react-redux"
import Dialog from "../../Components/Dialog"
import { useFormik } from "formik"
import FieldInput from "../../Components/FieldInput"
import * as Yup from 'yup'
import Button from '../../Components/Button'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialPaymentForm = {
    totalAmount: 0,
    payingAmount: '',
    balanceAmount: 0,
    invoiceId: '',
    userId: '',
    orgId: '',
}

const InvoiceList = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const [loading, setLoading] = useState(true)
    const [paymentList, setPaymentList] = useState([])
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState('')
    const [currentRow, setCurrentRow] = useState(null)
    const dispatch = useDispatch()
    const [invoiceHistory, setInvoiceHistory] = useState([])
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
    const [statusId, setStatusId] = useState()
    const [count, setCount] = useState(0)

    const paymentFormSchema = Yup.object().shape({
        totalAmount: Yup.number(),
        payingAmount: Yup.number(),
        balanceAmount: Yup.number(),
        invoiceId: Yup.string(),
        userId: Yup.string(),
        orgId: Yup.string(),
    })

    const paymentForm = useFormik({
        initialValues: initialPaymentForm,
        validateOnMount: true,
        validationSchema: paymentFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(paymentForm.values)
            if(!paymentForm.values.payingAmount || (parseInt(paymentForm.values.payingAmount) > parseInt(paymentForm.values.balanceAmount))){
                dispatch(setAlert({ title: 'Error', subtitle: 'Please Enter the Valid Amount', type: 'error', active: true }))
                return
            }
            values.statusId = statusId
            try {
                API.post('/finance/payBalanceAmount', values).then((res) => {
                    if (res.status === 200) {
                        setOpenPaymentDialog(false)
                        paymentForm.resetForm()
                        dispatch(setAlert({ title: 'Success', subtitle: 'Fees paid successfully', active: true, type: 'success' }))
                        setCount(count + 1)
                    }
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            }
        }
    })


    useEffect(() => {
        API.get(`/finance/getStudentPayments/${orgId}`).then((res) => {
            setPaymentList(res.data.data)
            setLoading(false)
        })
    }, [count])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            { name: '#Invoice', selector: row => row.invoiceId, initialActive: true, sortable: true },
            { name: 'Name', selector: row => row.userId.firstName + row.userId.lastName, sortable: true },
            { name: 'Total Amount', selector: row => row.totalAmount, sortable: true },
            { name: 'Paid Amount', selector: row => row.amountPaid, sortable: true },
            { name: 'Outstanding Amount', selector: row => row.totalAmount - row.amountPaid, sortable: true },
            { name: 'Status', selector: row => row.paymentStat === 'NP' ? <motion.div className="deactiveChip">Not Paid</motion.div> : row.paymentStat === 'PP' ? <motion.div className="pendingChip">Partially Paid</motion.div> : <motion.div className="activeChip">Paid</motion.div> },
            { name: 'Actions', selector: row => row.paymentStat === 'NP' || row.paymentStat === 'PP' ? <motion.div className="flex flex-row justify-around">
                    <motion.div className="cursor-pointer bg-sColor px-2 py-1 hover:bg-pColor hover:text-black rounded-full w-12 text-center text-white" onClick={() => initiatePayment(row)}>Pay</motion.div>
                </motion.div>:<motion.div></motion.div>
            }
        ]
    })

    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return paymentList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [paymentList, filter])/// eslint-disable-line

    const updatedate = (value) => {
        console.log(value, "hi")
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
        // let updatedates = new Date(value)
        // // updatedates = (updatedates.toUTCString().slice(4,21))
        // return `${updatedates.toUTCString().slice(8,11)}${updatedates.toUTCString().slice(4,7)}, ${updatedates.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    }

    const historyColumns = useMemo(() => {
        return [
            { name: 'S.No', center: true, selector: (row, i) => i + 1, sortable: true },
            { name: 'Amount Paid', center: true, selector: row => row.amountPaid, sortable: true },
            { name: 'Balance Amount', center: true, selector: row => row.balanceAmount, sortable: true },
            { name: 'Total Amount', center: true, selector: row => row.totalAmount, sortable: true },
            { name: 'Paid Date', center: true, selector: row => updatedate(row.createdAt), sortable: true }
        ]
    })

    const rowExpanded = (data, row) => {
        setCurrentRow(row.invoiceId)
        if (data) {
            API.get(`/finance/getPaymentHistory/${orgId}/${row.invoiceId}`).then((historyRes) => {
                if (historyRes.status === 200) {
                    setInvoiceHistory(historyRes.data.data)
                }
            }).catch((err) => {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            })
        }
    }

    const RowComponent = useCallback(row => {
        return (
            <div className="dark:bg-ldark py-2 px-3" key={row.data.invoiceId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])

    const NoHistory = () => (
        <div className="my-6 text-sm text-gray-500 dark:text-gray-400">
            {'No history to display'}
        </div>
    )
    const ExpandComponent = useCallback(({ data }) => {
        return (
            <div className="p-4 bg-bluegray-300 dark:bg-ldark">
                {
                    <DataTable columns={historyColumns} data={invoiceHistory} pagination noHeader highlightOnHover persistTableHead
                        selectableRowsHighlight onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRowsComponent={<RowComponent />} noDataComponent={<NoHistory />} />
                }
            </div>
        )
    }, [invoiceHistory]) // eslint-disable-line

    const cancelPayment = () => {
        setOpenPaymentDialog(false)
        paymentForm.resetForm()
    }

    const initiatePayment = (values) => {
        setStatusId(values._id)
        let details = {
            totalAmount: values.totalAmount,
            balanceAmount: values.totalAmount - values.amountPaid,
            invoiceId: values.invoiceId,
            userId: values.userId._id,
            orgId: values.orgId
        }
        paymentForm.setValues({ ...paymentForm.values, ...details })
        setOpenPaymentDialog(true)
    }

    // const balanceCalculate = (value) => {
    //     console.log((parseInt(paymentForm.values.totalAmount) - parseInt(paymentForm.values.amountPaid)) - parseInt(value))
    //     paymentForm.setValues({ ...paymentForm.values, balanceAmount: parseInt(parseInt(paymentForm.values.totalAmount) - parseInt(paymentForm.values.amountPaid)) - parseInt(value) })
    // }

    

    return (
        <motion.div>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="User List" theme="defaultLight" columns={columns} data={filteredcasedetails}
                        pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader
                        expandableRows expandableRowExpanded={(row) => (row.invoiceId === currentRow)} expandableRowsComponent={ExpandComponent} onRowExpandToggled={rowExpanded}
                        subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
            <Dialog title={"Pay Payment"} showDialog={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)}>
                <motion.div className={'relative px-2 py-2 w-full grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3'}>
                    <FieldInput label={'Invoice Id'} fieldProps={paymentForm.getFieldProps('invoiceId')} fieldMeta={paymentForm.getFieldMeta('invoiceId')} fieldHelper={paymentForm.getFieldHelpers('invoiceId')} edit={false} dataLoaded={true} />
                    <FieldInput label={'Total Amount'} fieldProps={paymentForm.getFieldProps('totalAmount')} fieldMeta={paymentForm.getFieldMeta('totalAmount')} fieldHelper={paymentForm.getFieldHelpers('totalAmount')} edit={false} dataLoaded={true} />
                    <FieldInput label={'Balance Amount'} fieldProps={paymentForm.getFieldProps('balanceAmount')} fieldMeta={paymentForm.getFieldMeta('balanceAmount')} fieldHelper={paymentForm.getFieldHelpers('balanceAmount')} edit={false} dataLoaded={true} />
                    <motion.div className="col-span-3">
                        <FieldInput type="number" label={'Paying Amount'} fieldProps={paymentForm.getFieldProps('payingAmount')} fieldMeta={paymentForm.getFieldMeta('payingAmount')} fieldHelper={paymentForm.getFieldHelpers('payingAmount')} edit={true} dataLoaded={true} />
                    </motion.div>
                    <motion.div className="flex flex-row col-span-3 justify-end">
                        <Button type="primary" onClick={() => paymentForm.submitForm()}>Pay</Button>
                        <Button type="secondary" onClick={() => cancelPayment()}>Cancel</Button>
                    </motion.div>
                </motion.div>
            </Dialog>
        </motion.div>
    )
}

export default InvoiceList