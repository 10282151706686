import React, { useState, useMemo, useEffect, useLayoutEffect, useCallback } from "react";
import { motion } from 'framer-motion';
import logoPic from '../Assets/Images/dummyLogo.svg';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { HomeMenu } from "../Common/HomeRoutes";
import { CombinedRoutes } from "../Common/Routes";
import { setSideBar } from "../Store/Theme/actions";
import { AiOutlineMenuFold } from 'react-icons/ai';
import { wrapAnim, TextAnimateY } from '../Common/anims';
import { ImHome } from "react-icons/im";
import { Link, withRouter } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { setLoggedIn } from "../Store/Auth/actions";

const intialAnime = {
    hidden: {
        x: '-100vw',
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'easeOut',
            duration: 0.8
        }
    }
};
let xStart = 0;
let yStart = 0;

const SideBar = () => {
    const [highlighted, setHighlighted] = useState('Home');
    const sideBar = useSelector(state => state.theme.sideBar);
    const [sideBarExpanded, setSideBarExpanded] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const userDetails = useSelector(state => state.auth.userDetails);
    const [subMenu, setSubMenu] = useState([]);

    const filteredCombinedRoutes = useMemo(() => (
        CombinedRoutes.filter(c => c.roles === undefined || c.roles.some(e => userDetails !== undefined && userDetails.role !== undefined && userDetails?.role.includes(e)))
    ), [userDetails]); // eslint-disable-line

    const HomeMenuList = useMemo(() => {
        return HomeMenu.filter(c => c.sidebar)
            .filter(c => {
                if (c.roles.includes(userDetails.subject.roleId || userDetails.subject.role)) {
                    return true;
                }
                return false;
            });
    }, [userDetails]); // eslint-disable-line

    const isPathIcon = (title) => {
        if (history.location.pathname !== '/') {
            for (let route of filteredCombinedRoutes) {
                if (history.location.pathname.includes(route.link) && route.menu === title) return true;
            }
        }
        return false;
    };

    const selectedMainMenu = useMemo(() => {
        setSubMenu(CombinedRoutes.filter((c) => c.subTitle === highlighted));
        return HomeMenuList.find(c => c.title === highlighted);
    }, [highlighted]);

    const hasMenuItems = useCallback((title) => {
        let menu = HomeMenuList.find(c => c.title === title);
        return menu ? filteredCombinedRoutes.filter(c => c.menu === menu.title).length > 0 : false;
    }, [HomeMenuList, filteredCombinedRoutes]);

    const sideMenuList = useMemo(() => {
        let menu = HomeMenuList.find(c => c.title === highlighted);
        return menu ? filteredCombinedRoutes.filter(c => c.menu === menu.title) : [];
    }, [highlighted]); // eslint-disable-line

    const hideSidebar = () => dispatch(setSideBar(false));
    const showSideBar = () => dispatch(setSideBar(true));
    const dragStart = ev => {
        if (ev.changedTouches === undefined) return;
        xStart = ev.changedTouches[0].screenX;
        yStart = ev.changedTouches[0].screenY;
    };
    const dragEnd = ev => {
        if (ev.changedTouches === undefined) return;
        let xEnd = ev.changedTouches[0].screenX;
        if (Math.abs(yStart - ev.changedTouches[0].screenY) > 100) return;
        let diff = xStart - xEnd;
        if (xStart > window.innerWidth / 2 && diff > 150) hideSidebar();
        else if (xStart < 1000 && diff < -150) showSideBar();
    };
    const setHighlightedMenu = () => {
        let found = false;
        filteredCombinedRoutes.forEach((c, i) => {
            if (c.link === history.location.pathname) {
                if (!c.menu) return;
                found = true;
                setHighlighted(c.menu);
            }
        });
        if (!found) setHighlighted(HomeMenuList[0].title);
    };
    const expandSideBar = () => setSideBarExpanded(true);
    const shrinkSideBar = () => setSideBarExpanded(false);

    useEffect(() => {
        hideSidebar();
    }, [history.location.pathname]); // eslint-disable-line
    useEffect(() => {
        if (!sideBar || !sideBarExpanded)
            setHighlightedMenu();
    }, [sideBar, sideBarExpanded]); // eslint-disable-line
    useEffect(() => setHighlightedMenu(), []); // eslint-disable-line
    useLayoutEffect(() => {
        window.addEventListener('touchstart', dragStart);
        window.addEventListener('dragstart', dragStart);
        window.addEventListener('touchend', dragEnd);
        window.addEventListener('dragend', dragEnd);

        return () => {
            window.removeEventListener('touchstart', dragStart);
            window.removeEventListener('dragstart', dragStart);
            window.removeEventListener('touchend', dragEnd);
            window.removeEventListener('dragend', dragEnd);
        };
    }, []); // eslint-disable-line

    const logout = () => {
        localStorage.removeItem('token');
        history.push('/auth/login');
        dispatch(setLoggedIn(false));
    };

    const homeRoutes = () => {
        setHighlighted('Home');
        history.push('/');
    };

    return (
        <>
            <motion.div className={`w-[320px] SideBar ${sideBarExpanded ? '' : 'lg:w-[56px]'} h-[100vh] text-pColor bg-sColor flex flex-col justify-start items-center relative shadow-xl flex-shrink-0 lg:left-0 top-0 bottom-0 z-10 transition-all duration-200 ${sideBar ? 'left-0' : '-left-96'}`} onMouseEnter={expandSideBar} onMouseLeave={shrinkSideBar}>
                <motion.div className="border-b w-full flex justify-center py-4 items-center h-[80px] relative">
                    <img src={userDetails?.subject?.orgLogo} height={100} width={100} className="justify-start" />
                    <AiOutlineMenuFold size="25px" className="absolute right-5 cursor-pointer text-tcolor dark:text-white lg:hidden" onClick={hideSidebar} />
                </motion.div>
                <motion.div className={'flex w-full overflow-y-auto overflow-x-hidden h-full relative'}>
                    <div className="h-full flex flex-col items-center text-start border-r border-[#EED6D3] w-14 flex-shrink-0 z-20">
                        <div>
                          <ImHome className={`my-5 hover:text-black cursor-pointer ${highlighted === 'Home' ? 'text-black font-bold text-xl' : ''}`} onClick={homeRoutes} />
                        </div>
                        <motion.div className={'flex flex-col justify-between items-center'}>
                            {HomeMenuList.map((c, i) => (
                                <motion.div key={i} className={`hover:text-black my-5 mx-5 cursor-pointer`} onMouseEnter={() => c.extendSidebar === false ? '' : setHighlighted(c.title)} onClick={() => setHighlighted(c.title)} title={(c.subtitle)}>
                                    <c.TitleIcon />
                                </motion.div>
                            ))}
                        </motion.div>
                        <div>
                           <FiLogOut className="cursor-pointer text-pColor hover:text-black font-bold text-md" onClick={logout} />

                        </div>
                    </div>
                    <div className={`flex flex-col bg-sColor dark:bg-ddark w-full pb-10 pt-5 px-2 overflow-y-auto overflow-x-auto ${sideBarExpanded ? 'block' : 'lg:hidden'}`}>
                        <div className="mb-5 text-blue-500 w-full justify-center text-center items-center dark:text-yellow-600 text-sm font-bold">
                            {selectedMainMenu !== undefined && selectedMainMenu.title}
                        </div>
                        {subMenu.map((c, i) => (
                            c.roles.includes(userDetails.subject.role || userDetails.subject.roleId) && (
                                <Link key={i} to={c.link} className="block z-10 my-1 text-center">
                                    <motion.span variants={TextAnimateY} className={`flex items-center py-2 pl-2 hover:rounded hover:bg-pColor cursor-pointer hover:text-black text-xs ${history.location.pathname === c.link ? 'text-tscolor font-bold' : 'text-black font-bold'}`}>
                                        <c.ItemIcon size="14px" className="mr-2" />
                                        {c.title}
                                    </motion.span>
                                </Link>
                            )
                        ))}
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
};

export default SideBar;
