import { FaBuilding, FaFingerprint, FaCogs, FaUsers } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { IoWalletSharp } from "react-icons/io5";
import { MdOutlineEmojiTransportation, MdSportsHandball } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { GiFiles } from "react-icons/gi";
import { HiMegaphone } from "react-icons/hi2";
import { SlGraph } from "react-icons/sl";
import { GrDocumentTime } from "react-icons/gr";
import { HiAcademicCap } from "react-icons/hi";

export const HomeMenu = [
    {
        TitleIcon: GoOrganization,
        title: 'Admission',
        subtitle: 'Admission',
        roles: ['Super Admin', 'Office Admin'],
        sidebar: true
    },
    {
        TitleIcon: IoWalletSharp,
        title: 'Finance Management',
        subtitle: 'Finance',
        roles: ['Super Admin', 'Office Admin'],
        sidebar: true
    },
    {
        TitleIcon: FaUsers,
        title: 'Users',
        subtitle: 'Users',
        roles: ['Super Admin'],
        sidebar: true
    },
    {
        TitleIcon: SlGraph,
        title: 'Results',
        subtitle: 'Results',
        roles: ['Super Admin', 'Office Admin'],
        sidebar: true
    },
    {
        TitleIcon: HiMegaphone,
        title: 'Events And Announcements',
        subTitle: 'Events',
        roles: ['Super Admin', 'Office Admin'],
        sidebar: true
    },
    {
        TitleIcon: SiGoogleclassroom,
        title: 'Classes',
        subtitle: 'Classes',
        roles: ['Super Admin', 'Office Admin', 'Teacher'],
        sidebar: true
    },
    {
        TitleIcon: MdOutlineEmojiTransportation,
        title: 'Transport',
        subtitle: 'Transport',
        roles: ['Super Admin', 'Transport Admin'],
        sidebar: true
    },
    {
        TitleIcon: MdSportsHandball,
        title: 'Extra Activities',
        subtitle: 'Extra Activities',
        roles: ['Super Admin', 'Sports Admin'],
        sidebar: true
    },
    {
        TitleIcon: GiFiles,
        title: 'Asset Management',
        subtitle: 'Assets',
        roles: ['Super Admin', 'Office Admin'],
        sidebar: true
    },
    {
        TitleIcon: GrDocumentTime,
        title: 'Attendence Management',
        subtitle: 'Attendence',
        roles: ['Super Admin', 'IT Admin'],
        sidebar: true
    },
    {
        TitleIcon: FaFingerprint,
        title: 'Identification',
        subtitile: 'ID',
        roles: ['Super Admin', 'IT Admin'],
        sidebar: true
    },
    {
        TitleIcon: HiAcademicCap,
        title: 'Academics',
        subtitle: 'Academics',
        roles: ['Office Admin', 'Teacher'],
        sidebar: true
    },
    {
        TitleIcon: FaCogs,
        title: 'Settings',
        subtitile: 'Settings',
        roles: ['Super Admin', 'Office Admin', 'Sports Admin', 'IT Admin', 'Lab In-Charge', 'Librarian', 'HR', 'Teacher', 'Maintanence Admin'],
        sidebar: true
    }
]