const TabButton = ({ children, index, value, setValue, id = '', onClick }) => {

    const handleClick = ({ }) => {
        if (typeof setValue === 'function') setValue(index)
        if (typeof onClick === 'function') onClick()
    }

    return (
        <button className={`mx-2 py-0.5 font-medium rounded transition-all duration-150 ${index === value ? 'bg-sColor text-white dark:bg-ldark px-4 text-sm' : 'text-black text-xs'} outline-none focus:outline-none`} onClick={handleClick} id={id}>
            {children}
        </button>
    )
}

export default TabButton