import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import API from '../../Common/API'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';
import { calculateAcademicYear } from '../../Common/academicYear';
import Button from '../../Components/Button';
import { FaRegSave } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { setAlert } from '../../Store/Theme/actions';


const months = [{ text: 'January', value: 1 }, { text: 'Feburaury', value: 2 }, { text: 'March', value: 3 }, { text: 'April', value: 4 }, { text: 'May', value: 5 },
{ text: 'June', value: 6 }, { text: 'July', value: 7 }, { text: 'August', value: 8 }, { text: 'September', value: 9 }, { text: 'October', value: 10 }, { text: 'November', value: 11 }, { text: 'December', value: 12 }]

const AvailHolidays = () => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const orgId = useSelector(state => state.auth.orgId)
    const academicYear = calculateAcademicYear()
    const [holidaysList, setHolidaysList] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [count, setCount] = useState(0)

    useEffect(() => {
        axios.all([API.get(`/common/getHolidaysList/${academicYear}`), API.get(`/common/getAvailedEvents/${academicYear}/${orgId}`)]).then((axios.spread((holiRes, availRes) => {
            setSelectedList(availRes.data.data)
            let monthMap = new Map()
            holiRes.data.data.map((c) => {
                if (monthMap.has(c.month)) {
                    const monthData = monthMap.get(c.month)
                    monthData.data.push(c)
                } else {
                    monthMap.set(c.month, {
                        month: c.month,
                        data: [c]
                    })
                }
            })
            setHolidaysList([...monthMap.values()].sort((a, b) => a.month - b.month))
            setLoading(false)
        })))
        console.log(holidaysList)
    }, [count])

    const getCurrentDay = (value) => {
        // Create a new Date object
        var currentDate = new Date(value);

        // Get the day of the week (0-6, where 0 is Sunday and 6 is Saturday)
        var dayOfWeek = currentDate.getDay();

        // An array to map the day of the week to its name
        var daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Get the name of the day using the array
        var dayName = daysOfWeek[dayOfWeek];

        // Output the result
        return dayName

    }

    console.log(holidaysList)

    const availedHolidaysList = (value) => {
        let details = {
            orgId: orgId,
            eventName: value.eventName,
            title: value.eventName,
            description: '',
            eventType: 'Holiday and Seasonal Events',
            start: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0] : value.eventDate,
            end: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0] : value.eventDate,
            startDate: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[2] : value.eventDate.split('-')[2],
            startMonth: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[1] : value.eventDate.split('-')[1],
            startYear: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[0] : value.eventDate.split('-')[0],
            endDate: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[2] : value.eventDate.split('-')[2],
            endMonth: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[1] : value.eventDate.split('-')[1],
            endYear: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[0] : value.eventDate.split('-')[0],
            academicYear: academicYear,
            eventDate: value.eventDate
        }
        if (selectedList.filter((x) => x.eventName === details.eventName && x.eventDate === details.eventDate).length > 0) {
            const updatedList = [...selectedList]
            const Index = updatedList.findIndex((x) => x.eventName === details.eventName && x.eventDate === details.eventDate)
            updatedList.splice(Index, 1)
            setSelectedList(updatedList)
        } else {
            setSelectedList([...selectedList, details])
        }
    }

    const submitHolidayList = () => {
        API.post(`/common/availHolidays/${academicYear}/${orgId}`, { eventList: selectedList }).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Holidays Availed Successfully', type: 'success', active: true }))
                setCount(count + 1)
            }
        }).catch((apiErr) => {
            console.log(apiErr)
        })
    }

    return (
        <>
            {
                loading ? "Loading please wait" : <motion.div className="relative flex flex-col text-xl">
                    <motion.div className="text-black ">
                        <p className="text-xl font-bold">Welcome</p> <p className="text-md">Avail your holidays by selecting the holidays from the list</p>
                    </motion.div>
                    <motion.div className="flex flex-row text-center justify-end px-10">
                        <motion.div className="px-2 bg-sColor text-white rounded-full my-1 py-2 cursor-pointer mx-1" onClick={() => submitHolidayList()}><FaRegSave /></motion.div>
                        <motion.div className='px-2 bg-[red] text-white rounded-full py-2 my-1 cursor-pointer mx-1' onClick={() => setCount(count + 1)}><AiFillCloseCircle /></motion.div>
                    </motion.div>
                    <motion.div className="relative h-full px-10  w-full flex flex-col">
                        {/* <table class="table-auto border border-sColor text-center border-collapse">
                            <thead>
                                <tr>
                                    <th className='border border-sColor px-5'>S.No</th>
                                    <th className='border border-sColor px-5'>Event Name</th>
                                    <th className='border border-sColor px-5'>Event Date</th>
                                    <th className='border border-sColor px-5'>Event Day</th>
                                    <th className='border border-sColor px-5'>Avail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    holidaysList.map((holiday, i) => {
                                        return <tr className="px-10" key={i}>
                                            <td className="border border-sColor px-10">{i + 1}</td>
                                            <td className='border border-sColor px-10'>{holiday.eventName}</td>
                                            <td className='border border-sColor px-10'>{holiday.eventDate.split('T').length > 0 ? holiday.eventDate.split('T')[0] : holiday.eventDate}</td>
                                            <td className='border border-sColor px-10'>{getCurrentDay(holiday.eventDate.split('T').length > 0 ? holiday.eventDate.split('T')[0] : holiday.eventDate)}</td>
                                            <td className='border border-sColor px-10'>
                                                <motion.div className={`${selectedList.some((x) => x.eventDate === (holiday.eventDate.split('T').length > 0 ? holiday.eventDate.split('T')[0] : holiday.eventDate)) ? 'activeChip' : 'pendingChip'} px-4 py-1 w-full text-xs rounded-full text-center text-white cursor-pointer font-bold`} onClick={() => availedHolidaysList(holiday, i)}>
                                                    {selectedList.some((x) => x.eventDate === (holiday.eventDate.split('T').length > 0 ? holiday.eventDate.split('T')[0] : holiday.eventDate)) ? 'Availed' : 'Avail'}
                                                </motion.div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table> */}
                        <motion.div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                            {
                                holidaysList.map((c) => {
                                    return <motion.div className="px-2 py-2 bg-slate-600 bg-opacity-20 mx-2 h-72 w-96 overflow-scroll">
                                        <motion.div className="flex flex-row justify-between text-black text-md font-bold">
                                            <p>{months.filter((x) => x.value === c.month)[0].text}</p>
                                            <p>{c.data.length}</p>
                                        </motion.div>
                                        <motion.div className="h-[0.5px] w-full bg-black my-1"></motion.div>
                                        <motion.div className="flex flex-col text-black text-md">
                                            {c.data.map((eve, i) => {
                                                return <motion.div className="flex my-1 px-2 flex-row">
                                                    <motion.div className="flex flex-row text-xs font-bold">
                                                        <motion.div className="flex flex-col justify-center">
                                                            {eve.date < 10 ? `0${eve.date}` : eve.date}
                                                            <motion.div>{getCurrentDay(eve.eventDate.split('T').length > 0 ? eve.eventDate.split('T')[0] : eve.eventDate)}</motion.div>
                                                        </motion.div>
                                                        <motion.div className='mx-5 flex w-52 flex-col justify-center'>{eve.eventName}</motion.div>
                                                    </motion.div>
                                                    <motion.div className={`${selectedList.some((x) => x.eventDate === (eve.eventDate.split('T').length > 0 ? eve.eventDate.split('T')[0] : eve.eventDate)) ? 'text-sColor' : 'text-black'} px-4 py-1 w-full text-xs underline rounded-full text-center text-black cursor-pointer`} onClick={() => availedHolidaysList(eve, i)}>
                                                        {selectedList.some((x) => x.eventDate === (eve.eventDate.split('T').length > 0 ? eve.eventDate.split('T')[0] : eve.eventDate)) ? 'Availed' : 'Avail'}
                                                    </motion.div>
                                                </motion.div>
                                            })}
                                        </motion.div>
                                    </motion.div>
                                })
                            }
                        </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row text-center justify-center">
                        <Button type="primary" onClick={() => submitHolidayList()}>Submit</Button>
                        <Button type="secondary" onClick={() => setCount(count + 1)}>Cancel</Button>
                    </motion.div>
                </motion.div>
            }
        </>
    )
}

export default AvailHolidays