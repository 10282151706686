import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import API from '../../Common/API'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { calculateAcademicYear } from '../../Common/academicYear';
import FieldInput from '../../Components/FieldInput';
import FieldSelect from '../../Components/FieldSelect';
import Button from '../../Components/Button';
import { setAlert } from '../../Store/Theme/actions';

const initialFormValues = {
    eventName: '',
    title: '',
    description: '',
    eventType: '',
    start: '',
    end: '',
    startTime: '',
    endTime: '',
    startDate: '',
    startMonth: '',
    startYear: '',
    endDate: '',
    endMonth: '',
    endYear: '',
    academicYear: '',
    eventDate: '',
}

const AddEvent = () => {

    const [loading, setLoading] = useState(true)
    const orgId = useSelector(state => state.auth.orgId)
    const academicYear = calculateAcademicYear()
    const [eventTypes, setEventTypes] = useState([])
    const [minimumDate, setMinmumDate] = useState()
    const [timeEnable, setTimeEnable] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        API.get('/menu/getEventTypes').then((evenTypeRes) => {
            setEventTypes(evenTypeRes.data.data.map((c) => ({ text: c.eventType, value: c.eventType })))
            setLoading(false)
        })
    }, [])

    Yup.addMethod(Yup.string, 'validName', function () {
        return this.test('Space is not allowed at the beginning', 'Space is not allowed at the beginning', (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    const eventFormShcema = Yup.object().shape({
        eventName: Yup.string().validName().required('Event name or Title is required'),
        title: Yup.string(),
        description: Yup.string().validName(),
        eventType: Yup.string(),
        start: Yup.string().required("From Date is required"),
        startTime: timeEnable ? Yup.string().required("Start Time is required") : Yup.string(),
        endTime: timeEnable ? Yup.string().required("End Time is required") : Yup.string(),
        end: Yup.string().required('To Date is required'),
        startDate: Yup.number(),
        startMonth: Yup.number(),
        startYear: Yup.number(),
        endDate: Yup.number(),
        endMonth: Yup.number(),
        endYear: Yup.number(),
        academicYear: Yup.string(),
        eventDate: Yup.string(),
    })

    const eventForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: eventFormShcema,
        onSubmit: (values) => {
            values.title = values.eventName
            values.academicYear = academicYear
            values.orgId = orgId
            values.start = values.start + 'T' + values.startTime + ':' + '00'
            values.end = values.end + 'T' + values.endTime + ':' + '00'
            values.startDate = parseInt(values.start.split('-')[2])
            values.startMonth = parseInt(values.start.split('-')[1])
            values.startYear = parseInt(values.start.split('-')[0])
            values.endDate = parseInt(values.end.split('-')[2])
            values.endMonth = parseInt(values.end.split('-')[1])
            values.endYear = parseInt(values.end.split('-')[0])
            API.post('/common/createEvents', values).then((eveRes) => {
                if (eveRes.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Event added successfully', type: 'success', active: true }))
                    eventForm.resetForm()
                    setMinmumDate('')
                    setTimeEnable(true)
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                }
            }).catch((apiErr) => {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            })
        }
    })

    const cancelEvents = () => {
        eventForm.resetForm()
        setMinmumDate('')
        setTimeEnable(true)
    }

    return (
        <motion.div className="relative flex flex-col h-full w-full">
            <motion.div className="grid grid-cols-1">
                <FieldInput label="Title / Event Name" placeholder={'Title / Event Name'} fieldHelper={eventForm.getFieldHelpers('eventName')} fieldProps={eventForm.getFieldProps('eventName')} fieldMeta={eventForm.getFieldMeta('eventName')} edit={true} dataLoaded={true} />
                <FieldInput label="Description" placeholder={'Optional'} fieldHelper={eventForm.getFieldHelpers('description')} fieldProps={eventForm.getFieldProps('description')} fieldMeta={eventForm.getFieldMeta('description')} edit={true} dataLoaded={true} />
                <FieldSelect label="Event Type" fieldHelper={eventForm.getFieldHelpers('eventType')} fieldProps={eventForm.getFieldProps('eventType')} fieldMeta={eventForm.getFieldMeta('eventType')} options={eventTypes} edit={true} dataLoaded={true} onClick={(ev) => ev === 'Holiday and Seasonal Events' ? setTimeEnable(false) : setTimeEnable(true)} />
                {eventForm.values.eventType !== 'Holiday and Seasonal Events' ? <motion.div className="grid grid-cols-2 sm:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1">
                    <FieldInput label={'From Date'} type="date" fieldHelper={eventForm.getFieldHelpers('start')} fieldProps={eventForm.getFieldProps('start')} fieldMeta={eventForm.getFieldMeta('start')} edit={true} dataLoaded={true} onChange={(ev) => setMinmumDate(ev)} />
                    <FieldInput label={'Event Start Time'} type="time" fieldHelper={eventForm.getFieldHelpers('startTime')} fieldProps={eventForm.getFieldProps('startTime')} fieldMeta={eventForm.getFieldMeta('startTime')} edit={true} dataLoaded={true} />
                    <FieldInput label={'To Date'} type="date" minDate={minimumDate} fieldHelper={eventForm.getFieldHelpers('end')} fieldProps={eventForm.getFieldProps('end')} fieldMeta={eventForm.getFieldMeta('end')} edit={true} dataLoaded={true} />
                    <FieldInput label={'Event End Time'} type="time" fieldHelper={eventForm.getFieldHelpers('endTime')} fieldProps={eventForm.getFieldProps('endTime')} fieldMeta={eventForm.getFieldMeta('endTime')} edit={true} dataLoaded={true} />
                </motion.div> : <motion.div className="grid grid-cols-2 sm:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1">
                    <FieldInput label={'From Date'} type="date" fieldHelper={eventForm.getFieldHelpers('start')} fieldProps={eventForm.getFieldProps('start')} fieldMeta={eventForm.getFieldMeta('start')} edit={true} dataLoaded={true} onChange={(ev) => setMinmumDate(ev)} />
                    <FieldInput label={'To Date'} type="date" minDate={minimumDate} fieldHelper={eventForm.getFieldHelpers('end')} fieldProps={eventForm.getFieldProps('end')} fieldMeta={eventForm.getFieldMeta('end')} edit={true} dataLoaded={true} />
                </motion.div>}
            </motion.div>
            <motion.div className="flex flex-row text-center justify-center">
                <Button type="primary" onClick={() => eventForm.submitForm()}>Submit</Button>
                <Button type="secondary" onClick={() => cancelEvents()}>Cancel</Button>
            </motion.div>
        </motion.div>
    )
}

export default AddEvent