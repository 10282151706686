import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Profile from './Profile'
import Organisation from './Organisation'
import ChangePassword from './ChangePassword'
import { useSelector } from 'react-redux'

const settingsMenuList = [{ link: 'profile', component: Profile }, { link: 'org', component: Organisation }, { link: 'security', component: ChangePassword }]

const Settings = () => {
    const userDetails = useSelector(state => state.auth.userDetails)
    const [tab, setTab] = useState(userDetails?.subject?.role !== 'Super Admin' ? 'profile' : 'org')


    return (
        <motion.div className="h-full w-full flex flex-col">
            <motion.div className="h-full w-full font-bold">
                Account Settings
            </motion.div>
            <motion.div className="bg-slate-100 bg-opacity-50  my-2 drop-shadow-md h-full w-full flex flex-row justify-start rounded-xl">
                <motion.div className="flex flex-col  px-2 w-32 h-screen border-r border-[#EED6D3]">
                    {userDetails.subject.role !== 'Super Admin' && <motion.div className={`hover:bg-sColor my-2 cursor-pointer px-2 rounded-full hover:text-pColor ${tab === 'profile' && 'bg-sColor text-pColor'}`} onClick={() => setTab('profile')}>
                        My Profile
                    </motion.div>}
                    <motion.div className={`hover:bg-sColor my-2 cursor-pointer px-2 rounded-full hover:text-pColor ${tab === 'org' && 'bg-sColor text-pColor'}`} onClick={() => setTab('org')}>
                        Org
                    </motion.div>
                    <motion.div className={`hover:bg-sColor my-2 cursor-pointer px-2 rounded-full hover:text-pColor ${tab === 'security' && 'bg-sColor text-pColor'}`} onClick={() => setTab('security')}>
                        Security
                    </motion.div>
                </motion.div>
                <motion.div className="h-full w-full">
                    {
                        settingsMenuList.map((c) => {
                            return <motion.div className="w-full">
                                {
                                    c.link === tab && <c.component />
                                }
                            </motion.div>
                        })
                    }
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default Settings