import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { IoChevronBackSharp } from "react-icons/io5";
import { FaTrashAlt, FaCheck } from "react-icons/fa";
import { useHistory } from 'react-router-dom'
import dummyPic from '../../Assets/Images/dummyProfile.webp'
import API from '../../Common/API'
import { setAlert } from '../../Store/Theme/actions';
import { useDispatch } from 'react-redux';

const UserView = ({ match }) => {

    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [userDetail, setUserDetail] = useState()
    const [reload, setReload] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        API.get(`/user/getUserDetails/${match.params.id}`).then((apiRes) => {
            setUserDetail(apiRes.data.data)
            setLoading(false)
        })
    }, [reload])

    const activateUser = () => {
        API.post('/user/activateUser', { id: match.params.id }).then((apiRes) => {
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'User Deactivated Successfully', type: 'success', active: true }))
                setReload(reload + 1)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                setReload(reload + 1)
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            setReload(reload + 1)
        })
    }

    const deactivateUser = () => {
        API.post('/user/deactivateUser', { id: match.params.id }).then((apiRes) => {
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'User Deactivated Successfully', type: 'success', active: true }))
                setReload(reload + 1)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                setReload(reload + 1)
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
            setReload(reload + 1)
        })
    }
    return (
        <>
            {
                loading ? "Loading please wait" : <motion.div className='relative min-h-screen w-full flex flex-col'>
                    <motion.div className={`w-full bg-slate-900 bg-opacity-10 h-12 rounded-full items-center flex flex-row justify-between`}>
                        <motion.div className="flex flex-row items-center">
                            <motion.div className={`m-2 px-1 py-1 bg-pColor rounded-full cursor-pointer`} onClick={() => history.goBack()}>
                                <IoChevronBackSharp className='text-2xl text-sColor font-bold' />
                            </motion.div>
                            <motion.div className='text-xl mx-2 font-bold'>
                                {userDetail.userId}
                            </motion.div>
                        </motion.div>
                        <motion.div className={'flex flex-row justify-evenly'}>
                            {userDetail.activeStat === 'D' && <motion.div className={`m-2 px-1 py-1 bg-sColor rounded-full cursor-pointer`} onClick={() => activateUser()}>
                                <FaCheck className='text-white p-1 text-2xl font-bold' title="Activate User" />
                            </motion.div>}
                            {userDetail.activeStat === 'A' && <motion.div className={`m-2 px-1 py-1 bg-red-500 rounded-full cursor-pointer`} onClick={() => deactivateUser()}>
                                <FaTrashAlt className='text-white p-1 text-2xl font-bold' title='Deactivate User' />
                            </motion.div>}
                        </motion.div>
                    </motion.div>
                    <motion.div className={`my-2 py-2 px-5 flex flex-row`}>
                        <img src={userDetail.profileImage === "" || userDetail.profileImage === null ? dummyPic : userDetail.profileImage} height={200} width={200} />
                        <motion.div className={`flex flex-col justify-evenly mx-2`}>
                            <p className='text-2xl text-black font-bold'>{userDetail.firstName} {userDetail.lastName}</p>
                            <p className='text-md text-black font-bold'>{userDetail.userId}</p>
                            <p className='text-md text-black font-bold'>{userDetail.mobile} | {userDetail.email}</p>
                            <p className='text-md text-black font-bold'>{userDetail.address.addressLine1},{userDetail.address.city}, {userDetail.address.district} (Dt) - {userDetail.address.pinCode}, {userDetail.address.state}</p>
                        </motion.div>
                    </motion.div>
                </motion.div >
            }
        </>
    )
}

export default UserView