import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import FieldInput from '../../Components/FieldInput'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import API from '../../Common/API'
import { FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../../Store/Theme/actions'
import Button from '../../Components/Button'

const initialInvoiceForm = {
    admissionId: '',
    firstName: '',
    lastName: '',
    address: '',
    class: '',
}

const AddInvoice = () => {

    const dispatch = useDispatch()
    const [studentDetails, setStudentDetails] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState([{ itemName: '', quantity: 0, cgst: 0, sgst: 0, price: 0 }])
    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = useSelector(state => state.auth.orgId)
    const [feesList, setFeesList] = useState([])
    const [activeIndex, setActiveIndex] = useState()
    const [feesMenuList, setFeesMenuList] = useState([])
    const [payingAmount, setPayingAmount] = useState(0)

    useEffect(() => {
        API.get(`/finance/getFeesList/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`).then((feesRes) => {
            setFeesList(feesRes.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const invoiceFormSchema = Yup.object().shape({
        admissionId: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
        class: Yup.string(),
    })

    const invoiceForm = useFormik({
        initialValues: initialInvoiceForm,
        validateOnMount: true,
        validationSchema: invoiceFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const invalidEntry = invoiceDetails.some((x)=>!x.quantity || !x.itemId)
            if(invalidEntry){
                dispatch(setAlert({ title: 'Warning', subtitle: 'Enter the Valid Details', type: 'warning', active: true }))
                return
            }
           try{
            values.userId = studentDetails.studentId._id;
            values.invoiceDetails = invoiceDetails
            values.payingAmount = parseInt(payingAmount)
            values.balanceAmount = getTotalAmount() - payingAmount
            values.totalAmount = getTotalAmount()
            values.orgId = orgId
            values.createdBy = userDetails.subject._id
            API.post('/finance/payStudentInvoice', values).then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Invoice Added Successfully', type: 'success', active: true }))
                    invoiceForm.resetForm()
                    setInvoiceDetails([{ itemName: '', quantity: 0, cgst: 0, sgst: 0, price: 0 }])
                    setPayingAmount(0)
                }
            }).catch((err) => {
                console.log(err)
            })
           }catch(err){
            if(!studentDetails){
                dispatch(setAlert({ title: 'Warning', subtitle: 'Student Details missing', type: 'warning', active: true }))
            }
           }

        }
    })

    const getStudentDetails = (ev) => {
        API.get(`/admission/getStudentDetailsByAdmissionId/${orgId}/${ev}`).then((detailsRes) => {
            if (detailsRes.status === 200) {
                setStudentDetails(detailsRes.data.data)
                let details = detailsRes.data.data
                invoiceForm.setValues({ ...invoiceForm.values, firstName: details.studentId.firstName, lastName: details.studentId.lastName, class: details.admittedOn })
            }else {
                dispatch(setAlert({ title: 'Warning', subtitle: 'Admission Record Not Found', type: 'warning', active: true }))
            }
        })
    }


    const removeItemDetails = (index) => {
        const updatedInvoiceDetails = [...invoiceDetails];
        if (updatedInvoiceDetails.length > 1) {
            updatedInvoiceDetails.splice(index, 1);
            setInvoiceDetails(updatedInvoiceDetails);
        } else {
            dispatch(setAlert({ title: 'Warning', subtitle: 'Invoice needed atleast one item to generate the invoice', type: 'warning', active: true }))
        }
    }

    const addInvoiceDetails = () => {
        const invalidEntry = invoiceDetails.some((x)=>!x.quantity || !x.itemId)
        if(invalidEntry){
            dispatch(setAlert({ title: 'Warning', subtitle: 'Enter the Valid Details', type: 'warning', active: true }))
            return
        }
        let values = { itemName: '',itemId:null, quantity: 0, cgst: 0, sgst: 0, price: 0 }
        setInvoiceDetails([...invoiceDetails, values])
    }

    const filterPackage = (values, i) => {
        setActiveIndex(i)
        let filterSplit = values.toLowerCase().trim();
        let result = feesList.filter(record => record["feesName"].toLowerCase().includes(filterSplit));
        if (feesList.length === result.length) {
            setFeesMenuList([])
        } else {
            setFeesMenuList(result)
        }
    }

    const addToBillTable = (values, index) => {
        const updatedInvoiceDetails = [...invoiceDetails];
        updatedInvoiceDetails[index].itemName = values.feesName
        updatedInvoiceDetails[index].itemId = values._id
        updatedInvoiceDetails[index].price = values.price
        updatedInvoiceDetails[index].cgst = values.cgst
        updatedInvoiceDetails[index].sgst = values.sgst
        setInvoiceDetails(updatedInvoiceDetails)
        setFeesMenuList([])
    }

    const getTotalAmount = () => {
        let total = 0
        invoiceDetails.map((c) => {
            total += c.price
        })
        return total
    }
    const handlepayingAmount = (ev) => {
        const value = parseFloat(ev.target.value) || 0; // Convert the input value to a float
        const maxAmount = getTotalAmount()
        if (value <= maxAmount) {
            setPayingAmount(value);
        } else {
            setPayingAmount(maxAmount);
        }
    };

    return (
        <motion.div className="relative h-full w-full flex flex-col">
            <motion.div className="bg-slate-300 bg-opacity-40 flex flex-col rounded-lg px-2 my-1 py-2">
                <motion.div className="text-sColor text-xl font-bold">
                    Student Details
                </motion.div>
                <motion.div className={`grid grid-cols-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4`}>
                    <FieldInput instruction={'Press Enter once you entered the Id'} label={'Admission No'} fieldProps={invoiceForm.getFieldProps('admissionId')} fieldHelper={invoiceForm.getFieldHelpers('admissionId')} fieldMeta={invoiceForm.getFieldMeta('admissionId')} edit={true} dataLoaded={true} onEnter={(ev) => getStudentDetails(ev)} />
                    <FieldInput label={'First Name'} fieldProps={invoiceForm.getFieldProps('firstName')} fieldHelper={invoiceForm.getFieldHelpers('firstName')} fieldMeta={invoiceForm.getFieldMeta('firstName')} edit={false} dataLoaded={true} />
                    <FieldInput label={'Last Name'} fieldProps={invoiceForm.getFieldProps('lastName')} fieldHelper={invoiceForm.getFieldHelpers('lastName')} fieldMeta={invoiceForm.getFieldMeta('lastName')} edit={false} dataLoaded={true} />
                    <FieldInput label={'Class'} fieldProps={invoiceForm.getFieldProps('class')} fieldHelper={invoiceForm.getFieldHelpers('class')} fieldMeta={invoiceForm.getFieldMeta('class')} edit={false} dataLoaded={true} />
                </motion.div>
            </motion.div>
            <motion.div className="bg-slate-300 h-screen bg-opacity-40 flex flex-col rounded-lg px-2 my-1 py-2">
                <motion.div className="text-sColor text-xl font-bold">
                    Invoice Details
                </motion.div>
                <motion.div className="flex flex-col">
                    <motion.div className="grid grid-cols-7 gap-2 my-1 text-center lg:grid-cols-7 xl:grid-cols-7">
                        <motion.div>S.No</motion.div>
                        <motion.div>Item</motion.div>
                        <motion.div className='text-right'>Quantity</motion.div>
                        <motion.div className='text-right'>CGST</motion.div>
                        <motion.div className='text-right'>SGST</motion.div>
                        <motion.div className='text-right'>Price</motion.div>
                        <motion.div className='text-center'>Action</motion.div>
                    </motion.div>
                    {
                        invoiceDetails.map((c, i) => {
                            return <motion.div key={i} className="grid grid-cols-7 my-1 gap-2 text-center lg:grid-cols-7 xl:grid-cols-7">
                                <motion.div>{i + 1}</motion.div>
                                <motion.div className='flex w-full flex-col'>
                                    <input type="text" className="relative h-7 px-2 w-full rounded-lg" value={c.itemName} onInput={(ev) => c.itemName = ev.target.value} onKeyUp={(ev) => filterPackage(ev.target.value, i)} />
                                    <motion.div className="absolute mt-8 w-52 bg-pColor max-h-36 h-auto overflow-y-auto" style={{ zIndex: 999 }}>
                                        {
                                            activeIndex === i && feesMenuList.map((c) => {
                                                return <motion.div className="flex flex-col cursor-pointer px-2 py-2 bg-pColor rounded-lg hover:bg-sColor hover:text-white" onClick={() => addToBillTable(c, i)}>
                                                    {c.feesName}
                                                </motion.div>
                                            })
                                        }
                                    </motion.div>
                                </motion.div>
                                <motion.div><input type="text" value={c.quantity} onInput={(ev) => c.quantity = ev.target.value} className="text-right relative h-7 px-2 w-full rounded-lg" /></motion.div>
                                <motion.div><input type="number" value={c.cgst} disabled={true} onInput={(ev) => c.cgst = ev.target.value} className="text-right relative h-7 px-2 w-full rounded-lg" /></motion.div>
                                <motion.div><input type="number" value={c.sgst} disabled={true} onInput={(ev) => c.sgst = ev.target.value} className="text-right relative h-7 px-2 w-full rounded-lg" /></motion.div>
                                <motion.div><input type="number" value={c.price} disabled={true} onInput={(ev) => c.price = ev.target.value} className="text-right relative h-7 px-2 w-full rounded-lg" /></motion.div>
                                <motion.div style={{ textAlign: '-webkit-center' }}> 
                                    <FaTrash className='text-center mx-2 cursor-pointer text-[red] my-1' onClick={() => removeItemDetails(i)} /></motion.div>
                            </motion.div>
                        })
                    }
                    <motion.div className="relative underline underline-offset-4 cursor-pointer my-2 px-5" onClick={() => addInvoiceDetails()}>
                        + Add More Items
                    </motion.div>
                </motion.div>
                <motion.div className="relative flex flex-col items-end">
                    <motion.div className="flex flex-row justify-around">
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            Total
                        </motion.div>
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            {getTotalAmount()}
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="relative flex flex-col items-end">
                    <motion.div className="flex flex-row justify-around">
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            Paying Amount
                        </motion.div>
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            <input type="number" className="px-2 text-right"  value={payingAmount} onChange={handlepayingAmount} onInput={(ev) => setPayingAmount(ev.target.value)} />
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="relative flex flex-col items-end">
                    <motion.div className="flex flex-row justify-around">
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            Balance Amount
                        </motion.div>
                        <motion.div className='text-xl mx-10 px-10 py-2'>
                            <input type="number" className='px-2 text-right' value={getTotalAmount() - payingAmount} disabled={true} />
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="relative flex flex-col items-end">
                    <motion.div className="flex flex-row justify-around">
                        <Button type="primary" onClick={() => invoiceForm.submitForm()}>Pay</Button>
                        <Button type="secondary">Cancel</Button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default AddInvoice