import React, { useEffect, useState, useMemo } from "react"
import { motion } from 'framer-motion'
import API from '../../Common/API'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux"
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"
import Dialog from '../../Components/Dialog'
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInput from "../../Components/FieldInput"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import InlineTextField from "../../Components/InLineTextField"
import { FaPlusCircle } from "react-icons/fa";


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialGroupForm = {
    groupName: ''
}


const GroupCreation = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [groupList, setGroupList] = useState([])
    const [subjectsList, setSubjectsList] = useState([])
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [subList, setSubList] = useState([])
    const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.all([API.get(`/class/getSubjectGroups/${orgId}`), API.get(`/common/subjects/${userDetails.subject.orgType}`)]).then((axios.spread((groupRes, subjectRes) => {
            setGroupList(groupRes.data.data)
            setSubjectsList(subjectRes.data.data.map((c) => ({ text: c.subjectName, value: c._id })))
            setLoading(false)
        })))
    }, [count])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Group Name', selector: (row, i) => row.groupName, initialActive: true, sortable: true },
            { name: 'Status', selector: row => row.activeStat === 'A' ? <motion.div className="activeChip">Active</motion.div> : <motion.div className="deactiveChip">Deactivated</motion.div>, sortable: true },
            // {
            //     name: 'Actions', selector: row => <motion.div className="flex flex-row justify-around">
            //         {(row.activeStat === 'P' || row.activeStat === 'D') && <FaCheck className="mx-2 text-[green] cursor-pointer" title="Activate" onClick={() => activePlan(row)} />}
            //         {(row.activeStat === 'P' || row.activeStat === 'A') && <FaTrash className="mx-2 text-[red] cursor-pointer" title="Deactivate" onClick={() => deactivePlan(row)} />}
            //     </motion.div>, omit: (userDetails.subject.role !== 'Super Admin')
            // }
        ]
    })

    const SubHeader = useMemo(() => (
        <div className="flex flex-row">
            <FaPlusCircle className="mx-2 my-2 text-sColor cursor-pointer" onClick={() => setOpenAddGroupDialog(true)} />
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return groupList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [groupList, filter])/// eslint-disable-line

    const groupFormSchema = Yup.object().shape({
        groupName: Yup.string().required("Group Name is required")
    })

    const groupForm = useFormik({
        initialValues: initialGroupForm,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: groupFormSchema,
        onSubmit: (values) => {
            values.orgId = orgId
            values.createdBy = userDetails.subject._id
            values.subjects = subList
            console.log(subList)
            if(subList.length===0){
                dispatch(setAlert({ title: 'Error', subtitle: 'Select atleast one subject', active: true, type: 'error' }))
                return
            }
            try {
                API.post('/class/createGroups', values).then((res) => {
                    if (res.status === 200) {
                        setOpenAddGroupDialog(false)
                        groupForm.resetForm()
                        setSubList([])
                        setCount(count + 1)
                    }
                }).catch((err) => {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    const cancel = () => {
        setOpenAddGroupDialog(false)
        groupForm.resetForm()
        setSubList([])
    }

    const selectSubject = (event, c, index) => {
        if (event.target.checked) {
            setSubList([...subList, c])
        } else {
            const updateRecord = [...subList].filter((x) => { return x !== c })
            setSubList(updateRecord)
        }
    }

    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 px-2 py-2 rounded-lg w-full h-full`}>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Group List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
            <Dialog title={'Create Group'} showDialog={openAddGroupDialog} onClose={() => setOpenAddGroupDialog(false)}>
                <motion.div className={'relative px-2 py-2 w-full flex flex-col'}>
                    <FieldInput label={'Group Name'} fieldProps={groupForm.getFieldProps('groupName')} fieldHelper={groupForm.getFieldHelpers('groupName')} fieldMeta={groupForm.getFieldHelpers('groupName')} edit={true} dataLoaded={true} />
                    {/* <FieldInput label={'Minimum Age'} fieldProps={classForm.getFieldProps('minAge')} fieldHelper={classForm.getFieldHelpers('minAge')} fieldMeta={classForm.getFieldHelpers('minAge')} edit={true} dataLoaded={true} /> */}
                    <motion.div className="relative h-48 overflow-y-scroll flex flex-col">
                        {
                            subjectsList.map((c, i) => {
                                return <motion.div className="relative flex flex-row justify-start">
                                    <input type="checkbox" className="mx-4" checked={subList.includes(c)} onChange={(ev) => selectSubject(ev, c, i)} />
                                    <p className="mx-4">{c.text}</p>
                                </motion.div>
                            })
                        }
                    </motion.div>
                    <motion.div className="flex flex-row justify-end">
                        <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                        <Button type="primary" onClick={() => groupForm.submitForm()}>Save</Button>
                    </motion.div>
                </motion.div>
            </Dialog>
        </motion.div>

    )
}

export default GroupCreation