
import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import intertactionPlugin from '@fullcalendar/interaction';
import API from '../../Common/API'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { calculateAcademicYear } from "../../Common/academicYear";
import Dialog from '../../Components/Dialog'
import AddEvent from "./AddEvent";

const Calendar = () => {

    const orgId = useSelector(state => state.auth.orgId)
    const academicYear = calculateAcademicYear()
    const [openEventDialog, setOpenEventDialog] = useState(false)
    const [openAddEventDialog, setOpenAddEventDialog] = useState(false)
    const [eventList, setEventList] = useState([])
    const [eventInfo, setEventInfo] = useState('')

    useEffect(() => {
        axios.all([API.get(`/common/getAvailedEvents/${academicYear}/${orgId}`), API.get(`/common/getHolidaysList/${academicYear}`)]).then((axios.spread(async (availedRes, holidaysRes) => {
            let availEvents = await availedRes.data.data.map((value) => {
                if (value.eventType === 'Holiday and Seasonal Events') {
                    value.backgroundColor = '#FF0000'
                    value.display = 'background'
                } else if (value.eventType === 'Sports Events') {
                    value.backgroundColor = '#4F6F52'
                    value.title = `🏃🏽🤸🏅 ${value.title} 🥇🏃🏻‍♀️🤾🏾‍♀️`
                }
                return value
            })
            let generalEvents = await holidaysRes.data.data.map((value) => {
                let details = {
                    orgId: orgId,
                    eventName: value.eventName,
                    title: value.eventName,
                    description: '',
                    eventType: 'events',
                    start: value.eventDate,
                    end: value.eventDate,
                    startDate: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[2] : value.eventDate.split('-')[2],
                    startMonth: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[1] : value.eventDate.split('-')[1],
                    startYear: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[0] : value.eventDate.split('-')[0],
                    endDate: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[2] : value.eventDate.split('-')[2],
                    endMonth: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[1] : value.eventDate.split('-')[1],
                    endYear: value.eventDate.split('T').length > 0 ? value.eventDate.split('T')[0].split('-')[0] : value.eventDate.split('-')[0],
                    academicYear: academicYear,
                    eventDate: value.eventDate,
                    backgroundColor: '#8e7cc3',
                    textColor: 'white'
                }
                return details
            })
            let separateGeneralList = generalEvents.filter((X) =>
                !availEvents.some((c) =>
                    X.eventName === c.eventName && X.eventDate === c.eventDate
                )
            );
            setEventList([...availEvents, ...separateGeneralList])
        })))
    }, [])


    const getCurrentDay = (value) => {
        // Create a new Date object
        var currentDate = new Date(value);

        // Get the day of the week (0-6, where 0 is Sunday and 6 is Saturday)
        var dayOfWeek = currentDate.getDay();

        // An array to map the day of the week to its name
        var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        // Get the name of the day using the array
        var dayName = daysOfWeek[dayOfWeek];

        // Output the result
        return dayName

    }

    const EventItem = ({ info }) => {
        const { event } = info;
        return (
            <motion.div className="text-md font-bold cursor-pointer" onClick={() => viewEvent(event)}>
                {info.timeText} {event.title}
            </motion.div>
        )
    }

    const viewEvent = (info) => {
        setOpenEventDialog(true)
        setEventInfo(info._def.extendedProps)
    }

    return (
        <motion.div className="relative h-full w-full flex flex-col">
            <motion.div className="flex flex-row w-full justify-end">
                <motion.div className="w-fit cursor-pointer bg-sColor px-5 rounded-full text-white py-1" onClick={() => setOpenAddEventDialog(true)}>
                    Add Events
                </motion.div>
            </motion.div>
            <motion.div className="py-4 px-1">
                <FullCalendar
                    eventContent={(info) => <EventItem info={info} />}
                    plugins={[dayGridPlugin]}
                    events={eventList}
                />
            </motion.div>
            <Dialog title={'Event Details'} showDialog={openEventDialog} onClose={() => setOpenEventDialog(false)}>
                <motion.div className="grid grid-cols-2 w-full sm:grid-cols-1 py-4 md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2">
                    {/* Title */}
                    <motion.div className="flex flex-col px-2 py-1">
                        <motion.div className="text-xl font-bold">
                            Title
                        </motion.div>
                        <motion.div className="text-md">
                            {eventInfo.eventName}
                        </motion.div>
                    </motion.div>
                    {/* Description */}
                    <motion.div className="flex flex-col px-2 py-1">
                        <motion.div className="text-xl font-bold">
                            Description
                        </motion.div>
                        <motion.div className="text-md">
                            {eventInfo.description !== null && eventInfo.description !== '' ? eventInfo.description : '-'}
                        </motion.div>
                    </motion.div>
                    {/* From Date */}
                    <motion.div className="flex flex-col px-2 py-1">
                        <motion.div className="text-xl font-bold">
                            From
                        </motion.div>
                        <motion.div className="text-md">
                            {eventInfo.startDate}-{eventInfo.startMonth < 10 ? '0' + eventInfo.startMonth : eventInfo.startMonth}-{eventInfo.startYear}
                        </motion.div>
                    </motion.div>
                    {/* To */}
                    <motion.div className="flex flex-col px-2 py-1">
                        <motion.div className="text-xl font-bold">
                            To
                        </motion.div>
                        <motion.div className="text-md">
                            {eventInfo.endDate}-{eventInfo.endMonth < 10 ? '0' + eventInfo.endMonth : eventInfo.endMonth}-{eventInfo.endYear}
                        </motion.div>
                    </motion.div>
                </motion.div>
            </Dialog>
            <Dialog title={'Add Event'} showDialog={openAddEventDialog} onClose={() => setOpenAddEventDialog(false)}>
                <AddEvent />
            </Dialog>
        </motion.div>
    )
}

export default Calendar