import React, { useEffect, useState, useMemo } from "react"
import { motion } from 'framer-motion'
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"
import API from '../../Common/API'
import { Link } from 'react-router-dom'
import InlineTextField from "../../Components/InLineTextField"
import { FaPlusCircle } from "react-icons/fa";
import FieldInput from "../../Components/FieldInput"
import Dialog from '../../Components/Dialog'
import * as Yup from 'yup'
import { useFormik } from "formik"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import { useDispatch, useSelector } from "react-redux"
import FieldSelect from '../../Components/FieldSelect'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialClassForm = {
    className: ''
}

const ClassList = () => {

    const [filter, setFilter] = useState('')
    const [classList, setClassList] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [openAddTypeDialog, setOpenAddTypeDialog] = useState(false)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [count, setCount] = useState(0)
    const [classMenuList, setClassMenuList] = useState([])
    const orgId = useSelector(state => state.auth.orgId)
    const [subjectsList, setSubjectsList] = useState([])
    const [subList, setSubList] = useState([])
    const [openAddClassDialog, setOpenAddClassDialog] = useState(false)


    useEffect(() => {
        API.get(`/class/classList/${orgId}`).then((apiRes) => {
            if (apiRes.status === 200) {
                setClassMenuList(apiRes.data.data.map((c) => ({ text: c.className, value: c.className })))
                setClassList(apiRes.data.data)
                setLoading(false)
            }
        })
        API.get(`/common/subjects/${userDetails.subject.orgType}`).then((res) => {
            if (res.status === 200) {
                setSubjectsList(res.data.data.map((c) => ({ text: c.subjectName, value: c._id })))
            }
        })
    }, [count])

    const classFormSchema = Yup.object().shape({
        className: Yup.string().required("Class Name is required"),
    })

    const classForm = useFormik({
        initialValues: initialClassForm,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: classFormSchema,
        onSubmit: (values) => {
            values.orgId = userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId
            values.createdBy = userDetails.subject._id
            values.subjects = subList
            try {
                // API.post('/class/createClass', values).then((typeRes) => {
                //     if (typeRes.status === 200) {
                //         setOpenAddTypeDialog(false)
                //         dispatch(setAlert({ title: 'Success', subtitle: "Class Type Added Successfully", active: true, type: "success" }))
                //         setCount(count + 1)
                //         classForm.resetForm()
                //     } else {
                //         dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                //     }
                // })
                API.post('/common/assignSubjects', values).then((res) => {
                    if (res.status === 200) {
                        setOpenAddTypeDialog(false)
                        classForm.resetForm()
                        setSubList([])
                    }
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    const classForm1 = useFormik({
        initialValues: initialClassForm,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: classFormSchema,
        onSubmit: (values) => {
            values.orgId = userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId
            values.createdBy = userDetails.subject._id
            values.subjects = subList
            try {
                API.post('/class/createClass', values).then((typeRes) => {
                    if (typeRes.status === 200) {
                        setOpenAddClassDialog(false)
                        dispatch(setAlert({ title: 'Success', subtitle: "Class Type Added Successfully", active: true, type: "success" }))
                        setCount(count + 1)
                        classForm1.resetForm()
                    } else {
                        dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                    }
                })
            } catch (err) {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    // <Link to={`/users/view/${row._id}`}>{i + 1}</Link>
    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Class Name', selector: (row, i) => row.className, initialActive: true, sortable: true },
            { name: 'Min Age', selector: (row, i) => row.minAge, initialActive: true, sortable: true },
            { name: 'No Of Sections', selector: row => row.noOfSection, grow: 1, sortable: true },
            { name: 'Status', selector: row => row.activeStat === 'A' ? <motion.div className="activeChip">Active</motion.div> : <motion.div className="deactiveChip">Deactivated</motion.div>, sortable: true },
            // {
            //     name: 'Actions', selector: row => <motion.div className="flex flex-row justify-around">
            //         {(row.activeStat === 'P' || row.activeStat === 'D') && <FaCheck className="mx-2 text-[green] cursor-pointer" title="Activate" onClick={() => activePlan(row)} />}
            //         {(row.activeStat === 'P' || row.activeStat === 'A') && <FaTrash className="mx-2 text-[red] cursor-pointer" title="Deactivate" onClick={() => deactivePlan(row)} />}
            //     </motion.div>, omit: (userDetails.subject.role !== 'Super Admin')
            // }
        ]
    })
    const SubHeader = useMemo(() => (
        <div className="flex flex-row">
            <motion.div className="w-auto items-center text-center h-7 text-pColor justify-center flex flex-col rounded-full px-4 cursor-pointer bg-sColor" onClick={() => setOpenAddClassDialog(true)}>Add class</motion.div>
            <FaPlusCircle className="mx-2 my-2 text-sColor cursor-pointer" onClick={() => setOpenAddTypeDialog(true)} />
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return classList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [classList, filter])/// eslint-disable-line

    const cancel = () => {
        setOpenAddTypeDialog(false)
        classForm.resetForm()
        setSubList([])
    }

    const selectSubject = (event, c, index) => {
        if (event.target.checked) {
            setSubList([...subList, c])
        } else {
            const updateRecord = [...subList].filter((x) => { return x !== c })
            setSubList(updateRecord)
        }
    }


    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 px-2 py-2 rounded-lg w-full h-full`}>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Class List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
            <Dialog title={'Add Subjects'} showDialog={openAddTypeDialog} onClose={() => setOpenAddTypeDialog(false)}>
                <motion.div className={'relative px-2 py-2 w-full flex flex-col'}>
                    <FieldSelect label={'Class Name'} fieldProps={classForm.getFieldProps('className')} fieldHelper={classForm.getFieldHelpers('className')} fieldMeta={classForm.getFieldHelpers('className')} edit={true} dataLoaded={true} options={classMenuList} />
                    {/* <FieldInput label={'Minimum Age'} fieldProps={classForm.getFieldProps('minAge')} fieldHelper={classForm.getFieldHelpers('minAge')} fieldMeta={classForm.getFieldHelpers('minAge')} edit={true} dataLoaded={true} /> */}
                    <motion.div className="relative h-48 overflow-y-scroll flex flex-col">
                        {
                            subjectsList.map((c, i) => {
                                return <motion.div className="relative flex flex-row justify-start">
                                    <input type="checkbox" className="mx-4" checked={subList.includes(c)} onChange={(ev) => selectSubject(ev, c, i)} />
                                    <p className="mx-4">{c.text}</p>
                                </motion.div>
                            })
                        }
                    </motion.div>
                    <motion.div className="flex flex-row justify-end">
                        <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                        <Button type="primary" onClick={() => classForm.submitForm()}>Save</Button>
                    </motion.div>
                </motion.div>
            </Dialog>
            <Dialog title={'Create Class'} showDialog={openAddClassDialog} onClose={() => setOpenAddClassDialog(false)}>
                <motion.div className={'relative px-2 py-2 w-full flex flex-col'}>
                    <FieldInput label={'Class Name'} fieldProps={classForm1.getFieldProps('className')} fieldHelper={classForm1.getFieldHelpers('className')} fieldMeta={classForm1.getFieldHelpers('className')} edit={true} dataLoaded={true} />
                    <FieldInput label={'Minimum Age'} fieldProps={classForm1.getFieldProps('minAge')} fieldHelper={classForm1.getFieldHelpers('minAge')} fieldMeta={classForm1.getFieldHelpers('minAge')} edit={true} dataLoaded={true} />
                    {/* <motion.div className="relative h-48 overflow-y-scroll flex flex-col">
                        {
                            subjectsList.map((c, i) => {
                                return <motion.div className="relative flex flex-row justify-start">
                                    <input type="checkbox" className="mx-4" checked={subList.includes(c)} onChange={(ev) => selectSubject(ev, c, i)} />
                                    <p className="mx-4">{c.text}</p>
                                </motion.div>
                            })
                        }
                    </motion.div> */}
                    <motion.div className="flex flex-row justify-end">
                        <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                        <Button type="primary" onClick={() => classForm1.submitForm()}>Save</Button>
                    </motion.div>
                </motion.div>
            </Dialog>
        </motion.div>
    )
}

export default ClassList