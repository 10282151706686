import React from "react"
import { motion } from 'framer-motion'

const Button = ({ injectClass, type, children, onClick, disabled = false }) => {

    const trigger = () => {
        if (typeof onClick === 'function') onClick()
    }

    return (
        <motion.div className="relative px-5 py-2" onClick={trigger}>
            <motion.div aria-disabled={disabled} className={`${injectClass} px-4 py-2 ring-1 rounded-full w-auto text-center ${type === "primary" ? 'bg-sColor ring-sColor font-bold hover:rintg-2 hover:ring-sColor-900 text-md cursor-pointer text-white' : type === "secondary" ? 'bg-white ring-sColo font-bold text-md hover:ring-2 hover:ring-pColor-900 cursor-pointer text-tColor' : type === "danger" ? "bg-[#BE3144] ring-[#BE3144] hover:ring-2 hover:ring-[red] font-bold text-md text-white cursor-pointer" : ''}`}>
                {children}
            </motion.div>
        </motion.div>
    )
}

export default Button