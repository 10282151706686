import React, { useEffect, useLayoutEffect, useState } from "react"
import { motion } from 'framer-motion'
import * as Yup from 'yup'
import { useFormik } from "formik"
import API from "../../Common/API"
import { useSelector, useDispatch } from "react-redux"
import { calculateAcademicYear } from "../../Common/academicYear"
import { setAlert } from "../../Store/Theme/actions"
import { MdClass } from "react-icons/md"
import Dialog from "../../Components/Dialog"
import FieldSelect from "../../Components/FieldSelect"
import FieldInput from "../../Components/FieldInput"
import { useMemo } from "react"
import { FaPlusCircle } from "react-icons/fa"
import InlineTextField from "../../Components/InLineTextField"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"
import DatatableLoader from "../../Components/DatatableLoader"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialHomeWorkSchema = {
    fullDate: "",
    date: 0,
    month: 0,
    year: 0,
    unitId: '',
    unitName: '',
    topicName: '',
    subjectId: '',
    classId: '',
    homework: '',
    assingedBy: '',
    dueFullDate: '',
    dueDate: 0,
    dueMonth: 0,
    dueYear: 0,
    topicId: ""
}

const AddHomeWork = () => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const orgId = useSelector(state => state.auth.orgId)
    const [classList, setClassList] = useState([])
    const academicYear = calculateAcademicYear()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [openHomeWorkDialogBox, setOpenHomeWorkDialogBox] = useState(false)
    const [syllabusList, setSyllabusList] = useState([])
    const [fullSyllabus, setFullSyllabus] = useState([])
    const [subTopics, setSubTopics] = useState([])
    const [classId, setClassId] = useState('')
    const [subTopicEnable, setSubTopicEnable] = useState(true)
    const [subjectId, setSubjectId] = useState('')
    const [homeWorkList, setHomeWorkList] = useState([])
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [editForm, setEditForm] = useState(false)
    const [classFullList, setClassFullList] = useState([])

    useEffect(() => {
        API.get(`/common/getMyAllotedClass/${academicYear}/${userDetails.subject._id}`).then((classRes) => {
            if (classRes.status === 200) {
                setClassFullList(classRes.data.data.map((c) => ({ text: c.class.className, value: c.class._id })))
                setClassList(classRes.data.data.map((c) => ({ text: `${c.class.className} - ${c.subject}`, value: `${c.class._id}-${c.subjectId}` })))
                setLoading(false)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        })
    }, [])

    useLayoutEffect(() => {
        try {
            API.get(`/class/getHomeWorkByStaff/${orgId}/${academicYear}/${userDetails.subject._id}`).then((res) => {
                if (res.status === 200) {
                    setHomeWorkList(res.data.data)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [count])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Class', selector: row => { return classFullList.filter((c) => c.value === row.classId)[0].text }, initialActive: true, sortable: true },
            { name: 'Unit Name', selector: row => row.unitName, initialActive: true, sortable: true },
            { name: 'Topic Name', selector: row => row.topicName, initialActive: true, sortable: true },
            { name: 'Home Work', selector: row => row.homework, initialActive: true, sortable: true },
            { name: 'Due Date', selector: row => row.dueFullDate, initialActive: true, sortable: true },
            {
                name: 'Actions', selector: row => <motion.div className={`cursor-pointer flex flex-col bg-sColor text-white px-2 py-1 rounded-full w-12 text-center hover:bg-pColor hover:text-black`} onClick={() => openEditHomeWork(row)}>Edit</motion.div>
            }
        ]
    })

    const SubHeader = useMemo(() => (
        <div className="flex flex-row">
            <FaPlusCircle className="mx-2 my-2 text-sColor cursor-pointer" onClick={() => setOpenHomeWorkDialogBox(true)} />
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return homeWorkList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [homeWorkList, filter])/// eslint-disable-line

    const homeWorkFormshcema = Yup.object().shape({
        fullDate: Yup.string().required("Created Date is required"),
        date: Yup.number().nullable(),
        month: Yup.number().nullable(),
        year: Yup.number().nullable(),
        unitId: Yup.string().required("Unit Name is required"),
        unitName: Yup.string().nullable(),
        topicId: Yup.string().required("Topic Name is required"),
        topicName: Yup.string().nullable(),
        subjectId: Yup.string().nullable(),
        classId: Yup.string().nullable(),
        homework: Yup.string().required("Home work is required"),
        assingedBy: Yup.string().nullable(),
        dueFullDate: Yup.string().required("Due Date is required"),
        dueDate: Yup.number().nullable(),
        dueMonth: Yup.number().nullable(),
        dueYear: Yup.number().nullable()
    })

    const homeWorkForm = useFormik({
        initialValues: initialHomeWorkSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: homeWorkFormshcema,
        onSubmit: (values) => {
            values.assingedBy = userDetails.subject._id
            values.classId = classId
            values.date = parseInt(values.fullDate.split('-')[2])
            values.month = parseInt(values.fullDate.split('-')[1])
            values.year = parseInt(values.fullDate.split('-')[0])
            values.dueDate = parseInt(values.dueFullDate.split('-')[2])
            values.dueMonth = parseInt(values.dueFullDate.split('-')[1])
            values.dueYear = parseInt(values.dueFullDate.split('-')[0])
            values.subjectId = subjectId
            values.academicYear = academicYear
            values.topicName = subTopics.filter((x) => x.value === values.topicId)[0].text
            values.unitName = syllabusList.filter((x) => x.value === values.unitId)[0].text
            values.orgId = orgId
            API.post('/class/addHomeWorks', values).then((res) => {
                if (res.status == 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Home Work added successfully', type: 'success', active: true }))
                    homeWorkForm.resetForm()
                    setOpenHomeWorkDialogBox(false)
                    setSubTopics([])
                    setClassId('')
                    setSubjectId('')
                    setSubTopicEnable(true)
                    setCount(count + 1)
                } else {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                }
            })
        }
    })


    const openHomeWorkDialog = (values) => {
        let classId = values.split('-')[0]
        let subjectId = values.split('-')[1]
        setClassId(values.split('-')[0])
        setSubjectId(values.split('-')[1])
        API.get(`/class/getSyllabus/${orgId}/${classId}/${subjectId}/${academicYear}`).then((res) => {
            if (res.status === 200) {
                setFullSyllabus(res.data.data)
                setSyllabusList(res.data.data.filter((x) => x.portionEnable).map((c) => ({ text: c.topicName, value: c._id })))
                setLoading(false)
            }
        })
        setOpenHomeWorkDialogBox(true)
    }

    const loadSubTopics = (value) => {
        setSubTopics(fullSyllabus.filter((x) => x._id === value)[0].subTopics.map((c) => ({ text: c.topicName, value: c._id })))
        setSubTopicEnable(false)
    }

    const cancelHomeWork = () => {
        homeWorkForm.resetForm()
        setOpenHomeWorkDialogBox(false)
        setSubTopics([])
        setClassId('')
        setSubjectId('')
        setSubTopicEnable(true)
    }

    const openEditHomeWork = (value) => {
        setClassId(value.classId)
        setSubjectId(value.subjectId)
        setEditForm(true)
        API.get(`/class/getSyllabus/${orgId}/${value.classId}/${value.subjectId}/${academicYear}`).then((res) => {
            if (res.status === 200) {
                setFullSyllabus(res.data.data)
                setSyllabusList(res.data.data.filter((x) => x.portionEnable).map((c) => ({ text: c.topicName, value: c._id })))
                setLoading(false)
                setSubTopics(res.data.data.filter((x) => x._id === value.unitId)[0].subTopics.map((c) => ({ text: c.topicName, value: c._id })))
                setSubTopicEnable(false)
                setOpenHomeWorkDialogBox(true)
            }
        })
        homeWorkForm.setValues(value)
    }

    const editHomeWork = () => {
        API.post('/class/editHomework', homeWorkForm.values).then((editRes) => {
            if (editRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Home Work edited successfully', type: 'success', active: true }))
                homeWorkForm.resetForm()
                setOpenHomeWorkDialogBox(false)
                setSubTopics([])
                setClassId('')
                setSubjectId('')
                setSubTopicEnable(true)
                setCount(count + 1)
                setEditForm(false)
            }
        })
    }

    return (
        <>
            {
                loading ? <DatatableLoader /> : <motion.div className="relative h-full w-full flex flex-col">
                    <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                        <DataTable title="Class List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                    </motion.div>
                    {/* <motion.div className="relative text-xl my-2 font-bold">
                        Select subject to add the homeworks
                    </motion.div> */}
                    {/* <motion.div className="grid px-5 grid-cols-4 my-2 gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4">
                        {classList.length > 0 ? classList.map((c) => {
                            return <motion.div className="flex cursor-pointer flex-col bg-sColor bg-opacity-60 rounded-xl w-44 items-center h-44 relative justify-center text-center px-4 py-2" onClick={() => openHomeWorkDialog(c)}>
                                <MdClass className="text-3xl text-white opacity-80" />
                                <motion.div className="text-md font-bold text-white">{c.class.className}</motion.div>
                                <motion.div className="text-md font-bold text-white">{c.subject}</motion.div>
                            </motion.div>
                        }) : ''}
                    </motion.div> */}
                    <Dialog title={`${editForm ? 'Edit Homework' : 'Add HomeWork'}`} showDialog={openHomeWorkDialogBox} onClose={() => cancelHomeWork()}>
                        <motion.div className="w-full h-full flex flex-col">
                            {!editForm && <FieldSelect label={'Class'} fieldProps={homeWorkForm.getFieldProps('classId')} fieldMeta={homeWorkForm.getFieldMeta('classId')} fieldHelper={homeWorkForm.getFieldHelpers('classId')} edit={true} dataLoaded={true} options={classList} onChange={(value) => openHomeWorkDialog(value)} />}
                            <FieldSelect label={'Unit'} fieldProps={homeWorkForm.getFieldProps('unitId')} fieldMeta={homeWorkForm.getFieldMeta('unitId')} fieldHelper={homeWorkForm.getFieldHelpers('unitId')} edit={true} dataLoaded={true} options={syllabusList} onChange={(value) => loadSubTopics(value)} />
                            <FieldSelect disabled={subTopicEnable} label={'Topics'} fieldProps={homeWorkForm.getFieldProps('topicId')} fieldMeta={homeWorkForm.getFieldMeta('topicId')} fieldHelper={homeWorkForm.getFieldHelpers('topicId')} edit={true} dataLoaded={true} options={subTopics} />
                            <motion.div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                                <FieldInput type="date" label={'Created Date'} fieldProps={homeWorkForm.getFieldProps('fullDate')} fieldMeta={homeWorkForm.getFieldMeta('fullDate')} fieldHelper={homeWorkForm.getFieldHelpers('fullDate')} edit={true} dataLoaded={true} options={subTopics} />
                                <FieldInput type="date" label={'Due Date'} fieldProps={homeWorkForm.getFieldProps('dueFullDate')} fieldMeta={homeWorkForm.getFieldMeta('dueFullDate')} fieldHelper={homeWorkForm.getFieldHelpers('dueFullDate')} edit={true} dataLoaded={true} options={subTopics} />
                            </motion.div>
                            <FieldInput type="textarea" label={'Home Work'} fieldProps={homeWorkForm.getFieldProps('homework')} fieldMeta={homeWorkForm.getFieldMeta('homework')} fieldHelper={homeWorkForm.getFieldHelpers('homework')} edit={true} dataLoaded={true} />
                            <motion.div className="flex flex-row justify-between my-2">
                                <motion.div className="bg-[#C51605] text-white rounded-full cursor-pointer px-5 py-1 flex flex-col justify-center text-center items-center" onClick={() => cancelHomeWork()}>
                                    Cancel
                                </motion.div>
                                <motion.div className="bg-sColor text-white cursor-pointer px-5 py-1 rounded-full flex flex-col justify-center text-center items-center" onClick={() => editForm ? editHomeWork() : homeWorkForm.submitForm()}>
                                    {editForm ? 'Edit homework' : 'Add homework'}
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </Dialog>
                </motion.div>
            }
        </>
    )
}

export default AddHomeWork