import React, { useEffect, useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import TabButtonGroup from '../../Components/Tabs/TabButtonGroup'
import Tab from '../../Components/Tabs/Tab'
import TabGroup from '../../Components/Tabs/TabGroup'
import TabButton from '../../Components/Tabs/TabButton'
import DatatableLoader from "../../Components/DatatableLoader"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../Common/dataTableTheme'


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const ClassStudents = ({ history }) => {

    const [tab, setTab] = useState(0)
    const [displayRecords, setDisplayRecords] = useState([])
    const [studentsList, setStudentsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (history.location.state && history.location.state !== undefined && history.location.state.details !== undefined) {
            setDisplayRecords(history.location.state.details.sort((a, b) => a.sectionName.localeCompare(b.sectionName))[0])
            setStudentsList(history.location.state.details.sort((a, b) => a.sectionName.localeCompare(b.sectionName)))
            setLoading(false)
        }
    }, [])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            { name: 'Roll Number', selector: (row, i) => displayRecords.admissionId[i].rollNumber, sortable: true },
            {
                name: 'Student Name', cell: row => row.firstName, sortable: true
            }
        ]
    })


    return (
        <>
            {
                loading ? "Loading please wait ..." : <motion.div className="w-full h-full flex flex-col">
                    <motion.div className="text-center my-5">
                        <motion.div className="font-bold text-xl">
                            {displayRecords.className}
                        </motion.div>
                        <TabButtonGroup>
                            {
                                studentsList.map((c, i) => {
                                    return <TabButton index={i} value={tab} setValue={setTab} id={c.sectionName} onClick={() => setDisplayRecords(c)}>
                                        Section {c.sectionName}
                                    </TabButton>
                                })
                            }
                        </TabButtonGroup>
                    </motion.div>
                    <TabGroup value={tab}>
                        {
                            studentsList.map((c, i) => {
                                return <Tab index={i} value={tab}>
                                    <motion.div className="relative bg-slate-200 bg-opacity-20 rounded-xl  py-5 h-full px-10  w-full flex flex-col">
                                        {/* <table className="table-auto border border-sColor border-collapse">
                                            <thead>
                                                <tr>
                                                    <th className='border border-sColor px-5'>Roll number</th>
                                                    <th className='border border-sColor px-5'>Student Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    displayRecords.students.length > 0 && displayRecords.admissionId.length > 0 && displayRecords.students.map((stu, i) => {
                                                        return <tr className="px-10" key={i}>
                                                            <td className="border border-sColor px-10">{displayRecords.admissionId[i].rollNumber}</td>
                                                            <td className="border border-sColor px-10">{stu.firstName}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table> */}
                                        <DataTable title="User List" theme="defaultLight" columns={columns} data={displayRecords.students} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                                    </motion.div>
                                </Tab>
                            })
                        }
                    </TabGroup>
                </motion.div>
            }
        </>
    )
}

export default ClassStudents