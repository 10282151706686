import React, { useState } from "react"
import { motion } from 'framer-motion'
import loginPic from '../Assets/Images/loginPic.svg'
import FieldInput from "../Components/FieldInput"
import * as yup from 'yup'
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import API from "../Common/API"
import { setAlert } from "../Store/Theme/actions"
import { useHistory } from 'react-router-dom'
import { setToken, setUserDetails, setLoggedIn, setOrgId } from "../Store/Auth/actions"
import Dialog from '../Components/Dialog'
import Button from '../Components/Button'

const initialFormValues = {
    userName: "",
    password: "",
}

const initialForgotPasswordForm = {
    userName: ''
}


const Login = () => {


    const dispatch = useDispatch()
    const history = useHistory()
    const [openForgotDialog, setOpenForgotDialog] = useState(false)

    const loginFormSchema = yup.object().shape({
        userName: yup.string().required("User Name is required"),
        password: yup.string().required("Password is required")
    })

    const loginForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: loginFormSchema,
        onSubmit: (values) => {
            try {
                API.post('/auth/login', values).then(async (apiRes) => {
                    if (apiRes.status === 200) {
                        dispatch(setAlert({ title: "Success", subtitle: 'Logged in successfully', active: true, type: 'success' }))
                        dispatch(setToken(apiRes.data.data))
                        let userDetails = await JSON.parse(window.atob(apiRes.data.data.split(".")[1]));
                        dispatch(setOrgId(userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId))
                        localStorage.setItem('token', apiRes.data.data)
                        dispatch(setUserDetails(userDetails))
                        dispatch(setLoggedIn(true))
                        history.push('/')
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    })

    const forgotFormShcmea = yup.object().shape({
        userName: yup.string().required("User Name is required")
    })

    const forgotForm = useFormik({
        initialValues: initialForgotPasswordForm,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: forgotFormShcmea,
        onSubmit: (values) => {
            API.post('/auth/forgotPassword', values).then((res) => {
                console.log(res)
            })
        }
    })

    return (
        <motion.div className="bg-white min-h-screen w-full relative flex flex-col items-center align-center justify-center text-center">
            <motion.div className="w-auto flex flex-row justify-center">
                <motion.div>
                    <img src={loginPic} className="h-96 w-96 mx-2" />
                </motion.div>
                <motion.div className="flex flex-col w-96 mx-2">
                    <motion.div className="text-2xl font-bold text-[#6C63FF]">
                        Log In
                    </motion.div>
                    <motion.div className="grid grid-cols-1">
                        <FieldInput labelInjectClass={'bg-white ring-1 ring-[grey]'} label={'User Name'} type={'text'} fieldHelper={loginForm.getFieldHelpers('userName')} fieldProps={loginForm.getFieldProps('userName')} fieldMeta={loginForm.getFieldMeta('userName')} edit={true} dataLoaded={true} />
                        <FieldInput labelInjectClass={'bg-white ring-1 ring-[grey]'} label={'Password'} type={'password'} fieldHelper={loginForm.getFieldHelpers('password')} fieldProps={loginForm.getFieldProps('password')} fieldMeta={loginForm.getFieldMeta('password')} edit={true} dataLoaded={true} />
                        <motion.div className="text-end text-[#6C63FF] cursor-pointer px-5 underline underline-offset-4" onClick={() => setOpenForgotDialog(true)}>
                            Forgot password ?
                        </motion.div>
                    </motion.div>
                    <motion.div className="ring-1 mt-5 mb-5 top-0 left-0 bottom-0 right-0 w-24 mx-5 cursor-pointer shadow-xl hover:bg-[#6C63FF] hover:text-white rounded-full" onClick={() => loginForm.submitForm()}>
                        Login
                    </motion.div>
                </motion.div>
            </motion.div>
            <Dialog title="Forgot Password" showDialog={openForgotDialog} onClose={() => setOpenForgotDialog(false)}>
                <motion.div className="flex flex-col w-full my-2">
                    <FieldInput labelInjectClass={'bg-white ring-1 ring-[grey]'} label={'User Name'} type={'text'} fieldHelper={forgotForm.getFieldHelpers('userName')} fieldProps={forgotForm.getFieldProps('userName')} fieldMeta={forgotForm.getFieldMeta('userName')} edit={true} dataLoaded={true} />
                    <Button type='primary' onClick={() => forgotForm.submitForm()}>Next</Button>
                </motion.div>
            </Dialog>
        </motion.div>
    )
}

export default Login