import React, { useEffect, useState, useMemo } from "react"
import { motion } from 'framer-motion'
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"
import API from '../../Common/API'
import { Link } from 'react-router-dom'
import InlineTextField from "../../Components/InLineTextField"
import { FaCheck, FaPlusCircle, FaTrash } from "react-icons/fa";
import FieldInput from "../../Components/FieldInput"
import Dialog from '../../Components/Dialog'
import * as Yup from 'yup'
import { useFormik } from "formik"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import { useDispatch, useSelector } from "react-redux"
import { createPortal } from "react-dom"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialFeesForm = {
    feesName: '',
    orgId: '',
    createdBy: '',
    price: 0,
    cgst: 0,
    sgst: 0
}

const Fees = () => {

    const [filter, setFilter] = useState('')
    const [feesList, setFeesList] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [openAddFeesDialog, setOpenAddFeesDialog] = useState(false)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [disableSave, setDisableSave] = useState(false)
    const [count, setCount] = useState(0)

    useEffect(() => {
        API.get(`/finance/feesList/${userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId}`).then((apiRes) => {
            if (apiRes.status === 200) {
                setFeesList(apiRes.data.data)
                setLoading(false)
            }
        })
    }, [count])

    const fessTypeSchema = Yup.object().shape({
        feesName: Yup.string().required("Fees Name is required"),
        price: Yup.number().required("Price is required"),
        sgst: Yup.number().required("Sgst is required"),
        cgst: Yup.number().required("Cgst is required")
    })

    const feesForm = useFormik({
        initialValues: initialFeesForm,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: fessTypeSchema,
        onSubmit: (values) => {
            setDisableSave(true)
            values.orgId = userDetails.subject.profileType === 'org' ? userDetails.subject._id : userDetails.subject.orgId
            values.createdBy = userDetails.subject._id
            try {
                API.post('/finance/addFees', values).then((typeRes) => {
                    if (typeRes.status === 200) {
                        setOpenAddFeesDialog(false)
                        feesForm.resetForm()
                        dispatch(setAlert({ title: 'Success', subtitle: "Class Type Added Successfully", active: true, type: "success" }))
                        setDisableSave(false)
                        setCount(count + 1)
                    } else {
                        dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
                        setDisableSave(false)
                    }
                })
            } catch (err) {
                setDisableSave(false)
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }
    })

    // <Link to={`/users/view/${row._id}`}>{i + 1}</Link>
    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Fees Name', selector: row => row.feesName, initialActive: true, sortable: true },
            { name: 'Price', selector: row => row.price, grow: 1, sortable: true },
            { name: 'SGST', selector: row => row.sgst, grow: 1, sortable: true },
            { name: 'CGST', selector: row => row.cgst, grow: 1, sortable: true },
            { name: 'Status', selector: row => row.activeStat === 'P' ? <motion.div className="pendingChip">Pending</motion.div> : row.activeStat === 'A' ? <motion.div className="activeChip">Active</motion.div> : <motion.div className="deactiveChip">Deactivated</motion.div>, sortable: true },
            {
                name: 'Actions', selector: row => <motion.div className="flex flex-row justify-around">
                    {(row.activeStat === 'P' || row.activeStat === 'D') && <FaCheck className="mx-2 text-[green] cursor-pointer" title="Activate" onClick={() => activePlan(row)} />}
                    {(row.activeStat === 'P' || row.activeStat === 'A') && <FaTrash className="mx-2 text-[red] cursor-pointer" title="Deactivate" onClick={() => deactivePlan(row)} />}
                </motion.div>, omit: (userDetails.subject.role !== 'Super Admin')
            }
        ]
    })
    const SubHeader = useMemo(() => (
        <div className="flex flex-row">
            <FaPlusCircle className="mx-2 my-2 text-sColor cursor-pointer" onClick={() => setOpenAddFeesDialog(true)} />
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return feesList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [feesList, filter])/// eslint-disable-line

    const cancel = () => {
        setOpenAddFeesDialog(false)
        feesForm.resetForm()
    }

    const activePlan = (details) => {
        API.post('/finance/activeFeeStructure', details).then((apiRes) => {
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Fees Structure activated successfully', active: true, type: 'success' }))
                setCount(count + 1)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
        })
    }

    const deactivePlan = (details) => {
        API.post('/finance/deactiveFeeStructure', details).then((apiRes) => {
            if (apiRes.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Fees Structure deactivated successfully', active: true, type: 'success' }))
                setCount(count + 1)
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
            }
        }).catch((err) => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
        })
    }

    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 px-2 py-2 rounded-lg w-full h-full`}>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Class List" theme="defaultLight" columns={columns} data={filteredcasedetails} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
            {
                createPortal(<Dialog title={'Create Fee Structure'} showDialog={openAddFeesDialog} onClose={() => setOpenAddFeesDialog(false)}>
                    <motion.div className={'relative px-2 py-2 w-full flex flex-col'}>
                        <FieldInput label={'Structure name'} fieldProps={feesForm.getFieldProps('feesName')} fieldHelper={feesForm.getFieldHelpers('feesName')} fieldMeta={feesForm.getFieldHelpers('feesName')} edit={true} dataLoaded={true} />
                        <FieldInput type="number" label={'Price'} fieldProps={feesForm.getFieldProps('price')} fieldHelper={feesForm.getFieldHelpers('price')} fieldMeta={feesForm.getFieldHelpers('price')} edit={true} dataLoaded={true} />
                        <FieldInput type="number" label={'SGST'} fieldProps={feesForm.getFieldProps('sgst')} fieldHelper={feesForm.getFieldHelpers('sgst')} fieldMeta={feesForm.getFieldHelpers('sgst')} edit={true} dataLoaded={true} />
                        <FieldInput type="number" label={'CGST'} fieldProps={feesForm.getFieldProps('cgst')} fieldHelper={feesForm.getFieldHelpers('cgst')} fieldMeta={feesForm.getFieldHelpers('cgst')} edit={true} dataLoaded={true} />
                        <motion.div className="flex flex-row justify-end">
                            <Button type="secondary" onClick={() => cancel()}>Cancel</Button>
                            <Button type="primary" onClick={() => !disableSave && feesForm.submitForm()}>Save</Button>
                        </motion.div>
                    </motion.div>
                </Dialog>, document.body)
            }
        </motion.div>
    )
}

export default Fees