import React, { useEffect, useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import API from '../../Common/API'
import DatatableLoader from "../../Components/DatatableLoader"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTable, { createTheme } from "react-data-table-component"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const SubjectList = () => {

    const [loading, setLoading] = useState(true)
    const userDetails = useSelector(state => state.auth.userDetails)
    const orgId = useSelector(state => state.auth.orgId)
    const dispatch = useDispatch()
    const [subjectList, setSubjectList] = useState([])
    const [page, setPage] = useState(1)

    useEffect(() => {
        API.get(`/common/subjects/${userDetails?.subject?.orgType}`).then((res) => {
            if (res.status === 200) {
                setSubjectList(res.data.data)
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => i + 1, initialActive: true, sortable: true },
            { name: 'Subject', selector: row => row.subjectName, sortable: true }
        ]
    })

    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 px-2 py-2 rounded-lg w-full h-full`}>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="Subject List" theme="defaultLight" columns={columns} data={subjectList} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
                </motion.div>
            }
        </motion.div>
    )
}

export default SubjectList