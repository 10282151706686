import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import Root from './Root/Root';
import Loaders from './Common/Loaders';
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore } from 'redux';
import { reducer } from './Store/reducer'

const store = createStore(reducer)



function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loaders />}>
        <Router>
          <div className="h-100 font flex flex-col relative" style={{ fontFamily: "Graphik, Helvetica, Arial, sans-serif" }}>
            <Root />
            <div className="fixed px-5 py-2 bottom-0 right-0 text-[grey] text-xs">Powered By BrainyCampus</div>
          </div>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
