import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import * as Yup from 'yup'
import { useFormik } from "formik"
import Button from '../../Components/Button'
import FieldSelect from '../../Components/FieldSelect'
import FieldInput from '../../Components/FieldInput'

import API from '../../Common/API'
import { useDispatch, useSelector } from "react-redux"
import { setAlert } from '../../Store/Theme/actions'


const initialFormValues = {
    firstName: '',
    lastName: '',
    profileImage: '',
    gender: '',
    email: '',
    mobile: '',
    roleId: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        district: '',
        state: '',
        pinCode: ''
    },
    dob: '',
    date: '',
    year: '',
    month: '',
    age: '',
    community: '',
    nationality: '',
    otherNationality: '',
    religion: '',
    bloodGroup: '',
    aadharNo: '',
    panCardNo: '',
    emergencyPhoneNo: '',
    emergencyContactPerson: '',
}

const AddUser = () => {

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone', "Not a Valid Phone", (value) => {
            if (value === undefined || value === '') return true
            return (value.replace(/ /g, '').length === 10)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet', "Only Alphabets Allowed", (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', "Not valid", (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })


    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test('onlyAlphabet and numbers are allowed', 'Only Alphabets and numbers allowed', (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validName', function () {
        return this.test('Space is not allowed at the beginning', 'Space is not allowed at the beginning', (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    const [stateList, setStateList] = useState()
    const [stateMenuList, setStateMenuList] = useState()
    const [districtList, setDistrictList] = useState([])
    const [communityList, setCommunityList] = useState()
    const [nationalityList, setNationalityList] = useState()
    const [genderList, setGenderList] = useState()
    const [religionList, setReligionList] = useState()
    const [rolesList, setRolesList] = useState()
    const [bloodGroupList, setBloodGroupList] = useState()
    const [loading, setLoading] = useState(true)
    const [districtDisable, setDistrictDisable] = useState(true)
    const dispatch = useDispatch()
    const menuItems = useSelector(state => state.auth.menuItems)

    useEffect(() => {
        setStateList(menuItems.states)
        setStateMenuList(menuItems.states.map((c) => ({ text: c.state, value: c.state })))
        setRolesList(menuItems.roles.filter((x) => x.roleId !== 1 && x.roleId !== 4).map((c) => ({ text: c.role, value: c.role })))
        setCommunityList(menuItems.community.map((c) => ({ text: c.community, value: c.communityId })))
        setNationalityList(menuItems.nationality.map((c) => ({ text: c.nationality, value: c.nationalityId })))
        setGenderList(menuItems.gender.map((c) => ({ text: c.gender, value: c.gender })))
        setReligionList(menuItems.religion.map((c) => ({ text: c.religion, value: c.religionId })))
        setBloodGroupList(menuItems.bloodGroup.map((c) => ({ text: c.bloodGroup, value: c.bloodGroupId })))
        setLoading(false)
    }, [])

    const userFormSchema = Yup.object().shape({
        firstName: Yup.string().validateName().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        profileImage: Yup.string().nullable(),
        gender: Yup.string().required("Gender is required"),
        email: Yup.string().validateEmail().required("Email is required"),
        mobile: Yup.string().validatePhoneLength().required("Mobile is required"),
        roleId: Yup.string().required("Role is required"),
        address: Yup.object().shape({
            addressLine1: Yup.string().required("AddressLine1 is required"),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().required("City is required"),
            district: Yup.string().required("District is required"),
            state: Yup.string().required("State is required"),
            pinCode: Yup.string().required("Pincode is required")
        }),
        dob: Yup.string().required("Date of birth is required"),
        date: Yup.string().nullable(),
        year: Yup.string().nullable(),
        month: Yup.string().nullable(),
        age: Yup.string().nullable(),
        community: Yup.string().nullable(),
        nationality: Yup.string().nullable(),
        otherNationality: Yup.string().nullable(),
        religion: Yup.string().nullable(),
        bloodGroup: Yup.string().nullable(),
        aadharNo: Yup.string().nullable().min(12, "Aadhar Card No is 12 Digit Number").max(12, "Aadhar Card No is 12 Digit Number"),
        panCardNo: Yup.string().nullable(),
        emergencyPhoneNo: Yup.string().validatePhoneLength().required("Emergency phone is required"),
        emergencyContactPerson: Yup.string().validateName().required("Emergency contact person is required"),
    })

    const userForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: userFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            values.mobile = values.mobile.replace(/-/g, '')
            values.emergencyPhoneNo = values.emergencyPhoneNo.replace(/-/g, '')
            API.post('/user/addUser', values).then((userRes) => {
                console.log(userRes)
                if (userRes.status === 200) {
                    dispatch(setAlert({ type: 'success', title: 'Success', subtitle: 'User is created successfully', active: true }))
                    userForm.resetForm()
                }
            })
        }
    })


    const ageCalculation = (dob) => {
        const currentDate = new Date()
        const dobDate = new Date(dob)
        let currentAge = ''

        const yearDifference = currentDate.getFullYear() - dobDate.getFullYear();

        if (
            currentDate.getMonth() < dobDate.getMonth() ||
            (currentDate.getMonth() === dobDate.getMonth() &&
                currentDate.getDate() < dobDate.getDate())
        ) {
            currentAge = yearDifference - 1;
            userForm.setValues({ ...userForm.values, age: currentAge, date: dob.split('-')[2], month: dob.split('-')[1], year: dob.split('-')[0] })
        } else {
            currentAge = yearDifference;
            userForm.setValues({ ...userForm.values, age: currentAge, date: dob.split('-')[2], month: dob.split('-')[1], year: dob.split('-')[0] })
        }
    }

    const selectDistrictList = (value) => {
        setDistrictList(stateList.filter((x) => x.state === value)[0].districts.map((c) => ({ text: c, value: c })))
        setDistrictDisable(false)
    }

    return (
        <>
            {
                loading ? "Loading please wait" : <motion.div className="relative h-full w-full flex flex-col">
                    <motion.div className="relative my-2 rounded-xl px-2 py-2 w-full flex flex-col">
                        <motion.div className="text-2xl font-bold opacity-100">
                            Basic Details
                        </motion.div>
                        <motion.div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                            <FieldInput label={'First Name'} fieldProps={userForm.getFieldProps('firstName')} fieldMeta={userForm.getFieldMeta('firstName')} fieldHelper={userForm.getFieldHelpers('firstName')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Last Name'} fieldProps={userForm.getFieldProps('lastName')} fieldMeta={userForm.getFieldMeta('lastName')} fieldHelper={userForm.getFieldHelpers('lastName')} edit={true} dataLoaded={true} />
                            <FieldInput type="date" label={'Date of Birth'} fieldProps={userForm.getFieldProps('dob')} fieldMeta={userForm.getFieldMeta('dob')} fieldHelper={userForm.getFieldHelpers('dob')} edit={true} dataLoaded={true} onChange={(ev) => ageCalculation(ev)} />
                            <FieldInput label={'Age'} disabled={true} fieldProps={userForm.getFieldProps('age')} fieldMeta={userForm.getFieldMeta('age')} fieldHelper={userForm.getFieldHelpers('age')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'Gender'} fieldProps={userForm.getFieldProps('gender')} fieldMeta={userForm.getFieldMeta('gender')} fieldHelper={userForm.getFieldHelpers('gender')} edit={true} dataLoaded={true} options={genderList} />
                            <FieldInput label={'Email'} fieldProps={userForm.getFieldProps('email')} fieldMeta={userForm.getFieldMeta('email')} fieldHelper={userForm.getFieldHelpers('email')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Mobile'} type="phone" fieldProps={userForm.getFieldProps('mobile')} fieldMeta={userForm.getFieldMeta('mobile')} fieldHelper={userForm.getFieldHelpers('mobile')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'Community'} fieldProps={userForm.getFieldProps('community')} fieldMeta={userForm.getFieldMeta('community')} fieldHelper={userForm.getFieldHelpers('community')} edit={true} dataLoaded={true} options={communityList} />
                            <FieldSelect label={'Nationality'} fieldProps={userForm.getFieldProps('nationality')} fieldMeta={userForm.getFieldMeta('nationality')} fieldHelper={userForm.getFieldHelpers('nationality')} edit={true} dataLoaded={true} options={nationalityList} />
                            <FieldSelect label={'Blood Group'} fieldProps={userForm.getFieldProps('bloodGroup')} fieldMeta={userForm.getFieldMeta('bloodGroup')} fieldHelper={userForm.getFieldHelpers('bloodGroup')} edit={true} dataLoaded={true} options={bloodGroupList} />
                            <FieldSelect label={'Religion'} fieldProps={userForm.getFieldProps('religion')} fieldMeta={userForm.getFieldMeta('religion')} fieldHelper={userForm.getFieldHelpers('religion')} edit={true} dataLoaded={true} options={religionList} />
                            <FieldSelect label={'Role'} fieldProps={userForm.getFieldProps('roleId')} fieldMeta={userForm.getFieldMeta('roleId')} fieldHelper={userForm.getFieldHelpers('roleId')} edit={true} dataLoaded={true} options={rolesList} />
                        </motion.div>
                    </motion.div>
                    <motion.div className="relative my-2 rounded-xl px-2 py-2 w-full flex flex-col">
                        <motion.div className="text-2xl font-bold opacity-100">
                            Address Information
                        </motion.div>
                        <motion.div className={`grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3`}>
                            <FieldInput label={'Address Line 1'} fieldProps={userForm.getFieldProps('address.addressLine1')} fieldMeta={userForm.getFieldMeta('address.addressLine1')} fieldHelper={userForm.getFieldHelpers('address.addressLine1')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Address Line 2'} fieldProps={userForm.getFieldProps('address.addressLine2')} fieldMeta={userForm.getFieldMeta('address.addressLine2')} fieldHelper={userForm.getFieldHelpers('address.addressLine2')} edit={true} dataLoaded={true} />
                            <FieldInput label={'City'} fieldProps={userForm.getFieldProps('address.city')} fieldMeta={userForm.getFieldMeta('address.city')} fieldHelper={userForm.getFieldHelpers('address.city')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'State'} fieldProps={userForm.getFieldProps('address.state')} fieldMeta={userForm.getFieldMeta('address.state')} fieldHelper={userForm.getFieldHelpers('address.state')} edit={true} dataLoaded={true} options={stateMenuList} onChange={(ev) => selectDistrictList(ev)} />
                            <FieldSelect label={'District'} disabled={districtDisable} fieldProps={userForm.getFieldProps('address.district')} fieldMeta={userForm.getFieldMeta('address.district')} fieldHelper={userForm.getFieldHelpers('address.district')} edit={true} dataLoaded={true} options={districtList} />
                            <FieldInput type="phone" label={'Pincode'} fieldProps={userForm.getFieldProps('address.pinCode')} fieldMeta={userForm.getFieldMeta('address.pinCode')} fieldHelper={userForm.getFieldHelpers('address.pinCode')} edit={true} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    <motion.div className="relative my-2 rounded-xl px-2 py-2 w-full flex flex-col">
                        <motion.div className="text-2xl font-bold opacity-100">
                            Other Details
                        </motion.div>
                        <motion.div className={`grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3`}>
                            <FieldInput label={'Emergency Contact Person'} fieldProps={userForm.getFieldProps('emergencyContactPerson')} fieldMeta={userForm.getFieldMeta('emergencyContactPerson')} fieldHelper={userForm.getFieldHelpers('emergencyContactPerson')} edit={true} dataLoaded={true} />
                            <FieldInput type="phone" label={'Emergency Contact Number'} fieldProps={userForm.getFieldProps('emergencyPhoneNo')} fieldMeta={userForm.getFieldMeta('emergencyPhoneNo')} fieldHelper={userForm.getFieldHelpers('emergencyPhoneNo')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Aadhar Card No'} fieldProps={userForm.getFieldProps('aadharNo')} fieldMeta={userForm.getFieldMeta('aadharNo')} fieldHelper={userForm.getFieldHelpers('aadharNo')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Pan Card No'} fieldProps={userForm.getFieldProps('panCardNo')} fieldMeta={userForm.getFieldMeta('panCardNo')} fieldHelper={userForm.getFieldHelpers('panCardNo')} edit={true} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    <motion.div className={'flex-1 flex flex-row justify-center'}>
                        <Button type="primary" onClick={() => userForm.submitForm()} >Save</Button>
                        <Button type="secondary">Cancel</Button>
                    </motion.div>
                </motion.div>
            }
        </>
    )
}

export default AddUser