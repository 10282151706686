import React, { useState } from "react"
import { motion } from 'framer-motion'
import TabButtonGroup from '../../Components/Tabs/TabButtonGroup'
import TabGroup from '../../Components/Tabs/TabGroup'
import Tab from '../../Components/Tabs/Tab'
import TabButton from '../../Components/Tabs/TabButton'
import Fees from "./Fees"
import Package from "./Package"

const Finance = () => {
    const [tab, setTab] = useState(0)
    return (
        <motion.div className={`relative bg-slate-300 bg-opacity-30 rounded-xl px-2 py-2 h-full w-full flex flex-col`}>
            <motion.div className="w-full">
                <motion.div className="text-center">
                    <TabButtonGroup>
                        <TabButton index={0} value={tab} setValue={setTab} id={'Fee'}>
                            Fees
                        </TabButton>
                        <TabButton index={1} value={tab} setValue={setTab} id={'Package'}>
                            Package
                        </TabButton>
                    </TabButtonGroup>
                </motion.div>
                <TabGroup value={tab}>
                    <Tab index={0} value={tab}>
                        <Fees />
                    </Tab>
                    <Tab index={1} value={tab}>
                        <Package />
                    </Tab>
                </TabGroup>
            </motion.div>
        </motion.div>
    )
}

export default Finance