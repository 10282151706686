import Dashboard from '../Pages/Dashboard'
import NewAdmission from '../Pages/Admission/NewAdmission'
import PromotedAdmission from '../Pages/Admission/PromotedAdmission'
import AdmissionList from '../Pages/Admission/AdmissionList'
import Finance from '../Pages/Finance/Finance'
import { ImHome } from 'react-icons/im'
import { RiAddBoxLine } from "react-icons/ri";
import { PiStudentFill, PiPasswordDuotone } from "react-icons/pi";
import { CgListTree, CgProfile, CgOrganisation } from "react-icons/cg";
import { FaFileInvoiceDollar, FaRupeeSign, FaBusAlt, FaRss } from 'react-icons/fa'
import { FaUser, FaUserPlus, FaUsers, FaList, FaFingerprint, FaLayerGroup } from "react-icons/fa6";
import AddUser from '../Pages/Users/AddUser'
import UserList from '../Pages/Users/UserList'
import AddVehicle from '../Pages/Transport/AddVehicle'
import { LiaRouteSolid } from "react-icons/lia";
import AddRoutes from '../Pages/Transport/AddRoutes'
import Vehicles from '../Pages/Transport/Vehicles'
import { PiFilePlusFill } from "react-icons/pi";
import AddAsset from '../Pages/Assets/AddAsset'
import { SiFiles } from "react-icons/si";
import Assets from '../Pages/Assets/Assets'
import AddBiometric from '../Pages/Biometrics/AddBiometric'
import Biometrics from '../Pages/Biometrics/Biometrics'
import Profile from '../Pages/Settings/Profile'
import Organisation from '../Pages/Settings/Organisation'
import ChangePassword from '../Pages/Settings/ChangePassword'
import { BsCalendar2Range } from "react-icons/bs";
import Calendar from '../Pages/Events/Calendar'
import { LuCalendarPlus } from "react-icons/lu";
import AddEvent from '../Pages/Events/AddEvent'
import { AiFillMessage } from "react-icons/ai";
import AddAnnouncement from '../Pages/Events/AddAnnouncement'
import UserView from '../Pages/Users/UserView'
import { SiGoogleclassroom, SiMicrosoftacademic } from 'react-icons/si'
import ClassList from '../Pages/Classes/ClassList'
import AddInvoice from '../Pages/Finance/AddInvoice'
import { HiCurrencyRupee } from "react-icons/hi";
import InvoiceList from '../Pages/Finance/InvoiceList'
import PublishResult from '../Pages/Results/PublishResult'
import ClassAllocation from '../Pages/Classes/ClassAllocation'
import SubjectList from '../Pages/Subjects/SubjectList'
import AssignSubjects from '../Pages/Classes/AssignSubjects'
import AllotedClass from '../Pages/Classes/AllotedClass'
import { ImBooks } from "react-icons/im";
import AddSyllabus from '../Pages/Subjects/AddSyllabus'
import AllotedSubjects from '../Pages/Subjects/AllotedSubjects'
import NotesOfLesson from '../Pages/Subjects/NotesOfLesson'
import PortionCoverage from '../Pages/Subjects/PortionCoverage'
import { MdOutlineViewTimeline, MdOutlineHolidayVillage, MdHomeWork } from 'react-icons/md'
import AttendenceSummary from '../Pages/Attendence/AttendenceSummary'
import { TbCalendarTime, TbReportSearch } from "react-icons/tb";
import MarkAttendence from '../Pages/Attendence/MarkAttendence'
import MonthWiseReport from '../Pages/Attendence/MonthWiseReport'
import AvailHolidays from '../Pages/Events/AvailHolidays'
import GroupCreation from '../Pages/Subjects/GroupCreation'
import AddHomeWork from '../Pages/Classes/AddHomeWork'
import AddMarks from '../Pages/Academics/AddMarks'
import ClassStudents from '../Pages/Classes/ClassStudents'
import Settings from '../Pages/Settings/Settings'

export const DashRoutes = [
    {
        title: 'Dashboard',
        ItemIcon: ImHome,
        link: '/',
        component: Dashboard
    }
]

export const AdmissionRoutes = [
    {
        title: 'New Admission',
        subTitle: 'Admission',
        ItemIcon: RiAddBoxLine,
        link: '/addAdmission',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: NewAdmission
    },
    {
        title: 'Promoted Admission',
        subTitle: 'Admission',
        ItemIcon: PiStudentFill,
        link: '/promotedAdmission',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: PromotedAdmission
    },
    {
        title: 'Admission List',
        subTitle: 'Admission',
        ItemIcon: CgListTree,
        link: '/admissionList',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: AdmissionList
    }
]

export const FinanceRoutes = [
    {
        title: 'Add Invoice',
        subTitle: 'Finance Management',
        ItemIcon: FaFileInvoiceDollar,
        link: '/addInvoice',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: AddInvoice
    },
    {
        title: 'Invoice List',
        subTitle: 'Finance Management',
        ItemIcon: HiCurrencyRupee,
        link: '/invoiceList',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: InvoiceList
    },
    {
        title: 'Fees and Packages',
        subTitle: 'Finance Management',
        ItemIcon: FaRupeeSign,
        link: '/fee',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: Finance
    }
]

export const UserRoutes = [
    {
        title: 'Add User',
        subTitle: 'Users',
        ItemIcon: FaUserPlus,
        link: '/addUser',
        menu: 'Details',
        roles: ['Super Admin'],
        component: AddUser
    },
    {
        title: 'User List',
        subTitle: 'Users',
        ItemIcon: FaUsers,
        link: '/users',
        menu: 'Details',
        roles: ['Super Admin'],
        component: UserList
    },
    {
        title: 'User View',
        subTitle: '',
        ItemIcon: FaUser,
        link: '/users/view/:id',
        menu: 'Details',
        roles: ['Super Admin'],
        component: UserView
    }
]

export const TransportRoutes = [
    {
        title: 'Add Vehicle',
        subTitle: 'Transport',
        ItemIcon: FaBusAlt,
        menu: 'Details',
        link: '/addVehicle',
        roles: ['Super Admin'],
        component: AddVehicle
    },
    {
        title: 'Add Routes',
        subTitle: 'Transport',
        ItemIcon: LiaRouteSolid,
        menu: 'Details',
        link: '/addRoutes',
        roles: ['Super Admin'],
        component: AddRoutes
    },
    {
        title: 'Vehicle List',
        subTitle: 'Transport',
        ItemIcon: FaList,
        menu: 'Details',
        link: '/vehicles',
        roles: ['Super Admin'],
        component: Vehicles
    }
]

export const AssetRoutes = [
    {
        title: 'Asset List',
        subTitle: 'Asset Management',
        ItemIcon: SiFiles,
        menu: 'Details',
        link: '/assets',
        roles: ['Super Admin'],
        component: Assets
    },
    {
        title: 'Add Asset',
        subTitle: 'Asset Management',
        ItemIcon: PiFilePlusFill,
        menu: 'Details',
        link: '/addAsset',
        roles: ['Super Admin'],
        component: AddAsset
    }
]

export const Idroutes = [
    {
        title: 'Add Biometrics',
        subTitle: 'Identification',
        ItemIcon: FaFingerprint,
        menu: 'Details',
        link: '/addBiometrics',
        roles: ['Super Admin'],
        component: AddBiometric
    },
    {
        title: 'Biometrics',
        subTitle: 'Identification',
        ItemIcon: FaFingerprint,
        menu: 'Details',
        link: '/biometrics',
        roles: ['Super Admin'],
        component: Biometrics
    }
]

export const SettingRoutes = [
    {
        title: 'Settings',
        subTitle: 'Settings',
        ItemIcon: CgProfile,
        menu: 'Details',
        link: '/settings',
        roles: ['Super Admin', 'Office Admin', 'Teacher', 'IT Admin'],
        component: Settings
    },
    // {
    //     title: 'Profile',
    //     subTitle: 'Settings',
    //     ItemIcon: CgOrganisation,
    //     menu: 'Details',
    //     link: '/organisation',
    //     roles: ['Super Admin'],
    //     component: Organisation
    // },
    // {
    //     title: 'Change Password',
    //     subTitle: 'Settings',
    //     ItemIcon: PiPasswordDuotone,
    //     menu: 'Details',
    //     link: '/changePassword',
    //     roles: ['Super Admin', 'Office Admin', 'Teacher', 'IT Admin'],
    //     component: ChangePassword
    // }
]

export const eventRoutes = [
    {
        title: 'Calendar',
        subTitle: 'Events And Announcements',
        ItemIcon: BsCalendar2Range,
        menu: 'Details',
        link: '/calendar',
        roles: ['Super Admin', 'Office Admin'],
        component: Calendar
    },
    {
        title: 'Add Events',
        subTitle: 'Events And Announcements',
        ItemIcon: LuCalendarPlus,
        menu: 'Details',
        link: '/addEvents',
        roles: ['Super Admin', 'Office Admin'],
        component: AddEvent
    },
    {
        title: 'Add Announcements',
        subTitle: 'Events And Announcements',
        ItemIcon: AiFillMessage,
        menu: 'Details',
        link: '/addAnnouncement',
        roles: ['Super Admin', 'Office Admin'],
        component: AddAnnouncement
    },
    {
        title: 'Avail Holidays',
        subTitle: 'Events And Announcements',
        ItemIcon: MdOutlineHolidayVillage,
        menu: 'Details',
        link: '/availHolidays',
        roles: ['Super Admin', 'Office Admin'],
        component: AvailHolidays
    }
]

export const classRoutes = [
    {
        title: 'Class List',
        subTitle: 'Classes',
        ItemIcon: SiGoogleclassroom,
        menu: 'Details',
        link: '/classes',
        roles: ['Super Admin', 'Office Admin'],
        component: ClassList
    },
    {
        title: 'Allocate Class',
        subTitle: 'Classes',
        ItemIcon: SiGoogleclassroom,
        menu: 'Details',
        link: '/allocateClass',
        roles: ['Super Admin', 'Office Admin'],
        component: ClassAllocation
    },
    {
        title: 'Subjects',
        subTitle: 'Classes',
        ItemIcon: SiGoogleclassroom,
        menu: 'Details',
        link: '/subjects',
        roles: ['Super Admin', 'Office Admin'],
        component: SubjectList
    },
    {
        title: 'Assign Subjects',
        subTitle: 'Classes',
        ItemIcon: SiGoogleclassroom,
        menu: 'Details',
        link: '/assignSubjects',
        roles: ['Super Admin', 'Office Admin'],
        component: AssignSubjects
    },
    {
        title: 'Alloted Class',
        subTitle: 'Classes',
        ItemIcon: SiGoogleclassroom,
        menu: 'Details',
        link: '/allotedClass',
        roles: ['Teacher'],
        component: AllotedClass
    },
    {
        title: 'Add Syllabus',
        subTitle: 'Classes',
        ItemIcon: ImBooks,
        menu: 'Details',
        link: '/addSyllabus',
        roles: ['Super Admin', 'Office Admin'],
        component: AddSyllabus
    },
    {
        title: 'Notes of lesson',
        subTitle: 'Classes',
        ItemIcon: ImBooks,
        menu: 'Details',
        link: '/allotedSyllabus',
        roles: ['Teacher'],
        component: AllotedSubjects
    },
    {
        title: 'Notes of lesson',
        subTitle: 'Classes',
        ItemIcon: ImBooks,
        link: '/notesOfLesson',
        roles: [''],
        component: NotesOfLesson
    },
    {
        title: 'Set Portion Coverge',
        subTitle: 'Classes',
        ItemIcon: ImBooks,
        link: '/portionCoverage',
        roles: ['Super Admin', 'Office Admin'],
        component: PortionCoverage
    },
    {
        title: 'Group Creation',
        subTitle: 'Classes',
        ItemIcon: FaLayerGroup,
        link: '/groupCreation',
        roles: ['Super Admin', 'Office Admin'],
        component: GroupCreation
    },
    {
        title: 'Add Homeworks',
        subTitle: 'Classes',
        ItemIcon: MdHomeWork,
        link: '/homeWork',
        roles: ['Teacher'],
        component: AddHomeWork
    },
    {
        title: 'Class Details',
        subTitle: 'Classes',
        link: '/classDetails',
        roles: [''],
        component: ClassStudents,
    }
]

export const resultRoutes = [
    {
        title: 'Publish Results',
        subTitle: 'Results',
        ItemIcon: FaRss,
        link: '/publishResult',
        menu: 'Details',
        roles: ['Super Admin', 'Office Admin'],
        component: PublishResult
    }
]

export const attendenceRoutes = [
    {
        title: 'Mark Attendence',
        subTitle: 'Attendence Management',
        ItemIcon: TbCalendarTime,
        menu: 'Details',
        link: '/markAttendence',
        roles: ['IT Admin', 'Super Admin'],
        component: MarkAttendence
    },
    {
        title: 'Attendence Summary',
        subTitle: 'Attendence Management',
        ItemIcon: MdOutlineViewTimeline,
        menu: 'Details',
        link: '/attendenceSummary',
        roles: ['Super Admin', 'Office Admin', 'Teacher', 'IT Admin'],
        component: AttendenceSummary
    },
    {
        title: 'Month Reports',
        subTitle: 'Attendence Management',
        ItemIcon: TbReportSearch,
        menu: 'Details',
        link: '/attendenceReport',
        roles: ['Super Admin', 'Office Admin', 'Teacher', 'IT Admin'],
        component: MonthWiseReport
    },
]

export const academicRoutes = [
    {
        title: 'Add Marks',
        subTitle: 'Academics',
        ItemIcon: SiMicrosoftacademic,
        link: '/addMarks',
        roles: ['Teacher'],
        component: AddMarks
    }
]

export const CombinedRoutes = [...DashRoutes, ...AdmissionRoutes, ...FinanceRoutes, ...UserRoutes, ...TransportRoutes, ...AssetRoutes, ...Idroutes, ...SettingRoutes, ...eventRoutes, ...classRoutes, ...resultRoutes, ...attendenceRoutes, ...academicRoutes]