import React, { useEffect, useState, useMemo } from "react"
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux"
import API from "../../Common/API"
import { useHistory } from "react-router-dom"
import { setAlert } from "../../Store/Theme/actions"
import { calculateAcademicYear } from "../../Common/academicYear"
import DatatableLoader from "../../Components/DatatableLoader"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../Common/dataTableTheme'


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const AllotedClass = () => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const academicYear = calculateAcademicYear()
    const [assignedList, setAssignedList] = useState([])
    const [page, setPage] = useState(1)
    const orgId = useSelector(state => state.auth.orgId)
    const history = useHistory()

    useEffect(() => {
        try {

            API.get(`/common/getMyAllotedClass/${academicYear}/${userDetails.subject._id}`).then((res) => {
                const classMap = new Map()
                res.data.data.map((c) => {
                    if (classMap.has(c.class.className)) {
                        const classData = classMap.get(c.class.className)
                        classData.subjects.push(c)
                    } else {
                        classMap.set(c.class.className, {
                            className: c.class.className,
                            subjects: [c]
                        })
                    }
                })
                setAssignedList([...classMap.values()])
                setLoading(false)
            })
        } catch (err) {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', active: true, type: 'error' }))
        }
    }, [])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            { name: 'Class Name', selector: row => row.className, sortable: true },
            {
                name: 'Subjects', cell: row => <>
                    {row.subjects.map((c, i) => <div>{`${c.subject} ${(row.subjects.length === i + 1) ? '' : ','}`}</div>)}
                </>, sortable: true
            }
        ]
    })

    const redirectToClassDetails = (value) => {
        API.get(`/class/getStudentsByClass/${orgId}/${academicYear}/${value.subjects[0].class._id}`).then((res) => {
            if (res.status === 200) {
                history.push('/classDetails', { details: res.data.data })
            }
        })
    }

    return (
        <motion.div>
            {
                loading ? <DatatableLoader /> : <motion.div className="bg-pColor h-full w-full bg-opacity-10  drop-shadow-lg">
                    <DataTable title="User List" theme="defaultLight" columns={columns} data={assignedList} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} onRowClicked={(ev) => redirectToClassDetails(ev)} />
                </motion.div>
            }
        </motion.div>
    )
}

export default AllotedClass