import { motion } from "framer-motion"

const loadingAnim = {
    hidden: {
        x: '-200%'
    },
    visible: {
        x: '200%',
        transition: {
            repeat: Infinity,
            repeatType: 'loop',
            duration: 1,
            type: "linear"
        }
    }
}

const FieldLoader = ({ height = "h-1", injectClass = "" }) => {
    return (
        <div className={`${height} bg-pColor  overflow-hidden ${injectClass}`}>
            <motion.div variants={loadingAnim} initial="hidden" animate="visible" className={`w-1/2 ${height} bg-tcolor rounded-full`}></motion.div>
        </div>
    )
}

export default FieldLoader