import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import API from '../../Common/API'
import { motion } from 'framer-motion'
import { calculateAcademicYear } from "../../Common/academicYear"


const NotesOfLesson = ({ history }) => {

    const [loading, setLoading] = useState(true)
    const orgId = useSelector(state => state.auth.orgId)
    const academicYear = calculateAcademicYear()
    const [syllabusList, setSyllabusList] = useState([])

    useEffect(() => {
        setLoading(true)
        const classId = history.location.state.state.class._id
        const subject = history.location.state.state.subjectId
        try {
            API.get(`/class/getSyllabus/${orgId}/${classId}/${subject}/${academicYear}`).then((res) => {
                if (res.status === 200) {
                    setSyllabusList(res.data.data)
                    setLoading(false)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <motion.div>
            {
                loading ? "Loading please wait" : <motion.div className="relative h-full w-full flex flex-col">
                    <motion.div className="relative flex flex-row my-5 text-center justify-start">
                        <motion.div className="flex flex-col mx-10 ">
                            <p className="text-xl font-bold">Class</p>
                            <p className="text-md font-bold">{history.location.state.state.class.className}</p>
                        </motion.div>
                        <motion.div className="flex flex-col mx-10">
                            <p className="text-xl font-bold">Subject</p>
                            <p className="text-md font-bold">{history.location.state.state.subject}</p>
                        </motion.div>
                    </motion.div>
                    {
                        syllabusList.length > 0 ? syllabusList.map((c, i) => {
                            return (
                                <motion.div className={`${c.portionEnable === false ? 'bg-sColor bg-opacity-20 text-white' : 'bg-sColor bg-opacity-40 text-black'} relative my-2 px-4 py-2 flex flex-col w-full justify-center rounded-xl`}>
                                    {c.portionEnable === false && <motion.div className="absolute flex flex-col items-center align-center text-center w-full  text-black text-3xl font-bold justify-center">
                                        <p className="ring-1 ring-[black] px-2 py-2">Disabled</p>
                                    </motion.div>}
                                    <motion.div className="text-lg font-bold">UNIT {i + 1}</motion.div>
                                    <motion.div className="grid my-5 px-5 gap-4 grid-cols-6">
                                        <motion.div>S.No</motion.div>
                                        <motion.div>Content</motion.div>
                                        <motion.div>Due Date</motion.div>
                                        <motion.div>Start Date</motion.div>
                                        <motion.div>End Date</motion.div>
                                        <motion.div>Status</motion.div>
                                    </motion.div>
                                    <motion.div className="grid px-5 px-5 gap-4 my-5 grid-cols-6">
                                        <motion.div>{i + 1}</motion.div>
                                        <motion.div>{c.topicName}</motion.div>
                                        <motion.div>{c.dueDate !== null ? c.dueDate : '-'}</motion.div>
                                        <motion.div>
                                            <input type="date" value={c.startDate} className="bg-transparent px-2" onChange={(ev) => c.startDate = ev.target.value} />
                                        </motion.div>
                                        <motion.div><input type="date" value={c.endDate} className="bg-transparent px-2" onChange={(ev) => c.endDate = ev.target.value} /></motion.div>
                                        <motion.div>{c.completedStat !== null && c.completedStat !== undefined ? c.completedStat : ''}</motion.div>
                                    </motion.div>
                                    {
                                        c.subTopics && c.subTopics.length > 0 && c.subTopics.map((e, j) => {
                                            return <motion.div className="grid px-5 px-5 gap-4 my-5 grid-cols-6">
                                                <motion.div>{i + 1}.{j + 1}</motion.div>
                                                <motion.div>{e.topicName}</motion.div>
                                                <motion.div>{e.dueDate !== null ? e.dueDate : '-'}</motion.div>
                                                <motion.div>
                                                    <input type="date" value={e.startDate} className="bg-transparent px-2" onChange={(ev) => e.startDate = ev.target.value} />
                                                </motion.div>
                                                <motion.div>
                                                    <input type="date" value={e.dueDate} className="bg-transparent px-2" onChange={(ev) => e.dueDate = ev.target.value} />
                                                </motion.div>
                                                <motion.div>{e.completedStat !== null && e.completedStat !== undefined ? e.completedStat : ''}</motion.div>
                                            </motion.div>
                                        })
                                    }
                                </motion.div>
                            )
                        }) : ''
                    }
                    {/* <motion.div className="grid my-5 text-center grid-cols-5">
                        <motion.div>Content</motion.div>
                        <motion.div>Due Date</motion.div>
                        <motion.div>Start Date</motion.div>
                        <motion.div>End Date</motion.div>
                        <motion.div>Status</motion.div>
                    </motion.div>
                    {
                        syllabusList.length > 0 ? syllabusList.map((c) => {
                            return <motion.div className="grid px-5 my-5 grid-cols-5">
                                <motion.div>{c.topicName}</motion.div>
                                <motion.div>{c.dueDate !== null ? c.dueDate : '-'}</motion.div>
                                <motion.div>{c.startDate !== null && c.startDate !== undefined ? c.startDate : ''}</motion.div>
                                <motion.div>{c.endDate !== null && c.endDate !== undefined ? c.endDate : ''}</motion.div>
                                <motion.div>{c.completedStat !== null && c.completedStat !== undefined ? c.completedStat : ''}</motion.div>
                            </motion.div>
                        }) : ''
                    } */}
                </motion.div>
            }
        </motion.div>
    )
}

export default NotesOfLesson